<div class="topic-teaser bg-nightblue topic-teaser-reverse mb-huge-r">
  <div class="container">
    <div class="topic-teaser-container bg-nightblue">
      <div class="row pt-huge-r">
        <div class="topic-teaser-image-container">
          <img
            class="topic-teaser-image"
            src="https://picsum.photos/id/553/800/800"
            width="100%"
            height="100%"
            alt=""
          />
        </div>

        <div class="topic-teaser-content">
          <h2 class="topic-teaser-title font-curve-large mb-large">
            <span class="bold">Lorem ipsum</span>
            <span class="light">sit amet, consetetur sadipscing elitr</span>
          </h2>
          <ul class="link-list mb-large">
            <li class="link-list-item">
              <a href="#"><span>Lorem ipsum dolor</span></a>
            </li>
            <li class="link-list-item">
              <a href="#"><span>sit amet, consetetur</span></a>
            </li>
            <li class="link-list-item">
              <a href="#"><span>sadipscing elitr, sed</span></a>
            </li>
            <li class="link-list-item">
              <a href="#"><span>diam nonumy eirmod</span></a>
            </li>
            <li class="link-list-item">
              <a href="#"><span>tempor invidunt ut labore et dolore magna aliquyam</span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
