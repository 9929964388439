<div class="container">
  <div class="heading py-5">
    <h1 class="bold font-curve-large">Cards</h1>
    <app-dependency-link></app-dependency-link>
  </div>

  <h3>Basic examples (Adopted from bootstrap website)</h3>
  <p>For more information on those examples, check the bootstrap doc.</p>
  <app-card-demo [imagePath]="imgPath"></app-card-demo>
  <code
    class="block"
    [highlight]="cardDemo"
    [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
  ></code>
  <h3>Complex spacing examples (Adopted from bootstrap website)</h3>
  <app-complex-card-demo [imagePath]="imgPath"></app-complex-card-demo>
  <code
    class="block"
    [highlight]="complexCardDemo"
    [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
  ></code>
  <h3>Post example (Actual use case)</h3>
  <p>
    To use background-color utilities which span the entire viewport, you need to use a container
    nested within a container fluid! Make sure you are not setting up a container around the entire
    content.
  </p>
</div>
<app-post-card-demo></app-post-card-demo>
<div class="container">
  <code
    class="block"
    [highlight]="postCardDemo"
    [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
  ></code>
</div>
