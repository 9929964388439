<ngb-carousel class="carousel has-caption">
  <ng-template ngbSlide>
    <img src="assets/images/robot.jpg" alt="First slide" />
    <div class="carousel-caption font-curve-medium">
      <h3 class="bold">First slide label</h3>
      <p class="subtitle">Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      <button type="button" class="btn btn-primary btn-animated">
        <span>Go somewhere</span>
      </button>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <img src="assets/images/computer-stuff.jpg" alt="Second slide" />
    <div class="carousel-caption font-curve-medium">
      <h3 class="bold">Second slide label</h3>
      <p class="subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      <button type="button" class="btn btn-primary btn-animated">
        <span>Go somewhere</span>
      </button>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <img src="assets/images/hard-drive.jpg" alt="Third slide" />
    <div class="carousel-caption font-curve-medium">
      <h3 class="bold">Third slide label</h3>
      <p class="subtitle">Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
      <button type="button" class="btn btn-primary btn-animated">
        <span>Go somewhere</span>
      </button>
    </div>
  </ng-template>
</ngb-carousel>
