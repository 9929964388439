<div class="alert-container">
  <ngb-alert
    [type]="alert.type"
    (closed)="closeAlert(alert)"
    [ngClass]="alert.class"
    *ngFor="let alert of alerts"
  >
    <h4 class="alert-heading" *ngIf="alert.title !== undefined">{{ alert.title }}</h4>
    <p *ngIf="alert.message !== undefined">{{ alert.message }}</p>
  </ngb-alert>
</div>
<p>
  <button type="button" class="btn btn-primary" (click)="reset()">Reset Alerts</button>
</p>
