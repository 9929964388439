<div class="heading py-5">
  <h1 class="bold font-curve-large">Blockquotes</h1>
  <app-dependency-link documentationPath="content/typography/#blockquotes"></app-dependency-link>
</div>

<h3>Basic blockquotes</h3>
<app-blockquotes-demo></app-blockquotes-demo>
<code
  class="block mb-5"
  [highlight]="codeTemplateBasic"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>

<h3>Nested blockquotes</h3>
<app-nested-blockquotes-demo></app-nested-blockquotes-demo>
<code
  class="block mb-5"
  [highlight]="codeTemplateNested"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>

<h3>Just quotes without any extra styling</h3>
<div class="container-fluid">
  <app-quotes-demo></app-quotes-demo>
</div>
<code
  class="block mt-3"
  [highlight]="codeTemplateQuotes"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>
