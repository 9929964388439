<div class="heading py-5">
  <h1 class="bold font-curve-large">Post icons</h1>
</div>

<div class="alert alert-info">
  <h2 class="h5">We have something new for you!</h2>
  <p class="mt-2">
    The usage of icons as base64 encoded background images in CSS will soon be deprecated. You can
    switch to the brand new icon component
    <code>post-icon</code>
    today. Go ahead and have a look at the
    <a href="https://design-system.post.ch/?path=/docs/40ed323b-9c1a-42ab-91ed-15f97f214608--docs">
      brand new icons documentation
    </a>
    featuring a search and over 800 icons.
  </p>
  <p class="mt-3">
    <a
      href="https://design-system.post.ch/?path=/docs/5704bdc4-c5b5-45e6-b123-c54d01fce2f1--docs"
      class="btn btn-primary"
    >
      Discover the new icons
    </a>
  </p>
</div>
<app-svg-icons-demo></app-svg-icons-demo>
