<div class="heading py-5">
  <h1 class="bold font-curve-large">Form textarea</h1>
  <app-dependency-link documentationPath="forms/floating-labels/#textareas"></app-dependency-link>
</div>

<div class="alert alert-warning mb-bigger-big">
  <h2 class="alert-heading">
    We're deprecating the regular and medium sizes for text input, textarea and select.
  </h2>
  <div class="mt-0">
    This will make it easier to select the appropriate size variant: small for internal
    applications, large for external applications.
    <br />
    <code>.form-control-rg</code>
    and
    <code>.form-control-md</code>
    are deprecated and will be removed in the next major version.
  </div>
</div>

<section>
  <app-form-textarea-demo></app-form-textarea-demo>
</section>
<code
  class="block"
  [highlight]="codeTemplate"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>
