<div class="heading py-5">
  <h1 class="bold font-curve-large">Post-Spinner</h1>
</div>

<div class="alert alert-info" role="alert">
  <h4 class="alert-heading">Info for implementing</h4>
  <p>The spinner components requires an element with position: relative as a parent.</p>
</div>

<p>Regular spinner. Usage: Page-Loads. Blocking entire site.</p>
<div class="row me-0">
  <div class="col position-relative">
    <app-spinner-demo></app-spinner-demo>
    <code>
      There is some continous text here as an example. Lorem ipsum dolor sit amet, consetetur
      sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
      erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
    </code>
  </div>
  <div class="col bg-primary position-relative">
    <app-spinner-demo></app-spinner-demo>
  </div>
</div>
<code
  class="block mt-3"
  [highlight]="spinner"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>

<p>
  Small spinner. Usage: Loading asynchronous data for components like lists, tables, etc. Blocking
  only component.
</p>
<div class="row me-0">
  <div class="col position-relative">
    <app-spinner-demo small="true"></app-spinner-demo>
    <code>
      There is some continous text here as an example. Lorem ipsum dolor sit amet, consetetur
      sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
      erat, sed diam voluptua.
    </code>
  </div>
  <div class="col bg-primary position-relative">
    <app-spinner-demo small="true"></app-spinner-demo>
  </div>
</div>
<code
  class="block mt-3"
  [highlight]="spinnerSmall"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>

<p>XS spinner. Usage: On button, inline with text, ...; when waiting for a response.</p>
<app-spinner-mini-demo></app-spinner-mini-demo>
<code
  class="block mt-3"
  [highlight]="codeTemplate"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>
