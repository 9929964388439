<div class="heading py-5">
  <h1 class="bold font-curve-large">Form range</h1>
  <app-dependency-link documentationPath="forms/range"></app-dependency-link>
</div>

<section>
  <app-form-range-custom></app-form-range-custom>
  <code
    [highlight]="customCodeTemplate"
    [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
    class="block mt-big"
  ></code>
</section>

<section>
  <h3>Basic form range</h3>
  <p>Range inputs can still work without the directive:</p>
  <app-form-range-demo></app-form-range-demo>
  <code
    [highlight]="basicCodeTemplate"
    [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
    class="block mt-big"
  ></code>
</section>
