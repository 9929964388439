<div class="heading py-5 container">
  <h1 class="bold font-curve-large">Topic teaser</h1>
</div>

<div class="container">
  <p class="mb-5 light font-curve-regular">
    If you use multiple topic teasers on one page, each topic teaser must have a different
    background.
    <br />
    Only use "
    <a [routerLink]="'/bootstrap-samples/background'">Extended Swiss Post backgrounds</a>
    " or "bg-light" as background.
  </p>

  <h3 class="bold mt-5 mb-3">Basic example</h3>
</div>

<app-topic-teaser-demo class="d-block pb-huge"></app-topic-teaser-demo>

<div class="container">
  <code
    class="block"
    [highlight]="topicTeaserTemplate"
    [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
  ></code>

  <h3 class="bold mt-5 mb-3">Image on right side</h3>
</div>

<app-topic-teaser-right-demo class="d-block pb-huge"></app-topic-teaser-right-demo>

<div class="container">
  <code
    class="block"
    [highlight]="topicTeaserRightTemplate"
    [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
  ></code>
</div>
