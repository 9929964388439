<div class="d-flex gap-3 flex-wrap">
  <button
    type="button"
    class="btn btn-secondary me-2"
    placement="top auto"
    ngbTooltip="Tooltip on top"
  >
    Tooltip on top
  </button>
  <button
    type="button"
    class="btn btn-secondary me-2"
    placement="right auto"
    ngbTooltip="Tooltip on right"
  >
    Tooltip on right
  </button>
  <button
    type="button"
    class="btn btn-secondary me-2"
    placement="bottom auto"
    ngbTooltip="Tooltip on bottom"
  >
    Tooltip on bottom
  </button>
  <button
    type="button"
    class="btn btn-secondary me-2"
    placement="left auto"
    ngbTooltip="Tooltip on left"
  >
    Tooltip on left
  </button>

  <ng-template #tooltipContent>
    <span class="text-start">
      <p><strong>Custom HTML Tooltip on top</strong></p>
      <p>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Temporibus nisi iusto cupiditate
        optio perferendis iure ipsa vitae assumenda! Consequuntur, pariatur necessitatibus nesciunt
        architecto quidem a unde rerum vero praesentium cum.
      </p>
    </span>
  </ng-template>

  <button
    type="button"
    class="btn btn-secondary me-2"
    placement="top auto"
    [ngbTooltip]="tooltipContent"
  >
    Custom HTML Tooltip on top
  </button>
</div>
