<div class="heading py-5">
  <h1 class="bold font-curve-large">Tables</h1>
  <app-dependency-link documentationPath="content/tables"></app-dependency-link>
</div>

<app-tables-demo></app-tables-demo>
<code
  class="block"
  [highlight]="templateCode"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>
