<div class="row">
  <div class="col-lg-8 offset-lg-2">
    <h3>Javascript needed!</h3>
    <p class="lead">
      Some javascript is needed to sync the height between the "table-cells" while still keep them
      mobile-friendly and semantic.
    </p>
    <div class="row border-gutters">
      <div class="col-md-6">
        <div class="card product-card">
          <div class="card-body" data-sync-height-with="product-header">
            <div class="product-navigation">
              <div>
                <h3 class="font-weight-bold">Preiswert</h3>
                <h4 class="mb-regular">Sample Product</h4>
                <p class="lead">
                  Mit SAMPLE PRODUCT kommen Ihre Briefe kostengünstig und zuverlässig ans Ziel.
                </p>
              </div>
              <div>
                <a href="#" class="link-icon">
                  <i class="pi pi-3020"></i>
                  <span>mehr erfahren</span>
                </a>
              </div>
            </div>
          </div>

          <div class="card-body bg-light" data-sync-height-with="product-body-1">
            <h5 class="font-weight-bold h6">Sample Product</h5>
            <p>140 x 90 mm bis B5 (250 x 176 mm)</p>
            <dl class="horizontal-list mt-3">
              <div class="horizontal-list-item">
                <dt class="font-weight-light">bis 500 g</dt>
                <dd class="h3 font-weight-bold">1.20</dd>
              </div>
              <div class="horizontal-list-item">
                <dt class="font-weight-light">bis 50 g</dt>
                <dd class="h3 font-weight-bold">2.20</dd>
              </div>
            </dl>
          </div>

          <div class="card-body" data-sync-height-with="product-body-2">
            <h5 class="font-weight-bold h6">Sample Product</h5>
            <p>140 x 90 mm bis B5 (250 x 176 mm)</p>
            <dl class="horizontal-list mt-3 align-items-stretch">
              <div class="horizontal-list-item justify-content-between d-flex flex-column">
                <dt class="font-weight-light">
                  so zwischen ca. 5g
                  <br />
                  bis ungefähr etwa 500 g
                </dt>
                <dd class="h3 font-weight-bold">1.20</dd>
              </div>
              <div class="horizontal-list-item justify-content-between d-flex flex-column">
                <dt class="font-weight-light">bis 50 g</dt>
                <dd class="h3 font-weight-bold">2.20</dd>
              </div>
              <div class="horizontal-list-item justify-content-between d-flex flex-column">
                <dt class="font-weight-light">bis 100 g</dt>
                <dd class="h3 font-weight-bold">2.90</dd>
              </div>
              <div class="horizontal-list-item justify-content-between d-flex flex-column">
                <dt class="font-weight-light">bis 100 g</dt>
                <dd class="h3 font-weight-bold">2.90</dd>
              </div>
              <div class="horizontal-list-item justify-content-between d-flex flex-column">
                <dt class="font-weight-light">bis 100 g</dt>
                <dd class="h3 font-weight-bold">2.90</dd>
              </div>
              <div class="horizontal-list-item justify-content-between d-flex flex-column">
                <dt class="font-weight-light">bis 100 g</dt>
                <dd class="h3 font-weight-bold">2.90</dd>
              </div>
            </dl>
          </div>

          <div class="card-body bg-light" data-sync-height-with="product-body-3">
            <h5 class="font-weight-bold h6">Sample Product</h5>
            <p>140 x 90 mm bis B5 (250 x 176 mm)</p>
            <dl class="horizontal-list mt-3">
              <div class="horizontal-list-item">
                <dt class="font-weight-light">bis 500 g</dt>
                <dd class="h3 font-weight-bold">1.20</dd>
              </div>
              <div class="horizontal-list-item">
                <dt class="font-weight-light">bis 50 g</dt>
                <dd class="h3 font-weight-bold">2.20</dd>
              </div>
              <div class="horizontal-list-item">
                <dt class="font-weight-light">bis 100 g</dt>
                <dd class="h3 font-weight-bold">2.90</dd>
              </div>
            </dl>
          </div>

          <div class="card-body">
            <p>
              <button class="btn btn-secondary btn-animated w-100">
                <span>Order Sample Product</span>
              </button>
            </p>
            <p>
              <button class="btn btn-primary btn-animated w-100">
                <span>Print Sample Product</span>
              </button>
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card product-card">
          <div class="card-body" data-sync-height-with="product-header">
            <div class="product-navigation">
              <div>
                <h3 class="font-weight-bold">Schneller</h3>
                <h4 class="mb-regular">Sample Product</h4>
                <p class="lead">
                  Lorem ipsum aafw ea aewfwe awef awfeaea awefa wawef waf wawefa ea dolor sit amet
                  consectetur adipisicing elit. Iusto nulla laboriosam nesciunt. Unde velit dolores
                  at fugiat dolorum nobis sit.
                </p>
              </div>
              <div>
                <a href="#" class="link-icon">
                  <i class="pi pi-3020"></i>
                  <span>mehr erfahren</span>
                </a>
              </div>
            </div>
          </div>

          <div class="card-body bg-light" data-sync-height-with="product-body-1">
            <h5 class="font-weight-bold h6">Sample Product</h5>
            <p>140 x 90 mm bis B5 (250 x 176 mm)</p>
            <dl class="horizontal-list mt-3">
              <div class="horizontal-list-item">
                <dt class="font-weight-light">bis 500 g</dt>
                <dd class="h3 font-weight-bold">1.20</dd>
              </div>
              <div class="horizontal-list-item">
                <dt class="font-weight-light">bis 50 g</dt>
                <dd class="h3 font-weight-bold">2.20</dd>
              </div>
              <div class="horizontal-list-item">
                <dt class="font-weight-light">bis 100 g</dt>
                <dd class="h3 font-weight-bold">2.90</dd>
              </div>
            </dl>
          </div>

          <div class="card-body" data-sync-height-with="product-body-2">
            <h5 class="font-weight-bold h6">Sample Product</h5>
            <p>140 x 90 mm bis B5 (250 x 176 mm)</p>
            <dl class="horizontal-list mt-3 align-items-stretch">
              <div class="horizontal-list-item justify-content-between d-flex flex-column">
                <dt class="font-weight-light">
                  so zwischen ca. 5g
                  <br />
                  bis ungefähr etwa 500 g
                </dt>
                <dd class="h3 font-weight-bold">1.20</dd>
              </div>
              <div class="horizontal-list-item justify-content-between d-flex flex-column">
                <dt class="font-weight-light">bis 50 g</dt>
                <dd class="h3 font-weight-bold">2.20</dd>
              </div>
              <div class="horizontal-list-item justify-content-between d-flex flex-column">
                <dt class="font-weight-light">bis 100 g</dt>
                <dd class="h3 font-weight-bold">2.90</dd>
              </div>
            </dl>
          </div>

          <div class="card-body bg-light" data-sync-height-with="product-body-3">
            <h5 class="font-weight-bold h6">Sample Product</h5>
            <p>140 x 90 mm bis B5 (250 x 176 mm)</p>
            <dl class="horizontal-list mt-3">
              <div class="horizontal-list-item">
                <dt class="font-weight-light">bis 500 g</dt>
                <dd class="h3 font-weight-bold">1.20</dd>
              </div>
              <div class="horizontal-list-item">
                <dt class="font-weight-light">bis 50 g</dt>
                <dd class="h3 font-weight-bold">2.20</dd>
              </div>
              <div class="horizontal-list-item">
                <dt class="font-weight-light">bis 100 g</dt>
                <dd class="h3 font-weight-bold">2.90</dd>
              </div>
            </dl>
          </div>

          <div class="card-body">
            <p>
              <button class="btn btn-secondary btn-animated w-100">
                <span>Order Sample Product</span>
              </button>
            </p>
            <p>
              <button class="btn btn-primary btn-animated w-100">
                <span>Print Sample Product</span>
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
