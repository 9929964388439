<div class="form-floating">
  <input
    #dpValidation="ngbDatepicker"
    [formControl]="datepickerValidation"
    [navigation]="navigation"
    [class.is-invalid]="datepickerValidation.invalid"
    [class.is-valid]="datepickerValidation.valid"
    class="form-control"
    id="dpValidation"
    maxlength="10"
    ngbDatepicker
    placeholder=" "
    type="text"
  />

  <label for="dpValidation" class="form-label">Datepicker with validation</label>

  <button
    class="ngb-dp-open"
    (click)="dpValidation.toggle()"
    aria-label="Open calendar"
    type="button"
  >
    <i class="pi pi-3203" aria-hidden="true"></i>
  </button>

  <p class="valid-feedback">Valid feedback</p>
  <p class="invalid-feedback">Invalid feedback</p>
</div>
