<div class="row">
  <div class="col-md-6">
    <div class="toast-container" id="toast-container-left">
      <div class="toast toast-primary">
        <button class="toast-close-button" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
        <div class="toast-title">Primary</div>
        <div class="toast-message">Notification message.</div>
      </div>
      <div class="toast toast-success">
        <button class="toast-close-button" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
        <div class="toast-title">Success - You did it!</div>
      </div>
      <div class="toast toast-warning">
        <div class="toast-title">Warning Message</div>
        <div class="toast-message">Example without a button to close the toast.</div>
      </div>
      <div class="toast toast-danger">
        <button class="toast-close-button" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
        <div class="toast-title">Danger - Something went wrong...</div>
      </div>
      <div class="toast toast-info">
        <button class="toast-close-button" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
        <div class="toast-title">Information</div>
        <div class="toast-message">
          Important information with a long text. Lorem ipsum dolor sit amet, consetetur sadipscing
          elitr, sed diam nonumy eirmod.
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="toast-container" id="toast-container-right">
      <div class="toast toast-info pi-1001">
        <button class="toast-close-button" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
        <div class="toast-title">Special Icons (Black)</div>
        <div class="toast-message">
          Example with custom icon. Only needs the corresponding "pi"-class added to the toast.
        </div>
      </div>
      <div class="toast toast-primary pi-2063-white">
        <button class="toast-close-button" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
        <div class="toast-title">Special Icons (White)</div>
        <div class="toast-message">Example with white icon. Icon needs to be extended in sass.</div>
      </div>
      <div class="toast toast-primary no-icon">
        <button class="toast-close-button" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
        <div class="toast-title">Example without an icon</div>
        <div class="toast-message">Add class "no-icon" to the toast.</div>
      </div>
    </div>
  </div>
</div>

<div class="heading my-3">
  <h4>Examples of ngx-toastr</h4>
  <a
    href="https://www.npmjs.com/package/ngx-toastr"
    target="_blank"
    rel="noopener"
    class="btn btn-sm btn-primary me-2"
  >
    ngx-toastr Documentation
  </a>
  <a
    href="https://ngx-toastr.vercel.app/"
    target="_blank"
    rel="noopener"
    class="btn btn-sm btn-primary"
  >
    ngx-toastr Demo
  </a>
</div>
<p class="mb-3 mt-5">
  Screen reader output is a little better if the close button is disabled (alert messages can still
  be closed by clicking on them). Pass the following option to disable the close button:
</p>
<code
  class="d-block mt-3 mb-5"
  [highlight]="JSON.stringify(toastOptions, null, 2)"
  [languages]="['typescript']"
></code>
<section>
  <button class="btn btn-secondary btn-animated" (click)="showError()">
    <span>Show Danger Toast</span>
  </button>

  <button class="btn btn-secondary btn-animated ms-3" (click)="showSuccess()">
    <span>Show Success Toast</span>
  </button>

  <button class="btn btn-secondary btn-animated ms-3" (click)="showInfo()">
    <span>Show Info Toast</span>
  </button>

  <button class="btn btn-secondary btn-animated ms-3" (click)="showWarning()">
    <span>Show Warning Toast</span>
  </button>
</section>
