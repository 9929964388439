<div class="form-check form-switch">
  <input
    class="form-check-input"
    type="checkbox"
    role="switch"
    id="showLoadingCheckbox"
    [(ngModel)]="showLoading"
  />
  <label class="form-check-label" for="showLoadingCheckbox">Loading</label>
</div>

<ngx-datatable
  columnMode="force"
  [rows]="rows"
  [loadingIndicator]="showLoading"
  footerHeight="auto"
  headerHeight="auto"
  rowHeight="auto"
>
  <ngx-datatable-column prop="name"></ngx-datatable-column>
  <ngx-datatable-column prop="population"></ngx-datatable-column>
  <ngx-datatable-column prop="chosenAnimal"></ngx-datatable-column>
</ngx-datatable>
