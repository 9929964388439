<div class="heading py-5">
  <h1 class="bold font-curve-large">Timepicker</h1>
  <app-dependency-link></app-dependency-link>
</div>

<section>
  <app-timepicker-demo></app-timepicker-demo>
</section>

<h3 class="h5 bold mt-4">timepicker.component.html</h3>
<code class="block" [highlight]="codeTemplate" [languages]="['html']"></code>

<app-ngb-localization />
