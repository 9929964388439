<div class="heading py-5">
  <h1 class="bold font-curve-large">Alerts</h1>
  <app-dependency-link></app-dependency-link>
</div>

<div class="alert alert-warning mb-bigger-big">
  <p>
    The following two color variants are deprecated and will be removed in the next major version of
    the styles package:
  </p>
  <ul>
    <li>
      <code>alert-error</code>
      becomes
      <code>alert-danger</code>
    </li>
    <li>
      <code>alert-notification</code>
      becomes
      <code>alert-primary</code>
    </li>
  </ul>
</div>

<p>
  For more information about available alerts/notifications, check the
  <a [routerLink]="'../../bootstrap-samples/alerts'">
    Alert/Notification page under the "Bootstrap" Menu
  </a>
  .
</p>
<section>
  <app-ngb-alert-demo (setArray)="setArrayTemplate($event)"></app-ngb-alert-demo>
</section>
<!-- margin added as we have a fixed bottom alert -->
<code
  class="block mb-3"
  [highlight]="codeTemplate"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>

<app-ngb-localization />
