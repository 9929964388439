<blockquote class="blockquote">
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
</blockquote>

<blockquote class="blockquote">
  <q>
    Simple with q html tag. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
    erat a ante.
  </q>
</blockquote>

<figure>
  <blockquote class="blockquote">
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
  </blockquote>
  <figcaption class="blockquote-footer">
    Someone famous in
    <cite title="Source Title">Source Title</cite>
  </figcaption>
</figure>

<figure>
  <blockquote class="blockquote">
    <p>
      Blockquote with a longer text. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
      diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
      At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
      takimata sanctus est Lorem ipsum dolor sit amet.
    </p>
  </blockquote>
  <figcaption class="blockquote-footer">
    Someone famous in
    <cite title="Source Title">Source Title</cite>
  </figcaption>
</figure>

<blockquote class="blockquote" lang="de">
  <p>
    Blockquote with a different language. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Integer posuere erat a ante.
  </p>
</blockquote>
