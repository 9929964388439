<p class="d-flex flex-wrap align-items-center gap-small-r">
  <button type="button" class="btn btn-secondary btn-sm">
    <i aria-hidden="true" class="pi pi-3193"></i>
    <span>Some text</span>
  </button>
  <button type="button" class="btn btn-secondary btn-rg">
    <i aria-hidden="true" class="pi pi-3193"></i>
    <span>Some text</span>
  </button>
  <button type="button" class="btn btn-secondary btn-md">
    <i aria-hidden="true" class="pi pi-3193"></i>
    <span>Some text</span>
  </button>
  <button type="button" class="btn btn-secondary btn-lg">
    <i aria-hidden="true" class="pi pi-3193"></i>
    <span>Some text</span>
  </button>
</p>
<p class="d-flex flex-wrap align-items-center gap-small-r">
  <button type="button" class="btn btn-primary btn-sm">
    <span>Some text</span>
    <i aria-hidden="true" class="pi pi-3193"></i>
  </button>
  <button type="button" class="btn btn-primary btn-rg">
    <span>Some text</span>
    <i aria-hidden="true" class="pi pi-3193"></i>
  </button>
  <button type="button" class="btn btn-primary btn-md">
    <span>Some text</span>
    <i aria-hidden="true" class="pi pi-3193"></i>
  </button>
  <button type="button" class="btn btn-primary btn-lg">
    <span>Some text</span>
    <i aria-hidden="true" class="pi pi-3193"></i>
  </button>
</p>
