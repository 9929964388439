<div class="form-floating">
  <textarea class="form-control" placeholder="Comments" id="floatingTextarea2" rows="3"></textarea>
  <label for="floatingTextarea2">Comments</label>
</div>

<div class="form-floating mt-4">
  <textarea
    id="FormTextareaInvalid"
    type="text"
    class="form-control is-invalid"
    placeholder=" "
    required
    rows="3"
  ></textarea>
  <label class="form-label" for="FormTextareaInvalid">Invalid Textarea</label>
  <p class="invalid-feedback">Error message</p>
</div>

<div class="form-floating mt-4">
  <textarea
    id="FormTextareaValid"
    type="text"
    class="form-control is-valid"
    placeholder=" "
    rows="3"
  >
Value</textarea
  >
  <label class="form-label" for="FormTextareaValid">Valid Textarea</label>
  <p class="valid-feedback">Success message (optional)</p>
</div>
