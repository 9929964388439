<section>
  <h2>Headings</h2>
  <div class="row">
    <div class="col-md-6">
      <h1>Heading h1</h1>
      <h2>Heading h2</h2>
      <h3>Heading h3</h3>
      <h4>Heading h4</h4>
      <h5>Heading h5</h5>
      <h6>Heading h6</h6>
    </div>
    <div class="col-md-6">
      <h1>
        <span>Heading h1</span>
        <br />
        <span class="light">Subheading</span>
      </h1>
      <h2>
        <span>Heading h2</span>
        <br />
        <span class="light">Subheading</span>
      </h2>
      <h3>
        <span>Heading h3</span>
        <br />
        <span class="light">Subheading</span>
      </h3>
    </div>
  </div>

  <h2 class="mt-huge">Paragraphs</h2>
  <div class="row">
    <div class="col-md-6 d-flex flex-column">
      <h3 class="h5">Copy with lead (user-facing services)</h3>
      <article class="light font-curve-regular">
        <p class="lead">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi voluptatibus laborum atque
          explicabo consequuntur esse, ab debitis facere obcaecati aperiam suscipit officiis ut amet
          quasi optio porro odit maxime placeat.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut iste pariatur laboriosam
          possimus suscipit, ullam nemo, laborum minima itaque sed obcaecati. Neque officia, non
          similique eius repudiandae sequi totam nihil?
        </p>
      </article>
      <code class="block mt-auto" [highlight]="externalParagraph" [languages]="['html']"></code>
    </div>
    <div class="col-md-6 d-flex flex-column">
      <h3 class="h5">Default paragraph (internal usage)</h3>
      <p>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi voluptatibus laborum atque
        explicabo consequuntur esse, ab debitis facere obcaecati aperiam suscipit officiis ut amet
        quasi optio porro odit maxime placeat.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut iste pariatur laboriosam
        possimus suscipit, ullam nemo, laborum minima itaque sed obcaecati. Neque officia, non
        similique eius repudiandae sequi totam nihil?
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut iste pariatur laboriosam
        possimus suscipit, ullam nemo, laborum minima itaque sed obcaecati. Neque officia, non
        similique eius repudiandae sequi totam nihil?
      </p>
      <code class="block mt-auto" [highlight]="internalParagraph" [languages]="['html']"></code>
    </div>
    <div class="col-md-6"></div>
    <div class="col-md-6"></div>
  </div>

  <h2 class="mt-huge">Lists</h2>
  <div class="row">
    <div class="col-md-6">
      <ul>
        <li>Lorem ipsum dolor sit amet.</li>
        <li>Ipsa accusantium rerum in odio.</li>
        <li>Assumenda deleniti maxime atque aperiam.</li>
        <li>Voluptatibus consequuntur vitae nulla tempora?</li>
        <li>Ea velit voluptatum dignissimos reiciendis?</li>
      </ul>
    </div>
    <div class="col-md-6">
      <ol>
        <li>Lorem ipsum dolor sit amet.</li>
        <li>Ipsa accusantium rerum in odio.</li>
        <li>Assumenda deleniti maxime atque aperiam.</li>
        <li>Voluptatibus consequuntur vitae nulla tempora?</li>
        <li>Ea velit voluptatum dignissimos reiciendis?</li>
      </ol>
    </div>
  </div>
</section>
