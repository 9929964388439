<button type="button" class="btn btn-nightblue btn-animated"><span>Nightblue</span></button>
<button type="button" class="btn btn-nightblue-bright btn-animated">
  <span>Nightblue (Bright)</span>
</button>
<button type="button" class="btn btn-petrol btn-animated"><span>Petrol</span></button>
<button type="button" class="btn btn-petrol-bright btn-animated">
  <span>Petrol (Bright)</span>
</button>
<button type="button" class="btn btn-coral btn-animated"><span>Coral</span></button>
<button type="button" class="btn btn-coral-bright btn-animated"><span>Coral (Bright)</span></button>
<button type="button" class="btn btn-olive btn-animated"><span>Olive</span></button>
<button type="button" class="btn btn-olive-bright btn-animated"><span>Olive (Bright)</span></button>
<button type="button" class="btn btn-purple btn-animated"><span>Purple</span></button>
<button type="button" class="btn btn-purple-bright btn-animated">
  <span>Purple (Bright)</span>
</button>
<button type="button" class="btn btn-aubergine btn-animated"><span>Aubergine</span></button>
<button type="button" class="btn btn-aubergine-bright btn-animated">
  <span>Aubergine (Bright)</span>
</button>
