<div class="heading py-5">
  <h1 class="bold font-curve-large">Carousel</h1>
  <app-dependency-link></app-dependency-link>
</div>

<h3 class="mt-5 mb-3 bold">Basic example</h3>
<section>
  <app-ngb-carousel-demo></app-ngb-carousel-demo>
</section>
<code
  class="block"
  [highlight]="carouselTemplate"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>

<h3 class="mt-5 mb-3 bold">Light caption</h3>
<section>
  <app-ngb-carousel-light-demo></app-ngb-carousel-light-demo>
</section>
<code
  class="block"
  [highlight]="lightCarouselTemplate"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>

<app-ngb-localization />
