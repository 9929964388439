<div class="d-flex">
  <div>
    <label for="dropdownBasic1" class="form-label">Basic dropdown</label>
    <div ngbDropdown class="me-2">
      <button class="btn btn-primary" id="dropdownBasic1" ngbDropdownToggle>Toggle dropdown</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem>Action - 1</button>
        <button ngbDropdownItem class="active">Active action</button>
        <button ngbDropdownItem disabled>Something disabled</button>
        <hr />
        <button ngbDropdownItem>Action - 2</button>
        <button ngbDropdownItem>Action - 3</button>
        <button ngbDropdownItem>Action - 4</button>
        <button ngbDropdownItem>Action - 5</button>
        <button ngbDropdownItem>Action - 6</button>
        <button ngbDropdownItem>Action - 7</button>
        <button ngbDropdownItem>Action - 8</button>
        <button ngbDropdownItem>Action - 9</button>
        <button ngbDropdownItem>Action - 10</button>
        <button ngbDropdownItem>Action - 11</button>
      </div>
    </div>
  </div>
  <div>
    <label for="dropdownBasic2" class="form-label">Basic dropup</label>
    <div ngbDropdown placement="top-right" class="me-2">
      <button class="btn btn-secondary" id="dropdownBasic2" ngbDropdownToggle>Toggle dropup</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
        <button ngbDropdownItem>Action - 1</button>
        <button ngbDropdownItem>Another Action</button>
        <button ngbDropdownItem>Something else is here</button>
        <button ngbDropdownItem>Action - 2</button>
        <button ngbDropdownItem>Action - 3</button>
      </div>
    </div>
  </div>
  <div>
    <label for="dropdownBasic3" class="form-label">Long dropup with scroll</label>
    <div ngbDropdown placement="top-right" class="me-2">
      <button class="btn btn-primary" id="dropdownBasic3" ngbDropdownToggle>Toggle dropup</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
        <button ngbDropdownItem>Action - 1</button>
        <button ngbDropdownItem>Another Action</button>
        <button ngbDropdownItem>Something else is here</button>
        <button ngbDropdownItem>Action - 2</button>
        <button ngbDropdownItem>Action - 3</button>
        <button ngbDropdownItem>Action - 4</button>
        <button ngbDropdownItem>Action - 5</button>
        <button ngbDropdownItem>Action - 6</button>
        <button ngbDropdownItem>Action - 7</button>
        <button ngbDropdownItem>Action - 8</button>
        <button ngbDropdownItem>Action - 9</button>
        <button ngbDropdownItem>Action - 10</button>
        <button ngbDropdownItem>Action - 11</button>
        <button ngbDropdownItem>Action - 12</button>
        <button ngbDropdownItem>Action - 13</button>
        <button ngbDropdownItem>Action - 14</button>
      </div>
    </div>
  </div>
  <div>
    <label for="dropdownBasic4" class="form-label">Dropdown with icons</label>
    <div ngbDropdown class="me-2">
      <button class="btn btn-secondary" id="dropdownBasic4" ngbDropdownToggle>
        Toggle dropdown
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
        <button ngbDropdownItem class="btn-border-radius-sm">
          <i class="pi pi-3035"></i>
          <span>Action with icon</span>
        </button>

        <button ngbDropdownItem class="btn d-flex align-items-center justify-content-between">
          <span>
            <i class="pi pi-1001"></i>
            <span>Action with two icons</span>
          </span>
          <i class="pi pi-2050"></i>
        </button>

        <button ngbDropdownItem class="btn">
          <i class="pi"></i>
          <span>Action with empty icon</span>
        </button>

        <button ngbDropdownItem>
          Action name as if it was written by Ernest Hemingway who would not stop writing even if
          there's no point to do so whatsoever
        </button>
        <button ngbDropdownItem>Action - 5</button>
        <button ngbDropdownItem>Action - 6</button>
        <button ngbDropdownItem>Action - 7</button>
        <button ngbDropdownItem>Action - 8</button>
        <button ngbDropdownItem>Action - 9</button>
        <button ngbDropdownItem>Action - 10</button>
        <button ngbDropdownItem>Action - 11</button>
      </div>
    </div>
  </div>
</div>
