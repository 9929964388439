<div class="heading py-5">
  <h1 class="bold font-curve-large">Accordion</h1>
  <app-dependency-link></app-dependency-link>
</div>

<div class="alert alert-warning mb-big">
  <p class="alert-heading">Bootstrap accordions are deprecated and will soon be removed.</p>
  <p>
    For a similar behavior, check out our new
    <a
      href="https://design-system.post.ch/?path=/docs/4d1b4185-e04d-494a-ab38-2b56c1778b0b--docs"
      rel="noopener"
      target="_blank"
    >
      post-accordion component
    </a>
    .
  </p>
</div>

<section>
  <h3>Using JavaScript</h3>
  <div class="alert alert-info my-large">
    <p>
      For the JavaScript accordion to work properly, you need to import collapse.js from Bootstrap
      into your component.
    </p>
  </div>
  <code
    class="block my-big p-3"
    highlight="import 'bootstrap/js/dist/collapse';"
    [languages]="['javascript']"
  ></code>

  <app-accordion-demo></app-accordion-demo>
  <code
    class="block mt-big"
    [highlight]="codeTemplate"
    [languages]="['html', 'scss', 'css']"
  ></code>
</section>
