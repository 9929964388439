<fieldset aria-label="Radio Buttons with Reactive Froms" class="btn-group">
  <input
    [formControl]="radio"
    [value]="Choice.One"
    autocomplete="off"
    class="btn-check"
    id="btnradio1"
    type="radio"
  />
  <label class="btn btn-secondary" for="btnradio1">One</label>

  <input
    [formControl]="radio"
    [value]="Choice.Two"
    autocomplete="off"
    class="btn-check"
    id="btnradio2"
    type="radio"
  />
  <label class="btn btn-secondary" for="btnradio2">Two (pre-selected)</label>

  <input
    [formControl]="radio"
    [value]="Choice.Three"
    autocomplete="off"
    class="btn-check"
    id="btnradio3"
    type="radio"
  />
  <label class="btn btn-secondary" for="btnradio3">Three</label>

  <input
    [formControl]="radio"
    [value]="Choice.Four"
    autocomplete="off"
    class="btn-check"
    id="btnradio4"
    type="radio"
    [attr.disabled]="fourthIsDisabled ? '' : null"
  />
  <label class="btn btn-secondary" for="btnradio4">Four (disabled)</label>
</fieldset>
