<div class="position-relative">
  <input
    #dpValidation="ngbDatepicker"
    [formControl]="datepickerValidation"
    [navigation]="navigation"
    [class.form-control-sm]="inputSize === 'sm'"
    [class.form-control-rg]="inputSize === 'rg'"
    [class.is-invalid]="datepickerValidation.invalid"
    [class.is-valid]="datepickerValidation.valid"
    aria-label="Datepicker validation example"
    class="form-control"
    maxlength="10"
    ngbDatepicker
    placeholder="yyyy-mm-dd"
    type="text"
  />

  <button
    class="ngb-dp-open"
    (click)="dpValidation.toggle()"
    aria-label="Open calendar"
    type="button"
  >
    <i class="pi pi-3203" aria-hidden="true"></i>
  </button>

  <p class="valid-feedback">Valid feedback</p>
  <p class="invalid-feedback">Invalid feedback</p>
</div>
