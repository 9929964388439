<div class="modal-header p-5 mb-3 rounded-top bg-danger">
  <div class="text-center">
    <span aria-hidden="true" class="pi pi-3x pi-2104-white mt-4 mb-3"></span>
    <p class="h4 bold">Bitte prüfen Sie Ihre Angaben</p>
    <p class="h4">Einige Angaben zur Rückseite fehlen oder sind unvollständig.</p>
  </div>
</div>
<div class="modal-body">
  <p class="h5">Wenn Sie bestätigen wir die WebStamp möglicherweise unvollständig erstellt.</p>
</div>
<div class="modal-footer justify-content-start flex-row-reverse">
  <button type="button" class="btn btn-primary btn-animated" (click)="activeModal.close()">
    <span>Einverstanden</span>
  </button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Zurück</button>
</div>
