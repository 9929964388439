<div class="heading py-5">
  <h1 class="bold font-curve-large">Product Cards</h1>
  <app-dependency-link></app-dependency-link>
</div>

<section>
  <h3 class="bold mt-big-r mb-3">Card Buttons</h3>
  <app-card-button></app-card-button>
  <code
    class="block mt-3"
    [highlight]="postCardButtonHtml"
    [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
  ></code>
</section>

<section>
  <h3 class="bold mt-big-r mb-3">Product navigation</h3>
  <app-card-product-navigation></app-card-product-navigation>
  <code
    class="block mt-3"
    [highlight]="postCardProductNavigationHtml"
    [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
  ></code>
</section>

<section>
  <h3 class="bold mt-big-r mb-3">Product teaser with prices</h3>
  <div class="bg-light p-big">
    <app-card-product-teaser></app-card-product-teaser>
  </div>

  <h4 class="mt-4 mb-3">component.html</h4>
  <code
    class="block mt-3"
    [highlight]="postCardProductTeaserHtml"
    [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
  ></code>
  <h4 class="mt-4 mb-3">component.ts</h4>
  <code
    class="block mt-3"
    [highlight]="postCardProductTeaserTs"
    [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
  ></code>
</section>
