<h3>Form check examples</h3>
<div class="row row-cols-1 row-cols-rg-2 align-items-center">
  <!-- Basic checks -->
  <div class="col">
    <div class="form-check">
      <input class="form-check-input" id="formCheckDefault" type="checkbox" value="" />
      <label class="form-check-label" for="formCheckDefault">Default checkbox</label>
    </div>
    <div class="form-check">
      <input checked class="form-check-input" id="formCheckChecked" type="checkbox" value="" />
      <label class="form-check-label" for="formCheckChecked">Checked checkbox</label>
    </div>
    <div class="form-check">
      <input
        #indeterminate
        class="form-check-input"
        id="formCheckIndeterminate"
        type="checkbox"
        value=""
      />
      <label class="form-check-label" for="formCheckIndeterminate">Indeterminate checkbox</label>
    </div>
  </div>

  <!-- Checks on colored background -->
  <div class="col">
    <div class="bg-nightblue-bright py-large px-big">
      <div class="form-check">
        <input class="form-check-input" id="formCheckDefaultColoredBg" type="checkbox" value="" />
        <label class="form-check-label" for="formCheckDefaultColoredBg">Default checkbox</label>
      </div>
      <div class="form-check">
        <input
          checked
          class="form-check-input"
          id="formCheckCheckedColoredBg"
          type="checkbox"
          value=""
        />
        <label class="form-check-label" for="formCheckCheckedColoredBg">Checked checkbox</label>
      </div>
      <div class="form-check">
        <input
          #indeterminate
          class="form-check-input"
          id="formCheckIndeterminateColoredBg"
          type="checkbox"
          value=""
        />
        <label class="form-check-label" for="formCheckIndeterminateColoredBg">
          Indeterminate checkbox
        </label>
      </div>
    </div>
  </div>
</div>

<h3 class="mt-4">Inline checks</h3>
<div class="form-check form-check-inline">
  <input class="form-check-input" id="inlineCheck1" type="checkbox" value="option1" />
  <label class="form-check-label" for="inlineCheck1">1</label>
</div>
<div class="form-check form-check-inline">
  <input class="form-check-input" id="inlineCheck2" type="checkbox" value="option2" />
  <label class="form-check-label" for="inlineCheck2">2</label>
</div>

<h3 class="mt-4">Check without label</h3>
<input
  aria-label="This label is required for accessibility"
  class="form-check-input"
  id="formCheckNoLabel"
  type="checkbox"
  value=""
/>

<h3 class="mt-4">Check with long label</h3>
<div class="row row-cols-1 row-cols-rg-2">
  <div class="col">
    <div class="form-check">
      <input class="form-check-input" id="formCheckLongLabel" type="checkbox" value="" />
      <label class="form-check-label" for="formCheckLongLabel">
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
        invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
        et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
        Lorem ipsum dolor sit amet.
      </label>
    </div>
  </div>
</div>

<h3 class="mt-4">Validation</h3>
<div class="row row-cols-1 row-cols-rg-2 row-cols-lg-3">
  <div class="col">
    <div class="form-check">
      <input class="form-check-input is-invalid" id="FormCheckboxInvalid" type="checkbox" />
      <label class="form-check-label" for="FormCheckboxInvalid">Invalid Checkbox</label>
      <p class="invalid-feedback">Error message</p>
    </div>
  </div>
  <div class="col">
    <div class="form-check">
      <input class="form-check-input is-valid" id="FormCheckboxValid" type="checkbox" checked />
      <label class="form-check-label" for="FormCheckboxValid">Valid Checkbox</label>
      <p class="valid-feedback">Success message (optional)</p>
    </div>
  </div>
</div>

<div class="row row-cols-1 row-cols-rg-2 row-cols-lg-3 pt-4 bg-light">
  <div class="col">
    <div class="form-check">
      <input class="form-check-input is-invalid" id="FormCheckboxInvalidOnLight" type="checkbox" />
      <label class="form-check-label" for="FormCheckboxInvalidOnLight">Invalid Checkbox</label>
      <p class="invalid-feedback">Error message</p>
    </div>
  </div>
  <div class="col">
    <div class="form-check">
      <input
        class="form-check-input is-valid"
        id="FormCheckboxValidOnLight"
        type="checkbox"
        checked
      />
      <label class="form-check-label" for="FormCheckboxValidOnLight">Valid Checkbox</label>
      <p class="valid-feedback">Success message (optional)</p>
    </div>
  </div>
</div>

<div class="row row-cols-1 row-cols-rg-2 row-cols-lg-3 pt-4 bg-dark">
  <div class="col">
    <div class="form-check">
      <input class="form-check-input is-invalid" id="FormCheckboxInvalidOnDark" type="checkbox" />
      <label class="form-check-label" for="FormCheckboxInvalidOnDark">Invalid Checkbox</label>
      <p class="invalid-feedback">Error message</p>
    </div>
  </div>
  <div class="col">
    <div class="form-check">
      <input
        class="form-check-input is-valid"
        id="FormCheckboxValidOnDark"
        type="checkbox"
        checked
      />
      <label class="form-check-label" for="FormCheckboxValidOnDark">Valid Checkbox</label>
      <p class="valid-feedback">Success message (optional)</p>
    </div>
  </div>
</div>
