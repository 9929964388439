<label for="street" class="form-label">Invalid Feedback</label>
<input class="form-control is-invalid" id="street" type="text" placeholder="Street" />
<div class="invalid-feedback">There is an error!</div>

<label for="control" class="form-label">Valid Feedback</label>
<input class="form-control is-valid" id="control" type="text" placeholder="Name" />

<label for="custom" class="form-label">Valid Feedback with message</label>
<input class="form-control is-valid" id="custom" type="text" placeholder="Welcome" />
<div class="valid-feedback">Everything is fine.</div>
