<div class="p-regular d-flex flex-wrap gap-regular">
  <button disabled class="btn btn-primary btn-animated"><span>Primary</span></button>
  <button disabled class="btn btn-secondary btn-animated"><span>Secondary</span></button>
  <button disabled class="btn btn-link">Tertiary</button>
</div>

<div class="bg-primary m-huge-y p-regular d-flex flex-wrap gap-regular">
  <button disabled class="btn btn-primary btn-animated"><span>Primary</span></button>
  <button disabled class="btn btn-secondary btn-animated"><span>Secondary</span></button>
  <button disabled class="btn btn-link">Tertiary</button>
</div>
