<div class="heading py-5">
  <h1 class="bold font-curve-large">Progressbar</h1>
  <app-dependency-link></app-dependency-link>
</div>

<section>
  <app-ngb-progressbar-demo></app-ngb-progressbar-demo>
</section>
<code
  class="block"
  [highlight]="codeTemplate"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>

<app-ngb-localization>
  <p class="light font-curve-regular mt-4">
    The progress bar also requires an
    <code>ariaLabel</code>
    attribute which provides context to the current progress. See the example above for how to use
    it.
  </p>
</app-ngb-localization>
