<h4>Links</h4>
<div class="d-flex flex-wrap align-items-center gap-3">
  <a href="javascript:;" class="badge">Link Badge</a>
  <a href="javascript:;" class="badge active">Link Badge Active</a>
  <a href="javascript:;" class="badge badge-sm">Link Badge Small</a>
  <a href="javascript:;" class="badge badge-sm active">Link Badge Small Active</a>
</div>

<div class="d-flex flex-wrap align-items-center gap-3">
  <a href="javascript:;" class="badge">
    <span>Nested Link Badge</span>
    <span class="badge">10</span>
  </a>
  <a href="javascript:;" class="badge active">
    <span>Nested Link Badge Active</span>
    <span class="badge">10</span>
  </a>
  <a href="javascript:;" class="badge badge-sm">
    <span>Nested Link Badge Small</span>
    <span class="badge">10</span>
  </a>
  <a href="javascript:;" class="badge badge-sm active">
    <span>Nested Link Badge Small Active</span>
    <span class="badge">10</span>
  </a>
</div>

<h4>Buttons</h4>
<div class="d-flex flex-wrap align-items-center gap-3">
  <button type="button" class="badge">Button Badge</button>
  <button type="button" class="badge active">Button Badge Active</button>
  <button type="button" class="badge badge-sm">Button Badge Small</button>
  <button type="button" class="badge badge-sm active">Button Badge Small Active</button>
</div>

<div class="d-flex flex-wrap align-items-center gap-3">
  <button type="button" class="badge">
    <span>Nested Button Badge</span>
    <span class="badge">10</span>
  </button>
  <button type="button" class="badge active">
    <span>Nested Button Badge Active</span>
    <span class="badge">10</span>
  </button>
  <button type="button" class="badge badge-sm">
    <span>Nested Button Badge Small</span>
    <span class="badge">10</span>
  </button>
  <button type="button" class="badge badge-sm active">
    <span>Nested Button Badge Small Active</span>
    <span class="badge">10</span>
  </button>
</div>

<h4>Checkable</h4>
<div class="d-flex flex-wrap align-items-center gap-3">
  <div class="badge-check">
    <input id="CheckableBadge" class="badge-check-input" type="checkbox" value="" />
    <label class="badge-check-label" for="CheckableBadge">Checkable Badge</label>
  </div>
  <div class="badge-check">
    <input id="CheckableBadgeActive" class="badge-check-input" type="checkbox" value="" checked />
    <label class="badge-check-label" for="CheckableBadgeActive">Checkable Badge Active</label>
  </div>
  <div class="badge-check">
    <input id="CheckableBadgeSmall" class="badge-check-input" type="checkbox" value="" />
    <label class="badge-check-label badge-sm" for="CheckableBadgeSmall">
      Checkable Badge Small
    </label>
  </div>
  <div class="badge-check">
    <input
      id="CheckableBadgeSmallActive"
      class="badge-check-input"
      type="checkbox"
      value=""
      checked
    />
    <label class="badge-check-label badge-sm" for="CheckableBadgeSmallActive">
      Checkable Badge Small Active
    </label>
  </div>
</div>

<div class="d-flex flex-wrap align-items-center gap-3">
  <div class="badge-check">
    <input id="CheckableBadgeNested" class="badge-check-input" type="checkbox" value="" />
    <label class="badge-check-label" for="CheckableBadgeNested">
      <span>Nested Checkable Badge</span>
      <span class="badge">10</span>
    </label>
  </div>
  <div class="badge-check">
    <input
      id="CheckableBadgeActiveNested"
      class="badge-check-input"
      type="checkbox"
      value=""
      checked
    />
    <label class="badge-check-label" for="CheckableBadgeActiveNested">
      <span>Nested Checkable Badge Active</span>
      <span class="badge">10</span>
    </label>
  </div>
  <div class="badge-check">
    <input id="CheckableBadgeNestedSmall" class="badge-check-input" type="checkbox" value="" />
    <label class="badge-check-label badge-sm" for="CheckableBadgeNestedSmall">
      <span>Nested Checkable Badge Small</span>
      <span class="badge">10</span>
    </label>
  </div>
  <div class="badge-check">
    <input
      id="CheckableBadgeActiveNestedSmall"
      class="badge-check-input"
      type="checkbox"
      value=""
      checked
    />
    <label class="badge-check-label badge-sm" for="CheckableBadgeActiveNestedSmall">
      <span>Nested Checkable Badge Small Active</span>
      <span class="badge">10</span>
    </label>
  </div>
</div>

<h4>Dismissable</h4>
<div class="d-flex flex-wrap align-items-center gap-3">
  <span class="badge">
    <span>Dismissible Badge</span>
    <button type="button" class="btn-close" aria-label="dismiss"></button>
  </span>
  <span class="badge badge-sm">
    <span>Dismissible Badge Small</span>
    <button type="button" class="btn-close" aria-label="dismiss"></button>
  </span>
</div>

<div class="d-flex flex-wrap align-items-center gap-3">
  <span class="badge">
    <span>Nested Dismissible Badge</span>
    <span class="badge">10</span>
    <button type="button" class="btn-close" aria-label="dismiss"></button>
  </span>
  <span class="badge badge-sm">
    <span>Nested Dismissible Badge Small</span>
    <span class="badge">10</span>
    <button type="button" class="btn-close" aria-label="dismiss"></button>
  </span>
</div>
