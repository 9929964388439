<h3>Default modal</h3>
<button type="button" class="btn btn-secondary" (click)="open()">Launch default modal</button>
<hr />

<h3>Sizes</h3>
<button type="button" class="btn btn-secondary me-2" (click)="open({ size: 'sm' })">
  Small modal
</button>
<button type="button" class="btn btn-secondary me-2" (click)="open({ size: 'lg' })">
  Large modal
</button>
<button type="button" class="btn btn-secondary" (click)="open({ size: 'xl' })">
  Extra large modal
</button>
<hr />

<h3>Long content</h3>
<p>When the modal is too long for the viewport, its body becomes scrollable.</p>
<button type="button" class="btn btn-secondary" (click)="openWithLongContent()">
  Launch modal with long content
</button>
<hr />

<h3>Trigger with a tooltip</h3>
<button
  type="button"
  class="btn btn-secondary"
  ngbTooltip="Tooltip on right"
  placement="right"
  (click)="open()"
>
  I am a modal Trigger with a tooltip
</button>
