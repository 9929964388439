<div class="form-floating">
  <input class="form-control form-control-lg" id="floatingInput" placeholder=" " type="email" />
  <label class="form-label" for="floatingInput">.form-control-lg</label>
</div>

<input
  aria-label="Required for accessibility on inputs that do not have an associated <label>"
  class="form-control form-control-lg"
  placeholder=".form-control-lg"
  type="text"
/>

<input
  aria-label="default input example"
  class="form-control"
  placeholder="Default input"
  type="text"
/>

<input
  aria-label="Required for accessibility on inputs that do not have an associated <label>"
  class="form-control form-control-rg"
  placeholder=".form-control-rg"
  type="text"
/>

<input
  aria-label="Required for accessibility on inputs that do not have an associated <label>"
  class="form-control form-control-sm"
  placeholder=".form-control-sm"
  type="text"
/>

<div class="form-floating mt-4">
  <input
    id="FormControlInvalid"
    type="text"
    class="form-control is-invalid"
    placeholder=" "
    required
  />
  <label class="form-label" for="FormControlInvalid">Invalid Input</label>
  <p class="invalid-feedback">Error message</p>
</div>

<div class="form-floating mt-4">
  <input
    id="FormControlValid"
    type="text"
    class="form-control is-valid"
    placeholder=" "
    value="Value"
  />
  <label class="form-label" for="FormControlValid">Valid Input</label>
  <p class="valid-feedback">Success message (optional)</p>
</div>
