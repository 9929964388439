/*
 * Copyright 2022 by Swiss Post, Information Technology
 */

import { Component } from '@angular/core';

@Component({
  selector: 'app-switch-demo',
  templateUrl: './form-switch-demo.component.html',
})
export class FormSwitchDemoComponent {}
