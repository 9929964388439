<ngx-datatable
  [ngClass]="{
    'datatable-spaced-cells': tableType === 'spaced-cells',
    'datatable-striped-rows': tableType === 'striped-rows',
    'datatable-inverted': tableType === 'inverted-colors',
    'datatable-flush': tableType === 'lines-separated-rows'
  }"
  [rows]="records"
  headerHeight="auto"
  rowHeight="auto"
>
  <!-- Column with name and prop
       The name is the title of the column and the prop is the property that is used to access the value of each row -->
  <ngx-datatable-column
    [cellClass]="headerColumn ? 'datatable-header-cell' : ''"
    name="Debtor"
    prop="debtorId"
  ></ngx-datatable-column>

  <!-- Column with name only, the prop is deduced from it -->
  <ngx-datatable-column name="RRN"></ngx-datatable-column>

  <!-- Column with prop only, the name is built from it -->
  <ngx-datatable-column [sortable]="false" prop="billingNumber"></ngx-datatable-column>

  <!-- Column with cell template -->
  <ngx-datatable-column [sortable]="false" prop="billingMonth">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{ value | date : 'MMM y' : undefined : 'en' }}
    </ng-template>
  </ngx-datatable-column>

  <!-- Column with badges -->
  <ngx-datatable-column [sortable]="false" prop="keyword">
    <ng-template let-value="value" ngx-datatable-cell-template>
      <span class="badge rounded-pill bg-white">{{ value }}</span>
    </ng-template>
  </ngx-datatable-column>

  <!-- Column with dropdowns -->
  <ngx-datatable-column [sortable]="false" cellClass="datatable-button-cell" prop="status">
    <ng-template let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
      <div class="d-inline-block" container="body" ngbDropdown>
        <button [id]="'statusDropdown' + rowIndex" class="datatable-button" ngbDropdownToggle>
          {{ value | titlecase }}
        </button>
        <div [aria-labelledby]="'statusDropdown' + rowIndex" ngbDropdownMenu>
          <ng-container *ngFor="let status of statuses">
            <button
              (click)="records[rowIndex].status = status"
              *ngIf="status !== value"
              ngbDropdownItem
            >
              {{ status | titlecase }}
            </button>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <!-- Column with buttons -->
  <ngx-datatable-column [sortable]="false">
    <ng-template ngx-datatable-cell-template>
      <button class="btn btn-rg btn-secondary" type="button">Modify</button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
