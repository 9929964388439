<div class="form-floating">
  <select aria-label="Floating label select example" class="form-select" id="floatingSelect">
    <option value="1">One</option>
    <option value="2">Two</option>
    <option value="3">Three</option>
  </select>
  <label for="floatingSelect">Floatinglabel form-select</label>
</div>

<select aria-label="Default select example" class="form-select">
  <option selected>Default select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>

<select aria-label=".form-select-rg example" class="form-select form-select-rg">
  <option selected>.form-select-rg</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>

<select aria-label=".form-select-sm example" class="form-select form-select-sm">
  <option selected>.form-select-sm</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>

<div class="form-floating mt-4">
  <select id="FormSelectInvalid" class="form-select is-invalid" required>
    <option disabled>Select one..</option>
    <option value="1">Value 1</option>
    <option value="2">Value 2</option>
  </select>
  <label class="form-label" for="FormSelectInvalid">Invalid Select</label>
  <p class="invalid-feedback">Error message</p>
</div>

<div class="form-floating mt-4">
  <select id="FormSelectValid" class="form-select is-valid">
    <option disabled>Select one..</option>
    <option value="1">Value 1</option>
    <option value="2">Value 2</option>
  </select>
  <label class="form-label" for="FormSelectValid">Valid Select</label>
  <p class="valid-feedback">Success message (optional)</p>
</div>
