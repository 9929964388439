<ngx-datatable
  [limit]="nbRowsPerPage"
  [offset]="page - 1"
  [rows]="rows"
  class="w-75"
  columnMode="force"
  footerHeight="auto"
  headerHeight="auto"
  rowHeight="auto"
>
  <ngx-datatable-column [width]="600" name="Name"></ngx-datatable-column>
  <ngx-datatable-column name="ID"></ngx-datatable-column>
  <ngx-datatable-column name="Price">
    <ng-template let-value="value" ngx-datatable-cell-template>
      {{ value === 0 ? 'Gratis' : 'CHF ' + value }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template>
      <div
        *ngIf="nbRowsPerPage === undefined || rows.length > nbRowsPerPage"
        class="datatable-paginated-footer"
      >
        <!-- Standard pagination -->
        <ng-container *ngIf="paginationMode === 'standard'; else paginationWithLoader">
          <label class="col-form-label datatable-row-count-label" for="pageSize">
            Rows per page
          </label>
          <select
            [(ngModel)]="nbRowsPerPage"
            class="form-select datatable-row-count-input"
            id="pageSize"
          >
            <option [ngValue]="5">5</option>
            <option [ngValue]="10">10</option>
            <option [ngValue]="20">20</option>
            <option [ngValue]="undefined">All</option>
          </select>
          <ngb-pagination
            [(page)]="page"
            [collectionSize]="rows.length"
            [maxSize]="3"
            [pageSize]="nbRowsPerPage"
            [rotate]="true"
            class="datatable-pagination"
          ></ngb-pagination>
        </ng-container>

        <!-- Pagination with a loader -->
        <ng-template #paginationWithLoader>
          <button
            type="button"
            class="btn btn-secondary btn-icon datatable-loader"
            [disabled]="loading"
            (click)="loadMore()"
          >
            <span class="visually-hidden">Load 5 more</span>
            <em class="pi pi-2041" aria-hidden="true"></em>
          </button>
        </ng-template>
      </div>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>
