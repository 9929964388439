<footer class="bg-light mt-big-r">
  <div class="container pt-big-r pb-big-r">
    <h2 class="bold">Support</h2>

    <div class="d-flex flex-wrap mt-huge-r mb-huge-r profile-list">
      <ng-container *ngFor="let dev of shuffledDevs">
        <article class="avatar">
          <img [src]="dev.avatar" [alt]="" class="profile-picture" />
          <div>
            <p class="bold">{{ dev.name }}</p>
            <p>{{ dev.title }}</p>
          </div>
        </article>
      </ng-container>
    </div>

    <div class="row mt-regular-r">
      <div class="col-12 col-rg-auto mt-regular-r">
        <a
          href="https://github.com/swisspost/design-system/issues"
          target="_blank"
          rel="noopener"
          class="btn-primary btn btn-rg btn-animated"
        >
          <span>Submit an issue</span>
        </a>
      </div>
    </div>
  </div>
</footer>

<footer class="container d-flex justify-content-between py-regular">
  <span class="bold">&copy; 2024 Swiss Post Ltd.</span>
  <a href="https://github.com/swisspost/design-system" target="_blank" rel="noopener">
    Improve this page
  </a>
</footer>
