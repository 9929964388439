<blockquote class="blockquote">
  <q>
    Nested quotes, consectetur adipiscing elit.
    <q>I'm nested!</q>
    Integer posuere erat a ante.
  </q>
</blockquote>

<blockquote class="blockquote" lang="de">
  <q>
    It also works with a different language! Lorem ipsum dolor sit amet, consectetur adipiscing
    elit.
    <q>I'm nested!</q>
    Integer posuere erat a ante.
  </q>
</blockquote>

<figure>
  <blockquote class="blockquote">
    <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</q>
  </blockquote>
  <figcaption class="blockquote-footer">
    Someone famous in
    <cite title="Source Title">Source Title</cite>
  </figcaption>
</figure>
