<div class="tabs-wrapper" [class]="wrapperBg ? 'bg-' + wrapperBg : ''">
  <!-- the conditional color classes are for demo purposes only -->
  <div class="container">
    <ul ngbNav #nav="ngbNav" class="tabs" [class]="tabsBg ? 'bg-' + tabsBg : ''">
      <li ngbNavItem>
        <a href="" ngbNavLink class="tab-title">Mantis Shrimp</a>
        <ng-template ngbNavContent ngPreserveWhitespaces>
          <div class="row">
            <div class="col-md-4">
              <figure>
                <img src="https://i.imgur.com/SVgu1UI.jpg" alt="Mantis Shrimp on the sea floor" />
                <figcaption class="mt-1">
                  <em>
                    Photo by
                    <a
                      href="https://unsplash.com/@dorographie?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
                    >
                      Dorothea OLDANI
                    </a>
                    on
                    <a
                      href="https://unsplash.com/s/photos/mantis-shrimp?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
                    >
                      Unsplash
                    </a>
                  </em>
                </figcaption>
              </figure>
            </div>
            <div class="col-md-8">
              <h3 class="bold h2 mb-0">Mantis shrimp</h3>
              <h4 class="light h2 mb-4">The heaviest punch</h4>
              <p>
                <strong>Mantis shrimps</strong>
                , or
                <strong>stomatopods</strong>
                , are
                <a
                  href="https://en.wikipedia.org/wiki/Carnivorous"
                  class="mw-redirect"
                  title="Carnivorous"
                >
                  carnivorous
                </a>
                <a
                  href="https://en.wikipedia.org/wiki/Marine_animal"
                  class="mw-redirect"
                  title="Marine animal"
                >
                  marine
                </a>
                <a href="https://en.wikipedia.org/wiki/Crustacean" title="Crustacean">
                  crustaceans
                </a>
                of the
                <a href="https://en.wikipedia.org/wiki/Order_(biology)" title="Order (biology)">
                  order
                </a>
                <strong>Stomatopoda</strong>
                ,
                <a href="https://en.wikipedia.org/wiki/Phylogenetic_tree" title="Phylogenetic tree">
                  branching
                </a>
                from other members of the class
                <a href="https://en.wikipedia.org/wiki/Malacostraca" title="Malacostraca">
                  Malacostraca
                </a>
                around 340 million years ago.
                <sup id="cite_ref-:0_2-0" class="reference"><a href="#cite_note-:0-2">[2]</a></sup>
                Mantis shrimps typically grow to around 10&nbsp;cm (3.9&nbsp;in) in length, while a
                few can reach up to 38&nbsp;cm (15&nbsp;in).
                <sup id="cite_ref-Hawaii_3-0" class="reference">
                  <a href="#cite_note-Hawaii-3">[3]</a>
                </sup>
                The largest mantis shrimp ever caught had a length of 46&nbsp;cm (18&nbsp;in); it
                was caught in the
                <a
                  href="https://en.wikipedia.org/wiki/Indian_River_(Florida)"
                  title="Indian River (Florida)"
                >
                  Indian River
                </a>
                near
                <a
                  href="https://en.wikipedia.org/wiki/Fort_Pierce,_Florida"
                  title="Fort Pierce, Florida"
                >
                  Fort Pierce, Florida
                </a>
                , in the
                <a href="https://en.wikipedia.org/wiki/United_States" title="United States">
                  United States
                </a>
                .
                <sup id="cite_ref-ChinaTimes_4-0" class="reference">
                  <a href="#cite_note-ChinaTimes-4">[4]</a>
                </sup>
                A mantis shrimp's
                <a href="https://en.wikipedia.org/wiki/Carapace" title="Carapace">carapace</a>
                (the bony, thick shell that covers crustaceans and some other species) covers only
                the rear part of the head and the first four segments of the
                <a href="https://en.wikipedia.org/wiki/Thorax" title="Thorax">thorax</a>
                . Varieties range in color from shades of brown to vivid colors, with more than 450
                species of mantis shrimps being known. They are among the most important
                <a
                  href="https://en.wikipedia.org/wiki/Predator"
                  class="mw-redirect"
                  title="Predator"
                >
                  predators
                </a>
                in many shallow,
                <a href="https://en.wikipedia.org/wiki/Tropics" title="Tropics">tropical</a>
                and
                <a href="https://en.wikipedia.org/wiki/Subtropics" title="Subtropics">
                  subtropical
                </a>
                marine
                <a href="https://en.wikipedia.org/wiki/Habitat" title="Habitat">habitats</a>
                . However, despite being common, they are poorly understood, as many species spend
                most of their lives tucked away in burrows and holes.
                <sup id="cite_ref-5" class="reference"><a href="#cite_note-5">[5]</a></sup>
              </p>
              <p>
                Called "sea locusts" by
                <a href="https://en.wikipedia.org/wiki/Assyria" title="Assyria">ancient</a>
                <a href="https://en.wikipedia.org/wiki/Assyrian_people" title="Assyrian people">
                  Assyrians
                </a>
                , "prawn killers" in Australia,
                <sup id="cite_ref-6" class="reference"><a href="#cite_note-6">[6]</a></sup>
                and now sometimes referred to as "thumb splitters" — because of the animal's ability
                to inflict painful wounds if handled incautiously
                <sup id="cite_ref-7" class="reference"><a href="#cite_note-7">[7]</a></sup>
                — mantis shrimps have powerful
                <a href="https://en.wikipedia.org/wiki/Raptorial" title="Raptorial">raptorials</a>
                that are used to attack and kill prey either by spearing, stunning, or
                <a
                  href="https://en.wikipedia.org/wiki/Dismember"
                  class="mw-redirect"
                  title="Dismember"
                >
                  dismembering
                </a>
                . Some mantis shrimp species have specialised calcified "clubs" that can strike with
                great power, while others have sharp forelimbs used to seize the prey (hence the
                term "mantis" in its
                <a href="https://en.wikipedia.org/wiki/Common_name" title="Common name">
                  common name
                </a>
                ).
              </p>
            </div>
          </div>
        </ng-template>
      </li>
      <li ngbNavItem>
        <a href="" ngbNavLink class="tab-title">Tardigrades</a>
        <ng-template ngbNavContent ngPreserveWhitespaces>
          <div class="row">
            <div class="col-md-4">
              <img src="https://i.imgur.com/sroraOT.jpg" alt="" />
            </div>
            <div class="col-md-8">
              <h3 class="bold h2 mb-0">Tardigrades</h3>
              <h4 class="light h2 mb-4">The toughest guy</h4>
              <p>
                <strong>Tardigrades</strong>
                (
                <span class="rt-commentedText nowrap">
                  <span class="IPA nopopups noexcerpt">
                    <a
                      href="https://en.wikipedia.org/wiki/Help:IPA/English"
                      title="Help:IPA/English"
                    >
                      /
                      <span style="border-bottom: 1px dotted">
                        <span title="/ˈ/: primary stress follows">ˈ</span>
                        <span title="'t' in 'tie'">t</span>
                        <span title="/ɑːr/: 'ar' in 'far'">ɑːr</span>
                        <span title="'d' in 'dye'">d</span>
                        <span title="/ɪ/: 'i' in 'kit'">ɪ</span>
                        <span title="/ɡ/: 'g' in 'guy'">ɡ</span>
                        <span title="'r' in 'rye'">r</span>
                        <span title="/eɪ/: 'a' in 'face'">eɪ</span>
                        <span title="'d' in 'dye'">d</span>
                      </span>
                      /
                    </a>
                  </span>
                </span>
                ), known colloquially as
                <strong>water bears</strong>
                or
                <strong>moss piglets</strong>
                ,
                <sup id="cite_ref-American_Scientist_1-0" class="reference">
                  <a href="#cite_note-American_Scientist-1">[1]</a>
                </sup>
                <sup id="cite_ref-WRD-20140321_2-0" class="reference">
                  <a href="#cite_note-WRD-20140321-2">[2]</a>
                </sup>
                <sup id="cite_ref-3" class="reference"><a href="#cite_note-3">[3]</a></sup>
                <sup id="cite_ref-4" class="reference"><a href="#cite_note-4">[4]</a></sup>
                are a
                <a href="https://en.wikipedia.org/wiki/Phylum" title="Phylum">phylum</a>
                of eight-legged
                <a
                  href="https://en.wikipedia.org/wiki/Segmentation_(biology)"
                  title="Segmentation (biology)"
                >
                  segmented
                </a>
                <a href="https://en.wikipedia.org/wiki/Micro-animal" title="Micro-animal">
                  micro-animals
                </a>
                .
                <sup id="cite_ref-American_Scientist_1-1" class="reference">
                  <a href="#cite_note-American_Scientist-1">[1]</a>
                </sup>
                <sup id="cite_ref-5" class="reference"><a href="#cite_note-5">[5]</a></sup>
                They were first described by the German zoologist
                <a
                  href="https://en.wikipedia.org/wiki/Johann_August_Ephraim_Goeze"
                  title="Johann August Ephraim Goeze"
                >
                  Johann August Ephraim Goeze
                </a>
                in 1773, who called them
                <strong>little water bears</strong>
                . In 1777, the Italian biologist
                <a
                  href="https://en.wikipedia.org/wiki/Lazzaro_Spallanzani"
                  title="Lazzaro Spallanzani"
                >
                  Lazzaro Spallanzani
                </a>
                named them
                <strong>Tardigrada</strong>
                <span class="rt-commentedText nowrap">
                  <span class="IPA nopopups noexcerpt">
                    <a
                      href="https://en.wikipedia.org/wiki/Help:IPA/English"
                      title="Help:IPA/English"
                    >
                      /
                      <span style="border-bottom: 1px dotted">
                        <span title="'t' in 'tie'">t</span>
                        <span title="/ɑːr/: 'ar' in 'far'">ɑːr</span>
                        <span title="/ˈ/: primary stress follows">ˈ</span>
                        <span title="'d' in 'dye'">d</span>
                        <span title="/ɪ/: 'i' in 'kit'">ɪ</span>
                        <span title="/ɡ/: 'g' in 'guy'">ɡ</span>
                        <span title="'r' in 'rye'">r</span>
                        <span title="/ə/: 'a' in 'about'">ə</span>
                        <span title="'d' in 'dye'">d</span>
                        <span title="/ə/: 'a' in 'about'">ə</span>
                      </span>
                      /
                    </a>
                  </span>
                </span>
                , which means "slow steppers".
                <sup id="cite_ref-Bordenstein_6-0" class="reference">
                  <a href="#cite_note-Bordenstein-6">[6]</a>
                </sup>
              </p>
              <p>
                They have been found everywhere, from mountaintops to the
                <a href="https://en.wikipedia.org/wiki/Deep_sea" title="Deep sea">deep sea</a>
                and
                <a
                  href="https://en.wikipedia.org/wiki/Mud_volcanoes"
                  class="mw-redirect"
                  title="Mud volcanoes"
                >
                  mud volcanoes
                </a>
                ,
                <sup id="cite_ref-7" class="reference"><a href="#cite_note-7">[7]</a></sup>
                and from
                <a
                  href="https://en.wikipedia.org/wiki/Tropical_rainforest"
                  title="Tropical rainforest"
                >
                  tropical rainforests
                </a>
                to the
                <a href="https://en.wikipedia.org/wiki/Antarctic" title="Antarctic">Antarctic</a>
                .
                <sup id="cite_ref-8" class="reference"><a href="#cite_note-8">[8]</a></sup>
                Tardigrades are among the most resilient animals known,
                <sup id="cite_ref-WP-20170714_9-0" class="reference">
                  <a href="#cite_note-WP-20170714-9">[9]</a>
                </sup>
                <sup id="cite_ref-NAT-20170714_10-0" class="reference">
                  <a href="#cite_note-NAT-20170714-10">[10]</a>
                </sup>
                with individual species able to survive extreme conditions—such as exposure to
                extreme temperatures, extreme
                <a
                  href="https://en.wikipedia.org/wiki/Pressures"
                  class="mw-redirect"
                  title="Pressures"
                >
                  pressures
                </a>
                (both high and low), air deprivation,
                <a href="https://en.wikipedia.org/wiki/Radiation" title="Radiation">radiation</a>
                ,
                <a href="https://en.wikipedia.org/wiki/Dehydration" title="Dehydration">
                  dehydration
                </a>
                , and
                <a href="https://en.wikipedia.org/wiki/Starvation" title="Starvation">starvation</a>
                —that would quickly kill most other known forms of
                <a href="https://en.wikipedia.org/wiki/Life" title="Life">life</a>
                .
                <sup id="cite_ref-11" class="reference"><a href="#cite_note-11">[11]</a></sup>
                Tardigrades have survived exposure to
                <a href="https://en.wikipedia.org/wiki/Outer_space" title="Outer space">
                  outer space
                </a>
                .
                <sup id="cite_ref-12" class="reference"><a href="#cite_note-12">[12]</a></sup>
                <sup id="cite_ref-13" class="reference"><a href="#cite_note-13">[13]</a></sup>
                There are about 1,300&nbsp;known species
                <sup id="cite_ref-14" class="reference"><a href="#cite_note-14">[14]</a></sup>
                in the
                <a href="https://en.wikipedia.org/wiki/Phylum" title="Phylum">phylum</a>
                Tardigrada, a part of the superphylum
                <a href="https://en.wikipedia.org/wiki/Ecdysozoa" title="Ecdysozoa">Ecdysozoa</a>
                consisting of animals that grow by
                <a href="https://en.wikipedia.org/wiki/Ecdysis" title="Ecdysis">ecdysis</a>
                such as
                <a href="https://en.wikipedia.org/wiki/Arthropod" title="Arthropod">arthropods</a>
                and
                <a href="https://en.wikipedia.org/wiki/Nematode" title="Nematode">nematodes</a>
                . The earliest known true members of the group are known from Cretaceous amber in
                North America, but are essentially modern forms, and therefore likely have a
                significantly earlier origin, as they diverged from their closest relatives in the
                <a href="https://en.wikipedia.org/wiki/Cambrian" title="Cambrian">Cambrian</a>
                , over 500 million years ago.
              </p>
              <p>
                Tardigrades are usually about 0.5&nbsp;mm (0.02&nbsp;in) long when fully grown.
                <sup id="cite_ref-American_Scientist_1-2" class="reference">
                  <a href="#cite_note-American_Scientist-1">[1]</a>
                </sup>
                They are short and plump, with four pairs of legs, each ending in claws (usually
                four to eight) or suction disks.
                <sup id="cite_ref-American_Scientist_1-3" class="reference">
                  <a href="#cite_note-American_Scientist-1">[1]</a>
                </sup>
                <sup id="cite_ref-Nelson-CurrentStatus_15-0" class="reference">
                  <a href="#cite_note-Nelson-CurrentStatus-15">[15]</a>
                </sup>
                Tardigrades are prevalent in
                <a href="https://en.wikipedia.org/wiki/Moss" title="Moss">mosses</a>
                and
                <a href="https://en.wikipedia.org/wiki/Lichen" title="Lichen">lichens</a>
                and feed on plant cells, algae, and small invertebrates. When collected, they may be
                viewed under a low-power
                <a href="https://en.wikipedia.org/wiki/Microscope" title="Microscope">microscope</a>
                , making them accessible to students and amateur scientists.
                <sup id="cite_ref-16" class="reference"><a href="#cite_note-16">[16]</a></sup>
              </p>
            </div>
          </div>
        </ng-template>
      </li>
      <li ngbNavItem>
        <a href="" ngbNavLink class="tab-title">Immortal Jellyfish</a>
        <ng-template ngbNavContent ngPreserveWhitespaces>
          <div class="row">
            <div class="col-md-4">
              <img src="https://i.imgur.com/wu7IgPl.jpg" alt="Jellyfish in a black sea" />
            </div>
            <div class="col-md-8">
              <h3 class="bold h2 mb-0">Turritopsis dohrnii</h3>
              <h4 class="light h2 mb-4">The immortal</h4>
              <p>
                <i><strong>Turritopsis dohrnii</strong></i>
                , also known as the
                <strong>immortal jellyfish</strong>
                , is a
                <a href="https://en.wikipedia.org/wiki/Species" title="Species">species</a>
                of small,
                <a
                  href="https://en.wikipedia.org/wiki/Biological_immortality"
                  title="Biological immortality"
                >
                  biologically immortal
                </a>
                <a href="https://en.wikipedia.org/wiki/Jellyfish" title="Jellyfish">jellyfish</a>
                <sup id="cite_ref-2" class="reference"><a href="#cite_note-2">[2]</a></sup>
                <sup id="cite_ref-piraino-96_3-0" class="reference">
                  <a href="#cite_note-piraino-96-3">[3]</a>
                </sup>
                found worldwide in temperate to tropic waters. It is one of the few known cases of
                <a href="https://en.wikipedia.org/wiki/Animal" title="Animal">animals</a>
                capable of reverting completely to a sexually immature, colonial stage after having
                reached sexual maturity as a solitary individual. Others include the jellyfish
                <i>
                  <a
                    href="/w/index.php?title=Laodicea_undulata&amp;action=edit&amp;redlink=1"
                    class="new"
                    title="Laodicea undulata (page does not exist)"
                  >
                    Laodicea undulata
                  </a>
                  <span class="noprint" style="font-size: 85%; font-style: normal">
                    &nbsp;[
                    <a
                      href="https://sv.wikipedia.org/wiki/Laodicea_undulata"
                      class="extiw"
                      title="sv:Laodicea undulata"
                    >
                      sv
                    </a>
                    ]
                  </span>
                </i>
                <sup id="cite_ref-4" class="reference"><a href="#cite_note-4">[4]</a></sup>
                and species of the genus
                <i>
                  <a
                    href="https://en.wikipedia.org/wiki/Aurelia_(genus)"
                    class="mw-redirect"
                    title="Aurelia (genus)"
                  >
                    Aurelia
                  </a>
                </i>
                .
                <sup id="cite_ref-5" class="reference"><a href="#cite_note-5">[5]</a></sup>
              </p>
              <p>
                Like most other
                <a
                  href="https://en.wikipedia.org/wiki/Hydrozoan"
                  class="mw-redirect"
                  title="Hydrozoan"
                >
                  hydrozoans
                </a>
                ,
                <i>T. dohrnii</i>
                begin their life as tiny, free-swimming
                <a href="https://en.wikipedia.org/wiki/Larva" title="Larva">larvae</a>
                known as
                <a href="https://en.wikipedia.org/wiki/Planula" title="Planula">planulae</a>
                . As a planula settles down, it gives rise to a colony of
                <a href="https://en.wikipedia.org/wiki/Polyp_(zoology)" title="Polyp (zoology)">
                  polyps
                </a>
                that are attached to the
                <a href="https://en.wikipedia.org/wiki/Seabed" title="Seabed">sea-floor</a>
                . All the polyps and jellyfish arising from a single planula are genetically
                identical clones.
                <sup id="cite_ref-6" class="reference"><a href="#cite_note-6">[6]</a></sup>
                The polyps form into an extensively branched form, which is not commonly seen in
                most jellyfish. Jellyfish, also known as medusae, then bud off these polyps and
                continue their life in a free-swimming form, eventually becoming sexually mature.
                When sexually mature, they have been known to prey on other jellyfish species at a
                rapid pace. If a
                <i>T. dohrnii</i>
                jellyfish is exposed to environmental stress, physical assault, or is sick or old,
                it can revert to the polyp stage, forming a new polyp colony.
                <sup id="cite_ref-nytm_7-0" class="reference">
                  <a href="#cite_note-nytm-7">[7]</a>
                </sup>
                It does this through the cell development process of
                <a
                  href="https://en.wikipedia.org/wiki/Transdifferentiation"
                  title="Transdifferentiation"
                >
                  transdifferentiation
                </a>
                , which alters the
                <a
                  href="https://en.wikipedia.org/wiki/Cellular_differentiation"
                  title="Cellular differentiation"
                >
                  differentiated state of the cells
                </a>
                and transforms them into new types of cells.
              </p>
              <p>
                Theoretically, this process can go on indefinitely, effectively rendering the
                jellyfish biologically immortal,
                <sup id="cite_ref-piraino-96_3-1" class="reference">
                  <a href="#cite_note-piraino-96-3">[3]</a>
                </sup>
                <sup id="cite_ref-CheatingDeath_8-0" class="reference">
                  <a href="#cite_note-CheatingDeath-8">[8]</a>
                </sup>
                although in practice individuals can still die. In nature, most
                <i>Turritopsis</i>
                <i>dohrnii</i>
                are likely to succumb to predation or disease in the medusa stage without reverting
                to the polyp form.
                <sup id="cite_ref-National_Geographic_News_9-0" class="reference">
                  <a href="#cite_note-National_Geographic_News-9">[9]</a>
                </sup>
              </p>
              <p>
                The capability of biological immortality with no maximum lifespan makes
                <i>T. dohrnii</i>
                an important target of basic biological,
                <a href="https://en.wikipedia.org/wiki/Ageing" title="Ageing">aging</a>
                and
                <a
                  href="https://en.wikipedia.org/wiki/Pharmaceutical_drug"
                  class="mw-redirect"
                  title="Pharmaceutical drug"
                >
                  pharmaceutical
                </a>
                research.
                <sup id="cite_ref-:0_10-0" class="reference">
                  <a href="#cite_note-:0-10">[10]</a>
                </sup>
              </p>
            </div>
          </div>
        </ng-template>
      </li>
      <li ngbNavItem>
        <a href="" ngbNavLink class="tab-title">Platypus</a>
        <ng-template ngbNavContent ngPreserveWhitespaces>
          <div class="row">
            <div class="col-md-4">
              <img src="https://i.imgur.com/c9fuPym.jpg" alt="Platypus on the forest floor" />
            </div>
            <div class="col-md-8">
              <h3 class="bold h2 mb-0">Platypus</h3>
              <h4 class="light h2 mb-4">The patchwork animal</h4>
              <p>
                The
                <strong>platypus</strong>
                (
                <i>Ornithorhynchus anatinus</i>
                ), sometimes referred to as the
                <strong>duck-billed platypus</strong>
                , is a
                <a
                  href="https://en.wikipedia.org/wiki/List_of_semiaquatic_tetrapods"
                  title="List of semiaquatic tetrapods"
                >
                  semiaquatic
                </a>
                egg-laying
                <a href="https://en.wikipedia.org/wiki/Mammal" title="Mammal">mammal</a>
                <a
                  href="https://en.wikipedia.org/wiki/Endemic_(ecology)"
                  class="mw-redirect"
                  title="Endemic (ecology)"
                >
                  endemic
                </a>
                to
                <a
                  href="https://en.wikipedia.org/wiki/Eastern_states_of_Australia"
                  title="Eastern states of Australia"
                >
                  eastern Australia
                </a>
                , including
                <a href="https://en.wikipedia.org/wiki/Tasmania" title="Tasmania">Tasmania</a>
                . The platypus is the sole living representative of its
                <a href="https://en.wikipedia.org/wiki/Family_(biology)" title="Family (biology)">
                  family
                </a>
                (
                <a href="https://en.wikipedia.org/wiki/Ornithorhynchidae" title="Ornithorhynchidae">
                  Ornithorhynchidae
                </a>
                ) and
                <a href="https://en.wikipedia.org/wiki/Genus" title="Genus">genus</a>
                (
                <i>Ornithorhynchus</i>
                ), though a number of
                <a
                  href="https://en.wikipedia.org/wiki/Fossil_Monotremes"
                  class="mw-redirect"
                  title="Fossil Monotremes"
                >
                  related species
                </a>
                appear in the fossil record.
              </p>
              <p>
                Together with the four species of
                <a href="https://en.wikipedia.org/wiki/Echidna" title="Echidna">echidna</a>
                , it is one of the five
                <a href="https://en.wiktionary.org/wiki/extant" class="extiw" title="wikt:extant">
                  extant
                </a>
                species of
                <a href="https://en.wikipedia.org/wiki/Monotreme" title="Monotreme">monotremes</a>
                , the only mammals that lay
                <a
                  href="https://en.wikipedia.org/wiki/Egg_(biology)"
                  class="mw-redirect"
                  title="Egg (biology)"
                >
                  eggs
                </a>
                instead of giving birth to live young. Like other monotremes, it senses prey through
                <a href="https://en.wikipedia.org/wiki/Electroreception" title="Electroreception">
                  electrolocation
                </a>
                . It is one of the few species of
                <a
                  href="https://en.wikipedia.org/wiki/Venomous_mammals"
                  class="mw-redirect"
                  title="Venomous mammals"
                >
                  venomous mammals
                </a>
                , as the male platypus has a
                <a href="https://en.wikipedia.org/wiki/Spur_(zoology)" title="Spur (zoology)">
                  spur
                </a>
                on the hind foot that delivers a
                <a href="https://en.wikipedia.org/wiki/Platypus_venom" title="Platypus venom">
                  venom
                </a>
                capable of causing severe pain to humans. The unusual appearance of this egg-laying,
                <a href="https://en.wikipedia.org/wiki/Duck" title="Duck">duck</a>
                -billed,
                <a href="https://en.wikipedia.org/wiki/Beaver" title="Beaver">beaver</a>
                -tailed,
                <a href="https://en.wikipedia.org/wiki/Otter" title="Otter">otter</a>
                -footed mammal baffled European naturalists when they first encountered it, and the
                first scientists to examine a preserved platypus body (in 1799) judged it a fake,
                made of several animals sewn together.
              </p>
              <p>
                The unique features of the platypus make it an important subject in the study of
                <a
                  href="https://en.wikipedia.org/wiki/Evolutionary_biology"
                  title="Evolutionary biology"
                >
                  evolutionary biology
                </a>
                , and a recognisable and iconic symbol of
                <a href="https://en.wikipedia.org/wiki/Australia" title="Australia">Australia</a>
                . It is culturally significant to several
                <a
                  href="https://en.wikipedia.org/wiki/Aboriginal_Australians"
                  title="Aboriginal Australians"
                >
                  Aboriginal peoples of Australia
                </a>
                , who also used to hunt the animal for food. It has appeared as a mascot at national
                events and features on the
                <a
                  href="https://en.wikipedia.org/wiki/Obverse_and_reverse"
                  title="Obverse and reverse"
                >
                  reverse
                </a>
                of the
                <a
                  href="https://en.wikipedia.org/wiki/Australian_twenty-cent_coin"
                  title="Australian twenty-cent coin"
                >
                  Australian twenty-cent coin
                </a>
                , and the platypus is the animal emblem of the state of
                <a href="https://en.wikipedia.org/wiki/New_South_Wales" title="New South Wales">
                  New South Wales
                </a>
                . Until the early 20th century humans hunted the platypus for its fur, but it is now
                protected throughout its range. Although
                <a href="https://en.wikipedia.org/wiki/Captive_breeding" title="Captive breeding">
                  captive-breeding
                </a>
                programs have had only limited success, and the platypus is vulnerable to the
                effects of pollution, it is not under any immediate threat.
              </p>
              <p>
                As of 2020
                <sup class="plainlinks noexcerpt noprint asof-tag update" style="display: none">
                  <a
                    class="external text"
                    href="https://en.wikipedia.org/w/index.php?title=Platypus&amp;action=edit"
                  >
                    [update]
                  </a>
                </sup>
                , the platypus is a legally
                <a
                  href="https://en.wikipedia.org/wiki/Protected_species"
                  class="mw-redirect"
                  title="Protected species"
                >
                  protected species
                </a>
                in all states where it occurs, but it only listed as an
                <a
                  href="https://en.wikipedia.org/wiki/Endangered_species"
                  title="Endangered species"
                >
                  endangered species
                </a>
                in
                <a href="https://en.wikipedia.org/wiki/South_Australia" title="South Australia">
                  South Australia
                </a>
                . The species is classified as a
                <a
                  href="https://en.wikipedia.org/wiki/Near-threatened_species"
                  title="Near-threatened species"
                >
                  near-threatened species
                </a>
                by the
                <a href="https://en.wikipedia.org/wiki/IUCN" class="mw-redirect" title="IUCN">
                  IUCN
                </a>
                , but a November 2020 report has recommended that it is upgraded to
                <a
                  href="https://en.wikipedia.org/wiki/Threatened_species"
                  title="Threatened species"
                >
                  threatened species
                </a>
                under the federal
                <i>
                  <a
                    href="https://en.wikipedia.org/wiki/EPBC_Act"
                    class="mw-redirect"
                    title="EPBC Act"
                  >
                    EPBC Act
                  </a>
                </i>
                , due to habitat destruction and declining numbers in all states.
              </p>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
  </div>
</div>

<!-- the additional div and conditional color classes are for demo purposes only -->
<div [class]="contentBg ? 'bg-' + contentBg : ''">
  <div [ngbNavOutlet]="nav" class="container py-5"></div>
</div>
