<div class="form-floating">
  <input
    #dpSimple="ngbDatepicker"
    [formControl]="simpleDatepicker"
    [navigation]="navigation"
    class="form-control"
    id="dpSimple"
    maxlength="10"
    ngbDatepicker
    placeholder=" "
    type="text"
  />
  <label for="dpSimple" class="form-label">Simple datepicker</label>
  <button class="ngb-dp-open" (click)="dpSimple.toggle()" aria-label="Open calendar" type="button">
    <i class="pi pi-3203" aria-hidden="true"></i>
  </button>
</div>
