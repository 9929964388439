<div class="layout-container header-big">
  <ng-template #versionDropdown>
    <div class="version-switch" ngbDropdown>
      <label class="visually-hidden" for="versionDropdownButton" id="versionDropdown">
        Version Selection for older documentation versions
      </label>
      <button class="btn btn-link dropdown-button" id="versionDropdownButton" ngbDropdownToggle>
        {{ (currentVersion$ | async)?.title }}
      </button>
      <div ngbDropdownMenu aria-labelledby="versionDropdown" class="dropdown-menu-end">
        <a *ngFor="let version of versions$ | async" [href]="version.url" ngbDropdownItem>
          <span class="bold">{{ version.title }}</span>
          <div class="small mt-2 icons" *ngIf="version.dependencies">
            <span
              title="Angular"
              *ngIf="version.dependencies.get('@angular/core') as angularVersion"
            >
              <img src="assets/images/angular.png" alt="Angular Logo" />
              {{ angularVersion.format('x.x') }}
            </span>
            <span
              title="Bootstrap"
              *ngIf="version.dependencies.get('bootstrap') as bootstrapVersion"
            >
              <img src="assets/images/bootstrap.png" alt="Bootstrap Logo" />
              {{ bootstrapVersion.format('x.x') }}
            </span>
            <span
              title="ng-bootstrap"
              *ngIf="version.dependencies.get('@ng-bootstrap/ng-bootstrap') as ngBootstrapVersion"
            >
              <img src="assets/images/ng-bootstrap.svg" alt="ng-bootstrap Logo" />
              {{ ngBootstrapVersion.format('x.x') }}
            </span>
          </div>
        </a>
      </div>
    </div>
  </ng-template>

  <sp-intranet-header
    [optionHeaderContent]="(versions$ | async) ? versionDropdown : null"
    siteTitle="Swiss Post Design System"
    languages="en"
    logoUrl="/"
  >
    <li class="nav-item">
      <a
        class="nav-link"
        [ngClass]="{ active: router.url.indexOf('/home') > -1 }"
        [routerLink]="'/home'"
      >
        Home
      </a>
    </li>
    <li class="nav-item" ngbDropdown>
      <button
        [ngClass]="{ active: router.url.indexOf('/bootstrap-samples') > -1 }"
        class="nav-link"
        id="id01"
        ngbDropdownToggle
        type="button"
      >
        Bootstrap
      </button>
      <div aria-labelledby="id01" ngbDropdownMenu>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/accordion') > -1 }"
          [routerLink]="'/bootstrap-samples/accordion'"
        >
          Accordion
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/alerts') > -1 }"
          [routerLink]="'/bootstrap-samples/alerts'"
        >
          Alerts / Notifications
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/background') > -1 }"
          [routerLink]="'/bootstrap-samples/background'"
        >
          Background
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/badge') > -1 }"
          [routerLink]="'/bootstrap-samples/badge'"
        >
          Badge
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/blockquotes') > -1 }"
          [routerLink]="'/bootstrap-samples/blockquotes'"
        >
          Blockquotes
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/buttons') > -1 }"
          [routerLink]="'/bootstrap-samples/buttons'"
        >
          Buttons
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/button-group') > -1 }"
          [routerLink]="'/bootstrap-samples/button-group'"
        >
          Button Group
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/card') > -1 }"
          [routerLink]="'/bootstrap-samples/card'"
        >
          Cards
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/forms') > -1 }"
          [routerLink]="'/bootstrap-samples/forms'"
        >
          Forms
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/form-check') > -1 }"
          [routerLink]="'/bootstrap-samples/form-check'"
        >
          Form checkbox
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/form-control') > -1 }"
          [routerLink]="'/bootstrap-samples/form-control'"
        >
          Form control
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/form-file') > -1 }"
          [routerLink]="'/bootstrap-samples/form-file'"
        >
          Form file
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/form-radio') > -1 }"
          [routerLink]="'/bootstrap-samples/form-radio'"
        >
          Form radio button
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/form-range') > -1 }"
          [routerLink]="'/bootstrap-samples/form-range'"
        >
          Form range
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/form-select') > -1 }"
          [routerLink]="'/bootstrap-samples/form-select'"
        >
          Form select
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/form-multiple-select') > -1 }"
          [routerLink]="'/bootstrap-samples/form-multiple-select'"
        >
          Form select multiple
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/form-switch') > -1 }"
          [routerLink]="'/bootstrap-samples/form-switch'"
        >
          Form switch
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/form-textarea') > -1 }"
          [routerLink]="'/bootstrap-samples/form-textarea'"
        >
          Form textarea
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/tables') > -1 }"
          [routerLink]="'/bootstrap-samples/tables'"
        >
          Tables
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/bootstrap-samples/typography') > -1 }"
          [routerLink]="'/bootstrap-samples/typography'"
        >
          Typography
        </a>
      </div>
    </li>
    <li class="nav-item" ngbDropdown>
      <button
        [ngClass]="{ active: router.url.indexOf('/ng-bootstrap-samples') > -1 }"
        class="nav-link"
        id="id02"
        ngbDropdownToggle
        type="button"
      >
        NgBootstrap
      </button>
      <div aria-labelledby="id02" ngbDropdownMenu>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/ng-bootstrap-samples/accordion') > -1 }"
          [routerLink]="'/ng-bootstrap-samples/accordion'"
        >
          Accordion
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/ng-bootstrap-samples/alert') > -1 }"
          [routerLink]="'/ng-bootstrap-samples/alert'"
        >
          Alerts
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/ng-bootstrap-samples/carousel') > -1 }"
          [routerLink]="'/ng-bootstrap-samples/carousel'"
        >
          Carousel
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/ng-bootstrap-samples/collapse') > -1 }"
          [routerLink]="'/ng-bootstrap-samples/collapse'"
        >
          Collapse
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/ng-bootstrap-samples/datepicker') > -1 }"
          [routerLink]="'/ng-bootstrap-samples/datepicker'"
        >
          Datepicker
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/ng-bootstrap-samples/dropdown') > -1 }"
          [routerLink]="'/ng-bootstrap-samples/dropdown'"
        >
          Dropdown
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/ng-bootstrap-samples/modal') > -1 }"
          [routerLink]="'/ng-bootstrap-samples/modal'"
        >
          Modal
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/ng-bootstrap-samples/pagination') > -1 }"
          [routerLink]="'/ng-bootstrap-samples/pagination'"
        >
          Pagination
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/ng-bootstrap-samples/popover') > -1 }"
          [routerLink]="'/ng-bootstrap-samples/popover'"
        >
          Popover
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/ng-bootstrap-samples/progressbar') > -1 }"
          [routerLink]="'/ng-bootstrap-samples/progressbar'"
        >
          Progressbar
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/ng-bootstrap-samples/rating') > -1 }"
          [routerLink]="'/ng-bootstrap-samples/rating'"
        >
          Rating
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/ng-bootstrap-samples/tabs') > -1 }"
          [routerLink]="'/ng-bootstrap-samples/tabs'"
        >
          Tabs
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/ng-bootstrap-samples/timepicker') > -1 }"
          [routerLink]="'/ng-bootstrap-samples/timepicker'"
        >
          Timepicker
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/ng-bootstrap-samples/tooltip') > -1 }"
          [routerLink]="'/ng-bootstrap-samples/tooltip'"
        >
          Tooltip
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/ng-bootstrap-samples/typeahead') > -1 }"
          [routerLink]="'/ng-bootstrap-samples/typeahead'"
        >
          Typeahead
        </a>
      </div>
    </li>
    <li class="nav-item" ngbDropdown>
      <button
        [ngClass]="{ active: router.url.indexOf('/post-samples') > -1 }"
        class="nav-link"
        id="id04"
        ngbDropdownToggle
        type="button"
      >
        Post
      </button>
      <div aria-labelledby="id01" ngbDropdownMenu>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/post-samples/custom-select') > -1 }"
          [routerLink]="'/post-samples/custom-select'"
        >
          Custom select
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/post-samples/datatable') > -1 }"
          [routerLink]="'/post-samples/datatable'"
        >
          Datatable
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/post-samples/feedback') > -1 }"
          [routerLink]="'/post-samples/feedback'"
        >
          Feedback
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/post-samples/forms') > -1 }"
          [routerLink]="'/post-samples/forms'"
        >
          Forms
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/post-samples/icons') > -1 }"
          [routerLink]="'/post-samples/icons'"
        >
          Icons
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/post-samples/intranet-layout') > -1 }"
          href="https://design-system.post.ch/?path=/docs/9b02bcb2-3b6a-4271-b550-675a62ff3890--docs"
        >
          Internet header
          <i style="height: 1em" aria-hidden="true" class="pi pi-2038"></i>
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/post-samples/intranet-layout') > -1 }"
          [routerLink]="'/post-samples/intranet-layout'"
        >
          Intranet header
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/post-samples/notification-overlay') > -1 }"
          [routerLink]="'/post-samples/notification-overlay'"
        >
          Notification overlay
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/post-samples/card') > -1 }"
          [routerLink]="'/post-samples/card'"
        >
          Post cards
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/post-samples/sizing') > -1 }"
          [routerLink]="'/post-samples/sizing'"
        >
          Sizing
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/post-samples/spinner') > -1 }"
          [routerLink]="'/post-samples/spinner'"
        >
          Spinner
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/post-samples/stepper') > -1 }"
          [routerLink]="'/post-samples/stepper'"
        >
          Stepper
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/post-samples/subnavigation') > -1 }"
          [routerLink]="'/post-samples/subnavigation'"
        >
          Subnavigation
        </a>
        <a
          ngbDropdownItem
          [ngClass]="{ active: router.url.indexOf('/post-samples/topic-teaser') > -1 }"
          [routerLink]="'/post-samples/topic-teaser'"
        >
          Topic teaser
        </a>
      </div>
    </li>
  </sp-intranet-header>
  <div
    data-spy="scroll"
    data-target="#component-list"
    data-offset="0"
    mainContent
    [ngClass]="{ container: !setFullwidthContainer }"
    id="mainContent"
  ></div>
  <div
    [ngClass]="
      setFullwidthContainer ? 'site-content header-margin' : 'site-content container header-margin'
    "
  >
    <main role="main">
      <router-outlet></router-outlet>
    </main>
  </div>

  <app-footer></app-footer>
</div>
<script type="text/javascript" src="runtime.js"></script>
<script type="text/javascript" src="polyfills.js"></script>
<script type="text/javascript" src="scripts.js"></script>
<script type="text/javascript" src="main.js"></script>
