<div class="row border-gutters">
  <div class="col-xl-4 col-md-6">
    <a href="#" class="card h-100 product-card">
      <div class="card-body product-navigation">
        <div>
          <p class="bold h3 mb-regular">Preiswert</p>
          <p class="lead">Lorem ipsum dolor sit.</p>
        </div>
        <span class="link-icon">
          <i class="pi pi-3020" aria-hidden="true"></i>
          <span>Mehr erfahren</span>
        </span>
      </div>
    </a>
  </div>

  <div class="col-xl-4 col-md-6">
    <a href="#" class="card h-100 product-card">
      <div class="card-body product-navigation">
        <div>
          <p class="bold h3 mb-regular">Preiswert</p>
          <p class="lead">Lorem ipsum dolor sit. Lorem, ipsum dolor.</p>
        </div>
        <span class="link-icon">
          <i class="pi pi-3020" aria-hidden="true"></i>
          <span>Mehr erfahren</span>
        </span>
      </div>
    </a>
  </div>

  <div class="col-xl-4 col-md-6">
    <a href="#" class="card h-100 product-card">
      <div class="card-body product-navigation">
        <div>
          <p class="bold h3 mb-regular">Preiswert</p>
          <p class="lead">Lorem ipsum dolor sit. Lorem, ipsum dolor.</p>
        </div>
        <span class="link-icon">
          <i class="pi pi-3020" aria-hidden="true"></i>
          <span>Mehr erfahren</span>
        </span>
      </div>
    </a>
  </div>

  <div class="col-xl-4 col-md-6">
    <a href="#" class="card h-100 product-card">
      <div class="card-body product-navigation">
        <div>
          <p class="bold h3 mb-regular">Preiswert</p>
        </div>
        <span class="link-icon">
          <i class="pi pi-3020" aria-hidden="true"></i>
          <span>Mehr erfahren</span>
        </span>
      </div>
    </a>
  </div>

  <div class="col-xl-4 col-md-6">
    <a href="#" class="card h-100 product-card">
      <div class="card-body product-navigation">
        <div>
          <p class="bold h3 mb-regular">Preiswert</p>
          <p class="lead">Lorem ipsum dolor sit. Lorem, ipsum dolor.</p>
        </div>
        <span class="link-icon">
          <i class="pi pi-3020" aria-hidden="true"></i>
          <span>Mehr erfahren</span>
        </span>
      </div>
    </a>
  </div>

  <div class="col-xl-4 col-md-6">
    <a href="#" class="card h-100 product-card">
      <div class="card-body product-navigation">
        <div>
          <p class="bold h3 mb-regular">Preiswert</p>
          <p class="lead">Lorem ipsum dolor sit. Lorem, ipsum dolor.</p>
          <p class="lead">Lorem ipsum dolor sit. Lorem, ipsum dolor.</p>
        </div>
        <span class="link-icon">
          <i class="pi pi-3020" aria-hidden="true"></i>
          <span>Mehr erfahren</span>
        </span>
      </div>
    </a>
  </div>

  <div class="col-xl-4 col-md-6">
    <a href="#" class="card h-100 product-card">
      <div class="card-body product-navigation">
        <div>
          <p class="bold h3 mb-regular">Preiswert</p>
          <p class="lead">Lorem ipsum dolor sit. Lorem, ipsum dolor.</p>
        </div>
        <span class="link-icon">
          <i class="pi pi-3020" aria-hidden="true"></i>
          <span>Mehr erfahren</span>
        </span>
      </div>
    </a>
  </div>

  <div class="col-xl-4 col-md-6">
    <a href="#" class="card h-100 product-card">
      <div class="card-body product-navigation">
        <div>
          <p class="bold h3 mb-regular">Preiswert</p>
          <p class="lead">Lorem ipsum dolor sit. Lorem, ipsum dolor.</p>
        </div>
        <span class="link-icon">
          <i class="pi pi-3020" aria-hidden="true"></i>
          <span>Mehr erfahren</span>
        </span>
      </div>
    </a>
  </div>
</div>
