<q>Just quotes</q>

<q lang="de" class="d-block mt-2">Just quotes with a different language</q>

<q class="d-block mt-2">
  Long quotes, Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
  tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
</q>

<q class="d-block mt-2">
  Long quotes with nested inside, Lorem ipsum dolor sit amet,
  <q>consetetur sadipscing elitr</q>
  , sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, voluptua.
</q>
