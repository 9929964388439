<div class="alert-container">
  <div class="alert alert-primary" role="alert">
    <h4 class="alert-heading">alert-primary</h4>
    <p>A simple primary alert—check it out!</p>
  </div>
  <div class="alert alert-success" role="alert">
    <h4 class="alert-heading">alert-success</h4>
    <p>A simple success alert—check it out!</p>
  </div>
  <div class="alert alert-danger" role="alert">
    <h4 class="alert-heading">alert-danger</h4>
    <p>
      A simple danger alert with
      <a href="#" class="alert-link">an example link</a>
      . Give it a click if you like.
    </p>
  </div>
  <div class="alert alert-warning" role="alert">
    <h4 class="alert-heading">alert-warning</h4>
    <p>A simple warning alert—check it out!</p>
  </div>
  <div class="alert alert-info" role="alert">
    <h4 class="alert-heading">alert-info</h4>
    <p>A simple info alert—check it out!</p>
  </div>
  <div class="alert alert-info pi-1001" role="alert">
    <h4 class="alert-heading">Example with custom icon (pi-1001)</h4>
    <p>Only needs the corresponding "pi"-class added to the alert.</p>
  </div>
  <div class="alert alert-primary pi-2063-white" role="alert">
    <h4 class="alert-heading">Example with white icon (pi-2063-white)</h4>
    <p>Icon needs to be extended in sass.</p>
  </div>
  <div class="alert alert-primary no-icon" role="alert">
    <h4 class="alert-heading">Example without an icon</h4>
    <p>Add class "no-icon" to the alert.</p>
  </div>
  <div class="alert alert-success" role="alert">
    <h4 class="alert-heading">Example with more content</h4>
    <p>
      Aww yeah, you successfully read this important alert message. This example text is going to
      run a bit longer so that you can see how spacing within an alert works with this kind of
      content.
    </p>
    <hr />
    <p>Whenever you need to, be sure to use margin utilities to keep things nice and tidy.</p>
  </div>
  <div class="alert alert-warning alert-dismissible" role="alert">
    <button type="button" class="btn-close" data-dismiss="alert" aria-label="Close"></button>
    <h4 class="alert-heading">Example for dismissible alert</h4>
    <p>Holy guacamole! You should check in on some of those fields below.</p>
  </div>
  <div class="alert alert-primary" role="alert">
    <p>Example with only one line of text.</p>
  </div>
  <div class="alert alert-gray alert-action alert-dismissible" role="alert">
    <button type="button" class="btn-close" data-dismiss="alert" aria-label="Close"></button>
    <div class="alert-content">
      <p>
        We use cookies to improve your user experience. Depending on
        <a href="#">your settings</a>
        , we also use cookies for analysis purposes and to provide you with personalized content and
        relevant advertising. You can find more information in our
        <a href="#">data protection declaration</a>
        .
      </p>
    </div>
    <div class="alert-buttons">
      <button class="btn btn-primary btn-animated">
        <span>Allow cookies</span>
      </button>
    </div>
  </div>
</div>

<h3>Fixed alert</h3>
<button class="btn btn-secondary" (click)="toggleFixedAlert()" #dismissibleBtn>
  Toggle fixed alert
</button>

<div class="alert alert-info alert-fixed-bottom alert-dismissible" role="alert" #dismissible>
  <button
    type="button"
    class="btn-close"
    data-dismiss="alert"
    aria-label="Close"
    (click)="toggleFixedAlert()"
  ></button>
  <h4 class="alert-heading">This is an alert fixed to the bottom of the page.</h4>
  <p>
    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
    ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
    dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
    sit amet.
  </p>
</div>
