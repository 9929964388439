<div ngbAccordion [closeOthers]="true">
  <div ngbAccordionItem [collapsed]="false">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton>
        Default title wrapping - Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
        posuere cubilia curae nullam sagittis vel augue eget pellentesque lacus sapien.
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <p>
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
            squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck
            quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it
            squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
            craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur
            butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth
            nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
          </p>

          <div class="d-flex flex-row-reverse gap-mini mt-mini">
            <button class="btn btn-primary" type="button">Primary</button>
            <button class="btn btn-secondary" type="button">Secondary</button>
            <button class="btn btn-tertiary" type="button">Tertiary</button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div ngbAccordionItem>
    <h2 ngbAccordionHeader>
      <button
        ngbAccordionButton
        class="text-truncate"
        title="Title truncated with .text-truncate (requires a title attribute) - Pellentesque interdum diam eget feugiat aliquet nullam tincidunt nunc eu lorem vehicula, a porta nisl eleifend integer non ullamcorper massa."
      >
        Title truncated with
        <code>.text-truncate</code>
        (requires a
        <code>title</code>
        attribute) - Pellentesque interdum diam eget feugiat aliquet nullam tincidunt nunc eu lorem
        vehicula, a porta nisl eleifend integer non ullamcorper massa.
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <p>
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
            squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck
            quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it
            squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
            craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur
            butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth
            nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
          </p>
        </ng-template>
      </div>
    </div>
  </div>
</div>
