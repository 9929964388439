<h3 class="mt-huge-r">
  Using the
  <code>multiple</code>
  attribute
</h3>
<select class="form-select" multiple aria-label="multiple select example">
  <option selected>Open this select menu</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>

<h3 class="mt-huge-r">
  Using the
  <code>size</code>
  attribute
</h3>
<div class="alert alert-info my-4">
  <p>
    In Safari on macOS, the size attribute only works as expected from 4+. This is a
    <a
      href="https://stackoverflow.com/questions/15760089/select-size-attribute-size-not-working-in-chrome-safari"
    >
      known issue
    </a>
    .
  </p>
</div>
<select class="form-select" size="8" multiple aria-label="size 8 select example">
  <option selected>Open this select menu</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
  <option value="4">Four</option>
  <option value="5">Five</option>
  <option value="6">Six</option>
  <option value="7">Seven</option>
  <option value="8">Eight</option>
  <option value="9">Nine</option>
  <option value="10">Ten</option>
  <option value="11">Eleven</option>
</select>

<div class="form-floating mt-4">
  <select id="FormSelectMultipleInvalid" class="form-select is-invalid" multiple size="4" required>
    <option value="1">Value 1</option>
    <option value="2">Value 2</option>
    <option value="3">Value 3</option>
    <option value="4">Value 4</option>
    <option value="5">Value 5</option>
    <option value="6">Value 6</option>
  </select>
  <label class="form-label" for="FormSelectMultipleInvalid">Invalid Select Multiple</label>
  <p class="invalid-feedback">Error message</p>
</div>

<div class="form-floating mt-4">
  <select id="FormSelectMultipleValid" class="form-select is-valid" multiple size="4">
    <option value="1">Value 1</option>
    <option value="2">Value 2</option>
    <option value="3">Value 3</option>
    <option value="4">Value 4</option>
    <option value="5">Value 5</option>
    <option value="6">Value 6</option>
  </select>
  <label class="form-label" for="FormSelectMultipleValid">Valid Select Multiple</label>
  <p class="valid-feedback">Success message (optional)</p>
</div>
