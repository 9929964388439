<div class="heading py-5">
  <h1 class="bold font-curve-large">Modal</h1>
  <app-dependency-link></app-dependency-link>
</div>

<section>
  <p class="font-curve-regular light mb-5">
    To manually remove the focus styles on close button in modal, you will need to add
    <code>ngbAutofocus tabindex="-1"</code>
    to the title with class modal-title. For advanced focus management (focus the [X] button, focus
    the [OK] button), please refer to
    <a href="https://ng-bootstrap.github.io/#/components/modal/examples#focus">
      https://ng-bootstrap.github.io/#/components/modal/examples#focus
    </a>
    .
  </p>
  <app-ngb-modal-demo></app-ngb-modal-demo>
</section>

<h3 class="bold h5">trigger.component.html</h3>
<code class="block" [highlight]="buttonsTemplate" [languages]="['html']"></code>

<h3 class="bold h5">trigger.component.ts</h3>
<code class="block" [highlight]="tsTemplate" [languages]="['typescript']"></code>

<h3 class="bold h5">modal.component.html</h3>
<code class="block" [highlight]="modalTemplate" [languages]="['html']"></code>

<h3 class="bold h5">modal.component.ts</h3>
<code class="block mb-5" [highlight]="modalTsTemplate" [languages]="['typescript']"></code>
