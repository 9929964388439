<!-- Subnavigation -->
<div class="subnavigation">
  <div class="container container-fluid-xs container-fluid-sm">
    <ul class="subnavigation-list">
      <li class="subnavigation-item">
        <a href="#" class="subnavigation-link active">Navitem active</a>
      </li>
      <li class="subnavigation-item">
        <a href="#" class="subnavigation-link">Navitem default</a>
      </li>
      <li class="subnavigation-item">
        <a href="#" class="subnavigation-link">Navitem default</a>
      </li>
    </ul>
  </div>
</div>

<span class="spacer"></span>

<!-- Alternate -->
<div class="subnavigation subnavigation-alternate">
  <div class="container container-fluid-xs container-fluid-sm">
    <ul class="subnavigation-list">
      <li class="subnavigation-item">
        <a href="#" class="subnavigation-link active">Navitem active</a>
      </li>
      <li class="subnavigation-item">
        <a href="#" class="subnavigation-link">Navitem default</a>
      </li>
      <li class="subnavigation-item">
        <a href="#" class="subnavigation-link">Navitem default</a>
      </li>
    </ul>
  </div>
</div>

<span class="spacer"></span>

<!-- Colored background -->
<div class="subnavigation bg-petrol">
  <div class="container container-fluid-xs container-fluid-sm">
    <ul class="subnavigation-list">
      <li class="subnavigation-item">
        <a href="#" class="subnavigation-link active">Navitem active</a>
      </li>
      <li class="subnavigation-item">
        <a href="#" class="subnavigation-link">Navitem default</a>
      </li>
      <li class="subnavigation-item">
        <a href="#" class="subnavigation-link">Navitem default</a>
      </li>
    </ul>
  </div>
</div>

<span class="spacer"></span>

<!-- Subnavigation with Badges -->
<div class="subnavigation">
  <div class="container container-fluid-xs container-fluid-sm">
    <ul class="subnavigation-list">
      <li class="subnavigation-item">
        <a href="#" class="subnavigation-link active">
          Navitem active
          <span class="badge bg-active rounded-pill">19</span>
        </a>
      </li>
      <li class="subnavigation-item">
        <a href="#" class="subnavigation-link">
          Navitem default
          <span class="badge bg-active rounded-pill">10</span>
        </a>
      </li>
      <li class="subnavigation-item">
        <a href="#" class="subnavigation-link">
          Navitem default
          <span class="badge bg-active rounded-pill">3</span>
        </a>
      </li>
    </ul>
  </div>
</div>
