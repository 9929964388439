<div class="heading py-5">
  <h1 class="bold font-curve-large">Background</h1>
  <h2 class="font-curve-medium">Custom Post Backgrounds</h2>
</div>

<h2 class="bold">Infos</h2>
<p class="light font-curve-regular">
  All these background-color classes automatically use the appropriate font color meeting the
  accessibility guidelines. When setting opacity, the contrast ratio cannot be guaranteed and should
  be checked individually.
</p>

<h2 class="bold mt-huge">Default backgrounds</h2>
<div class="d-flex my-4">
  <p class="form-check form-switch">
    <input
      id="bg-opacity-1"
      class="form-check-input"
      type="checkbox"
      role="switch"
      [(ngModel)]="dropOpacity1"
    />
    <label class="form-check-label order-first" for="bg-opacity-1">
      Drop background opacity to 80%
    </label>
  </p>
</div>
<div class="row">
  <div class="col-12 col-lg-6">
    <div class="p-3 bg-gray">
      <app-background-demo
        [dropOpacity]="dropOpacity1"
        [colorArray]="[
          'yellow',
          'white',
          'light',
          'gray',
          'dark',
          'primary',
          'secondary',
          'transparent'
        ]"
      ></app-background-demo>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="p-3 bg-dark">
      <app-background-demo
        [dropOpacity]="dropOpacity1"
        [colorArray]="[
          'yellow',
          'white',
          'light',
          'gray',
          'dark',
          'primary',
          'secondary',
          'transparent'
        ]"
      ></app-background-demo>
    </div>
  </div>
</div>
<code
  class="block mt-huge"
  [highlight]="codeTemplate"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>

<h2 class="bold mt-huge">Thematic backgrounds</h2>
<div class="d-flex my-4">
  <p class="form-check form-switch">
    <input
      id="bg-opacity-2"
      class="form-check-input"
      type="checkbox"
      role="switch"
      [(ngModel)]="dropOpacity2"
    />
    <label class="form-check-label order-first" for="bg-opacity-2">
      Drop background opacity to 80%
    </label>
  </p>
</div>
<div class="row">
  <div class="col-12 col-lg-6">
    <div class="p-3 bg-gray">
      <app-background-demo
        [dropOpacity]="dropOpacity2"
        [colorArray]="[
          'nightblue',
          'nightblue-bright',
          'petrol',
          'petrol-bright',
          'coral',
          'coral-bright',
          'olive',
          'olive-bright',
          'purple',
          'purple-bright',
          'aubergine',
          'aubergine-bright'
        ]"
      ></app-background-demo>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="p-3 bg-dark">
      <app-background-demo
        [dropOpacity]="dropOpacity2"
        [colorArray]="[
          'nightblue',
          'nightblue-bright',
          'petrol',
          'petrol-bright',
          'coral',
          'coral-bright',
          'olive',
          'olive-bright',
          'purple',
          'purple-bright',
          'aubergine',
          'aubergine-bright'
        ]"
      ></app-background-demo>
    </div>
  </div>
</div>

<h2 class="bold mt-huge">Contextual backgrounds (Intranet only)</h2>
<div class="d-flex my-4">
  <p class="form-check form-switch">
    <input
      id="bg-opacity-3"
      class="form-check-input"
      type="checkbox"
      role="switch"
      [(ngModel)]="dropOpacity3"
    />
    <label class="form-check-label order-first" for="bg-opacity-3">
      Drop background opacity to 80%
    </label>
  </p>
</div>
<div class="row">
  <div class="col-12 col-lg-6">
    <div class="p-3 bg-gray">
      <app-background-demo
        [dropOpacity]="dropOpacity3"
        [colorArray]="['success', 'warning', 'error', 'info']"
      ></app-background-demo>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="p-3 bg-dark">
      <app-background-demo
        [dropOpacity]="dropOpacity3"
        [colorArray]="['success', 'warning', 'error', 'info']"
      ></app-background-demo>
    </div>
  </div>
</div>
