<div class="modal-header">
  <h4 class="modal-title" ngbAutofocus tabindex="-1">Hi there!</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <p *ngIf="!showLongContent; else longContent">Hello, World!</p>
  <ng-template #longContent>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tempor nisi quis velit efficitur
      scelerisque. In convallis dui non molestie vestibulum. Maecenas tempor lorem sit amet augue
      tincidunt, non ornare diam semper. Curabitur tempus feugiat orci vitae suscipit. Nam
      sollicitudin varius leo, sed ultrices nisl suscipit eget. Quisque libero ipsum, blandit in
      vestibulum sed, ultricies maximus purus. Cras tempus lorem leo, venenatis varius dui pharetra
      sed. Proin et dignissim nunc. Cras et tristique ante. Integer faucibus magna felis, eget
      viverra turpis aliquet ut. Etiam dictum eleifend magna. Praesent hendrerit commodo massa, quis
      suscipit libero convallis a.
    </p>
    <p>
      Fusce maximus tempor semper. Donec gravida volutpat odio, in iaculis leo vehicula nec.
      Praesent vel dui et ligula cursus efficitur. Duis vitae diam nibh. Donec convallis, nisi at
      lobortis placerat, risus diam egestas lorem, et malesuada libero urna mattis orci. Integer
      bibendum odio laoreet convallis pretium. Nunc tortor lorem, eleifend at ante quis, molestie
      pellentesque augue. Aenean fringilla risus est, eu posuere velit condimentum sed. Ut in felis
      id sapien dictum scelerisque. Curabitur scelerisque velit pulvinar magna bibendum dictum. Nunc
      fringilla leo semper dolor consequat rhoncus. Vivamus sit amet elit et enim finibus semper.
      Sed et lobortis mi, sed gravida nisl. Donec et risus laoreet, condimentum orci eget, vehicula
      arcu. Pellentesque in semper purus, et porta urna.
    </p>
    <p>
      Fusce ultrices viverra eros a convallis. Cras et tempor mauris. Fusce tincidunt libero sit
      amet odio euismod, non placerat massa semper. Vivamus aliquet dolor eget justo posuere luctus.
      Aliquam id risus ante. Nulla urna felis, accumsan sed purus nec, dignissim scelerisque nisl.
      Aliquam at magna quis dolor mollis vestibulum non sit amet augue. Sed velit justo,
      pellentesque sit amet blandit sed, gravida at ante. Nulla sed mi erat. Proin at orci eu justo
      dapibus dapibus. Morbi ac mattis tellus. Phasellus fringilla elementum magna at finibus.
      Curabitur tempor consequat varius. Ut sit amet orci arcu. Curabitur dignissim libero ut
      bibendum venenatis.
    </p>
    <p>
      Morbi consequat rhoncus risus, in placerat diam mattis vitae. Ut vitae elementum nisl. Nullam
      sed ullamcorper nulla. Morbi ut justo odio. Suspendisse congue lectus et nisl iaculis,
      sagittis consequat leo tempus. Morbi a nisl dignissim, sollicitudin nisi vitae, convallis
      diam. Pellentesque nec cursus risus. Sed vestibulum augue vel nunc eleifend pulvinar. Aenean
      ut lobortis enim. Praesent lorem diam, iaculis vitae tellus ac, luctus hendrerit ex. Quisque
      vestibulum nibh ac euismod lacinia.
    </p>
    <p>
      Donec suscipit congue metus sed tristique. Nunc sagittis auctor ex vitae tristique. Integer
      lectus magna, pretium non purus eget, fringilla vulputate dolor. Lorem ipsum dolor sit amet,
      consectetur adipiscing elit. Phasellus mollis nunc nec velit dignissim, eu faucibus metus
      lobortis. Fusce lacus nibh, varius ultrices dignissim nec, gravida vitae enim. In blandit
      pretium ligula. Nulla imperdiet malesuada dui in fermentum. Mauris nisi augue, luctus nec
      ullamcorper ut, consectetur nec dolor. Aliquam at diam dolor.
    </p>
  </ng-template>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-rg btn-secondary" (click)="activeModal.close('Close click')">
    <span>Close</span>
  </button>
</div>
