<!--
  ~ Copyright 2021 by Swiss Post, Information Technology
  -->

<div class="d-flex bg-primary p-regular" style="height: 150px">
  <div class="flex-fill">
    <div class="bg-light" [class]="demoClass"></div>
  </div>

  <!-- Legend -->
  <ul class="ps-regular text-white">
    <li class="d-flex align-items-center mb-regular">
      <div class="bg-light h-regular w-regular me-mini"></div>
      <span>content</span>
    </li>
  </ul>
</div>

<form [formGroup]="demoForm" class="row mt-regular">
  <!-- Width control -->
  <div class="col" formGroupName="width">
    <label class="form-label" for="width">Width</label>
    <select class="form-select form-select-rg" formControlName="size" id="width">
      <optgroup label="Bootstrap sizes">
        <option *ngFor="let size of bootstrapSizes" [value]="size.value">{{ size.name }}</option>
      </optgroup>
      <optgroup label="Post sizes">
        <option *ngFor="let size of sizes" [value]="size.name">
          {{ size.name | replaceHyphen | titlecase | removeSpaces }}
        </option>
      </optgroup>
    </select>
  </div>

  <!-- Height control -->
  <div class="col" formGroupName="height">
    <label class="form-label" for="height">Height</label>
    <select class="form-select form-select-rg" formControlName="size" id="height">
      <optgroup label="Bootstrap sizes">
        <option *ngFor="let size of bootstrapSizes" [value]="size.value">{{ size.name }}</option>
      </optgroup>
      <optgroup label="Post sizes">
        <option *ngFor="let size of sizes" [value]="size.name">
          {{ size.name | replaceHyphen | titlecase | removeSpaces }}
        </option>
      </optgroup>
    </select>
  </div>

  <!-- Max-width control -->
  <div class="col" formGroupName="max-width">
    <label class="form-label" for="max-width">Max-width</label>
    <select class="form-select form-select-rg" formControlName="size" id="max-width">
      <optgroup label="Bootstrap sizes">
        <option value="100">100%</option>
      </optgroup>
      <optgroup label="Post sizes">
        <option *ngFor="let size of sizes" [value]="size.name">
          {{ size.name | replaceHyphen | titlecase | removeSpaces }}
        </option>
      </optgroup>
    </select>
  </div>

  <!-- Max-height control -->
  <div class="col" formGroupName="max-height">
    <label class="form-label" for="max-height">Max-height</label>
    <select class="form-select form-select-rg" formControlName="size" id="max-height">
      <optgroup label="Bootstrap sizes">
        <option value="100">100%</option>
      </optgroup>
      <optgroup label="Post sizes">
        <option *ngFor="let size of sizes" [value]="size.name">
          {{ size.name | replaceHyphen | titlecase | removeSpaces }}
        </option>
      </optgroup>
    </select>
  </div>
</form>

<!-- Code sample -->
<p class="h5 bold mt-4">sizing-demo.component.html</p>
<code [highlight]="highlight" [languages]="['html']" class="block"></code>
