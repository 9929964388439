<fieldset aria-label="Radio Buttons with Template-driven Froms" class="btn-group">
  <input
    [(ngModel)]="radio"
    [value]="Choice.One"
    autocomplete="off"
    checked
    class="btn-check"
    id="btnradio1"
    name="btnradio"
    type="radio"
  />
  <label class="btn btn-secondary" for="btnradio1">One</label>

  <input
    [(ngModel)]="radio"
    [value]="Choice.Two"
    autocomplete="off"
    class="btn-check"
    id="btnradio2"
    name="btnradio"
    type="radio"
  />
  <label class="btn btn-secondary" for="btnradio2">Two (pre-selected)</label>

  <input
    [(ngModel)]="radio"
    [value]="Choice.Three"
    autocomplete="off"
    class="btn-check"
    id="btnradio3"
    name="btnradio"
    type="radio"
  />
  <label class="btn btn-secondary" for="btnradio3">Three</label>

  <input
    [(ngModel)]="radio"
    [value]="Choice.Four"
    autocomplete="off"
    class="btn-check"
    id="btnradio4"
    name="btnradio"
    type="radio"
    disabled
  />
  <label class="btn btn-secondary" for="btnradio4">Four (disabled)</label>
</fieldset>
