<div class="heading py-5">
  <h1 class="bold font-curve-large">Badge</h1>
  <app-dependency-link></app-dependency-link>
</div>

<h3 class="bold">Default badges</h3>
<section class="mt-regular-r">
  <app-badge-demo></app-badge-demo>
  <code class="block mt-regular-r" [highlight]="badgeTemplate" [languages]="['html']"></code>
</section>

<h3 class="bold mt-huge-r">Nested badges</h3>
<section class="mt-regular-r">
  <app-badge-nested-demo></app-badge-nested-demo>
  <code class="block mt-regular-r" [highlight]="badgeNestedTemplate" [languages]="['html']"></code>
</section>

<h3 class="bold mt-huge-r">Interactive badges</h3>
<section class="mt-regular-r">
  <app-badge-interactive-demo></app-badge-interactive-demo>
  <code
    class="block mt-regular-r"
    [highlight]="badgeInteractiveTemplate"
    [languages]="['html']"
  ></code>
</section>
