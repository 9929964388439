<div class="bg-light pt-3 pb-3 mb-3">
  <div class="container">
    <div class="row row-cols-1 row-cols-md-2">
      <div class="col">
        <!-- Benutzerangaben -->
        <div class="card card-white">
          <div class="card-header d-flex">
            <div class="pi pi-2x pi-3217"></div>
            <h3 class="d-inline font-weight-bold text-large m-0">Benutzerangaben</h3>
            <div class="ms-auto">
              <a href="#" title="Account Management">
                <span class="pi pi-1.5x pi-3020"></span>
              </a>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <div class="list-group-item d-flex">
              <div>
                Herr
                <br />
                Hans Muster
                <br />
                <div>
                  Musterstrasse 1
                  <br />
                  1234 Musterstadt
                </div>
              </div>
              <a href="#" class="ms-auto"><span class="pi pi-3193"></span></a>
            </div>
            <div class="list-group-item d-flex">
              <p class="mb-0">
                Sprache:
                <span class="font-weight-bold">Deutsch</span>
              </p>
              <a href="#" class="ms-auto"><span class="pi pi-3193"></span></a>
            </div>
          </div>
          <div class="card-footer card-links">
            <a href="#">Adresse hinzufügen</a>
          </div>
        </div>

        <!-- Telefonnummern -->
        <div class="card card-white">
          <div class="card-header d-flex">
            <div class="pi pi-2x pi-2077"></div>
            <h3 class="d-inline font-weight-bold text-large m-0">Telefonnummern</h3>
          </div>
          <div class="list-group list-group-flush">
            <div class="list-group-item d-block">
              <div class="d-flex justify-content-between">
                <p class="mb-1">079 000 00 00</p>
                <span class="badge rounded-pill border-light border-2">nicht bestätigt</span>
              </div>
              <p>076 111 11 11</p>
            </div>
          </div>
          <div class="card-footer card-links">
            <a href="#">Telefonnummern verwalten</a>
            <a href="#">Handynummer bestatigen</a>
          </div>
        </div>
      </div>

      <div class="col">
        <!-- Guthaben -->
        <div class="card card-white">
          <div class="card-header d-flex">
            <div class="pi pi-2x pi-1000"></div>
            <h3 class="d-inline font-weight-bold text-large m-0">Guthaben & Zahlungsmittel</h3>
            <div class="ms-auto">
              <a href="#">
                <span class="pi pi-1.5x pi-3020"></span>
              </a>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <div class="list-group-item d-flex">
              <div>
                <h3 class="card-text-large font-weight-bold">CHF 25.00</h3>
                <p class="mb-0">
                  Debit Direct
                  <span class="ms-5">****** 346</span>
                </p>
              </div>
              <div class="ms-auto">
                <a href="#"><span class="pi pi-3193"></span></a>
              </div>
            </div>
          </div>
          <div class="card-footer card-links">
            <a href="#">Guthaben laden</a>
          </div>
        </div>

        <!-- Login -->
        <div class="card card-white">
          <div class="card-header d-flex">
            <div class="pi pi-2x pi-2034"></div>
            <h3 class="d-inline font-weight-bold text-large m-0">Login & Sicherheit</h3>
            <div class="ms-auto">
              <a href="#" title="Login Title">
                <span class="pi pi-1.5x pi-3020"></span>
              </a>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <div class="list-group-item d-flex">
              <p class="mb-0">hans.muster&#64;post.ch</p>
              <a href="#" class="ms-auto"><span class="pi pi-3193"></span></a>
            </div>
          </div>
          <div class="card-footer card-links">
            <a href="#">Password ändern</a>
            <a href="#">2 - Faktor Authentisierung</a>
          </div>
        </div>

        <!-- Newsletter -->
        <div class="card card-white">
          <div class="card-header d-flex">
            <div class="pi pi-2x pi-2006"></div>
            <h3 class="d-inline font-weight-bold text-large m-0">Newsletter</h3>
          </div>
          <div class="list-group list-group-flush">
            <div class="list-group-item d-flex">
              <p class="mb-0">Newsletter Postshop aboniert</p>
              <a href="#" class="ms-auto"><span class="pi pi-2043"></span></a>
            </div>
          </div>
          <div class="card-footer card-links">
            <a href="#">Newsletter verwalten</a>
          </div>
        </div>
      </div>
    </div>
    <!-- .card-columns -->
  </div>
  <!-- .container -->
</div>
<!-- .container-fluid -->
