<fieldset aria-label="Checkboxes with Reactive Froms" class="btn-group">
  <input
    [(ngModel)]="checkboxes.one"
    autocomplete="off"
    class="btn-check"
    id="btncheck1"
    type="checkbox"
  />
  <label class="btn btn-secondary" for="btncheck1">One (pre-checked)</label>

  <input
    [(ngModel)]="checkboxes.two"
    autocomplete="off"
    class="btn-check"
    id="btncheck2"
    type="checkbox"
  />
  <label class="btn btn-secondary" for="btncheck2">Two</label>

  <input
    [(ngModel)]="checkboxes.three"
    autocomplete="off"
    class="btn-check"
    id="btncheck3"
    type="checkbox"
  />
  <label class="btn btn-secondary" for="btncheck3">Three (pre-checked)</label>

  <input
    [(ngModel)]="checkboxes.four"
    autocomplete="off"
    class="btn-check"
    id="btncheck4"
    type="checkbox"
    disabled
  />
  <label class="btn btn-secondary" for="btncheck4">Four (disabled)</label>
</fieldset>
