<div class="position-relative">
  <input
    #dpSimple="ngbDatepicker"
    [formControl]="simpleDatepicker"
    [navigation]="navigation"
    [class.form-control-sm]="inputSize === 'sm'"
    [class.form-control-rg]="inputSize === 'rg'"
    aria-label="Datepicker simple example"
    class="form-control"
    maxlength="10"
    ngbDatepicker
    placeholder="yyyy-mm-dd"
    type="text"
  />
  <button class="ngb-dp-open" (click)="dpSimple.toggle()" aria-label="Open calendar" type="button">
    <i class="pi pi-3203" aria-hidden="true"></i>
  </button>
</div>
