<div class="heading py-5">
  <h1 class="bold font-curve-large">Button Group</h1>
  <app-dependency-link></app-dependency-link>
</div>

<section>
  <h4 class="bold">Checkbox buttons</h4>

  <div class="row align-items-center">
    <div class="col-auto">
      <app-button-group-checkbox-demo
        *ngIf="checkboxType === FormType.TemplateDriven"
      ></app-button-group-checkbox-demo>
      <app-button-group-reactive-checkbox-demo
        *ngIf="checkboxType === FormType.Reactive"
      ></app-button-group-reactive-checkbox-demo>
    </div>
    <div class="col">
      <div class="form-check form-check-inline mb-0">
        <input
          [(ngModel)]="checkboxType"
          [value]="FormType.TemplateDriven"
          class="form-check-input"
          id="templateDrivenCheckbox"
          name="checkboxType"
          type="radio"
        />
        <label class="form-check-label" for="templateDrivenCheckbox">Template-driven from</label>
      </div>
      <div class="form-check form-check-inline mb-0">
        <input
          [(ngModel)]="checkboxType"
          [value]="FormType.Reactive"
          class="form-check-input"
          id="reactiveCheckbox"
          name="checkboxType"
          type="radio"
        />
        <label class="form-check-label" for="reactiveCheckbox">Reactive from</label>
      </div>
    </div>
  </div>

  <ng-container *ngIf="checkboxType === FormType.TemplateDriven">
    <code class="block mt-large" [highlight]="checkboxTemplate" [languages]="['html']"></code>
    <code class="block mt-regular" [highlight]="checkboxController" [languages]="['ts']"></code>
  </ng-container>

  <ng-container *ngIf="checkboxType === FormType.Reactive">
    <code
      class="block mt-large"
      [highlight]="reactiveCheckboxTemplate"
      [languages]="['html']"
    ></code>
    <code
      class="block mt-regular"
      [highlight]="reactiveCheckboxController"
      [languages]="['ts']"
    ></code>
  </ng-container>
</section>

<section>
  <h4 class="bold">Radio buttons</h4>

  <div class="row align-items-center">
    <div class="col-auto">
      <app-button-group-radio-demo
        *ngIf="radioType === FormType.TemplateDriven"
      ></app-button-group-radio-demo>
      <app-button-group-reactive-radio-demo
        *ngIf="radioType === FormType.Reactive"
      ></app-button-group-reactive-radio-demo>
    </div>
    <div class="col">
      <div class="form-check form-check-inline mb-0">
        <input
          [(ngModel)]="radioType"
          [value]="FormType.TemplateDriven"
          class="form-check-input"
          id="templateDrivenRadio"
          name="radioType"
          type="radio"
        />
        <label class="form-check-label" for="templateDrivenRadio">Template-driven from</label>
      </div>
      <div class="form-check form-check-inline mb-0">
        <input
          [(ngModel)]="radioType"
          [value]="FormType.Reactive"
          class="form-check-input"
          id="reactiveRadio"
          name="radioType"
          type="radio"
        />
        <label class="form-check-label" for="reactiveRadio">Reactive from</label>
      </div>
    </div>
  </div>

  <ng-container *ngIf="radioType === FormType.TemplateDriven">
    <code class="block mt-large" [highlight]="radioTemplate" [languages]="['html']"></code>
    <code class="block mt-regular" [highlight]="radioController" [languages]="['ts']"></code>
  </ng-container>

  <ng-container *ngIf="radioType === FormType.Reactive">
    <code class="block mt-large" [highlight]="reactiveRadioTemplate" [languages]="['html']"></code>
    <code
      class="block mt-regular"
      [highlight]="reactiveRadioController"
      [languages]="['ts']"
    ></code>
  </ng-container>
</section>

<section>
  <h4 class="bold">Bad example</h4>
  <p>
    The only supported button variant for button groups is
    <code>.btn-secondary</code>
    .
  </p>
  <fieldset class="btn-group" aria-label="Basic mixed styles example">
    <button type="button" class="btn btn-nightblue">Left</button>
    <button type="button" class="btn btn-danger">Middle</button>
    <button type="button" class="btn btn-success">Right</button>
  </fieldset>
</section>
