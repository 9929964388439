<form>
  <div class="form-floating mb-regular">
    <input
      type="email"
      class="form-control form-control-lg"
      id="exampleInputEmail1"
      placeholder="Email address"
      aria-describedby="emailHelp"
    />
    <label for="exampleInputEmail1" class="form-label">Email address</label>
    <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
  </div>
  <div class="form-floating mb-regular">
    <input
      type="password"
      class="form-control form-control-lg"
      placeholder=" "
      id="exampleInputPassword1"
    />
    <label for="exampleInputPassword1" class="form-label">Password</label>
  </div>
  <div class="form-check mb-3">
    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
    <label class="form-check-label" for="exampleCheck1">Check me out</label>
  </div>

  <div class="d-grid gap-mini d-md-flex justify-content-md-end border-top border-2 pt-regular">
    <button class="btn btn-secondary" type="reset">Reset</button>
    <button class="btn btn-primary" type="submit">Submit</button>
  </div>
</form>
