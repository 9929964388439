<div class="heading py-5">
  <h1 class="bold font-curve-large">Form select</h1>
  <app-dependency-link documentationPath="forms/select"></app-dependency-link>
</div>

<div class="alert alert-warning mb-bigger-big">
  <h2 class="alert-heading">
    We're deprecating the regular and medium sizes for text input, textarea and select.
  </h2>
  <div class="mt-0">
    This will make it easier to select the appropriate size variant: small for internal
    applications, large for external applications.
    <br />
    <code>.form-select-rg</code>
    and
    <code>.form-select-md</code>
    are deprecated and will be removed in the next major version.
  </div>
</div>

<section>
  <app-select-demo></app-select-demo>
</section>

<div class="alert alert-info mb-bigger-big" role="alert">
  <h4 class="alert-heading">Need to customize the display of options?</h4>
  <p>
    Check out our example of
    <a routerLink="/post-samples/custom-select">custom select</a>
    .
  </p>
</div>

<code
  class="block"
  [highlight]="codeTemplate"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>
