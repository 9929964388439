<ngb-progressbar
  ariaLabel="Example of progress."
  type="primary"
  [showValue]="true"
  [value]="12.5"
></ngb-progressbar>

<ngb-progressbar
  ariaLabel="Example of progress."
  type="secondary"
  [showValue]="true"
  [value]="25"
></ngb-progressbar>

<ngb-progressbar
  ariaLabel="Example of progress."
  type="success"
  [showValue]="true"
  [value]="37.5"
></ngb-progressbar>

<ngb-progressbar
  ariaLabel="Example of progress."
  type="error"
  [showValue]="true"
  [value]="50"
></ngb-progressbar>

<ngb-progressbar
  ariaLabel="Example of progress."
  type="warning"
  [showValue]="true"
  [value]="62.5"
></ngb-progressbar>

<ngb-progressbar
  ariaLabel="Example of progress."
  type="info"
  [showValue]="true"
  [value]="75"
></ngb-progressbar>

<ngb-progressbar
  ariaLabel="Example of progress."
  type="light"
  [showValue]="true"
  [value]="87.5"
></ngb-progressbar>

<ngb-progressbar
  ariaLabel="Example of progress."
  type="dark"
  [showValue]="true"
  [value]="100"
></ngb-progressbar>
