<div class="heading py-5">
  <h1 class="bold font-curve-large">Dropdown</h1>
  <app-dependency-link></app-dependency-link>
</div>

<section>
  <app-ngb-dropdown-demo></app-ngb-dropdown-demo>
</section>
<code
  class="block"
  [highlight]="codeTemplate"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>
