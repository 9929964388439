<h3>Form radio examples</h3>
<div class="row row-cols-1 row-cols-rg-2 align-items-center">
  <div class="col">
    <div class="form-check">
      <input class="form-check-input" id="formRadioDefault" name="formRadioDefault" type="radio" />
      <label class="form-check-label" for="formRadioDefault">Default radio</label>
    </div>
    <div class="form-check">
      <input
        checked
        class="form-check-input"
        id="formRadioChecked"
        name="formRadioDefault"
        type="radio"
      />
      <label class="form-check-label" for="formRadioChecked">Checked radio</label>
    </div>
  </div>

  <div class="col">
    <div class="bg-nightblue-bright py-large px-big">
      <div class="form-check">
        <input
          class="form-check-input"
          id="formRadioDefaultColoredBg"
          name="formRadioColoredBg"
          type="radio"
        />
        <label class="form-check-label" for="formRadioDefaultColoredBg">Default radio</label>
      </div>
      <div class="form-check">
        <input
          checked
          class="form-check-input"
          id="formRadioCheckedColoredBg"
          name="formRadioColoredBg"
          type="radio"
        />
        <label class="form-check-label" for="formRadioCheckedColoredBg">Checked radio</label>
      </div>
    </div>
  </div>
</div>

<h3 class="mt-4">Inline radios</h3>
<div class="form-check form-check-inline">
  <input
    class="form-check-input"
    id="inlineRadio1"
    name="inlineRadioOptions"
    type="radio"
    value="option1"
  />
  <label class="form-check-label" for="inlineRadio1">1</label>
</div>
<div class="form-check form-check-inline">
  <input
    class="form-check-input"
    id="inlineRadio2"
    name="inlineRadioOptions"
    type="radio"
    value="option2"
  />
  <label class="form-check-label" for="inlineRadio2">2</label>
</div>

<h3 class="mt-4">Radio without label</h3>
<input
  aria-label="This label is required for accessibility"
  class="form-check-input"
  id="radioNoLabel1"
  name="radioNoLabel"
  type="radio"
  value=""
/>

<h3 class="mt-4">Radio with long label</h3>
<div class="row row-cols-1 row-cols-rg-2">
  <div class="col">
    <div class="form-check">
      <input
        class="form-check-input"
        id="formRadioLongLabel"
        name="formRadioLongLabel"
        type="radio"
      />
      <label class="form-check-label" for="formRadioLongLabel">
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
        invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
        et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
        Lorem ipsum dolor sit amet.
      </label>
    </div>
  </div>
</div>

<h3 class="mt-4">Validation</h3>
<div class="row row-cols-1 row-cols-rg-2 row-cols-lg-3">
  <div class="col">
    <div class="form-check">
      <input class="form-check-input is-invalid" id="FormRadioInvalid" type="radio" />
      <label class="form-check-label" for="FormRadioInvalid">Invalid Radio</label>
      <p class="invalid-feedback">Error message</p>
    </div>
  </div>
  <div class="col">
    <div class="form-check">
      <input class="form-check-input is-valid" id="FormRadioValid" type="radio" checked />
      <label class="form-check-label" for="FormRadioValid">Valid Radio</label>
      <p class="valid-feedback">Success message (optional)</p>
    </div>
  </div>
</div>

<div class="row row-cols-1 row-cols-rg-2 row-cols-lg-3 pt-4 bg-light">
  <div class="col">
    <div class="form-check">
      <input class="form-check-input is-invalid" id="FormRadioInvalidOnLight" type="radio" />
      <label class="form-check-label" for="FormRadioInvalidOnLight">Invalid Radio</label>
      <p class="invalid-feedback">Error message</p>
    </div>
  </div>
  <div class="col">
    <div class="form-check">
      <input class="form-check-input is-valid" id="FormRadioValidOnLight" type="radio" checked />
      <label class="form-check-label" for="FormRadioValidOnLight">Valid Radio</label>
      <p class="valid-feedback">Success message (optional)</p>
    </div>
  </div>
</div>

<div class="row row-cols-1 row-cols-rg-2 row-cols-lg-3 pt-4 bg-dark">
  <div class="col">
    <div class="form-check">
      <input class="form-check-input is-invalid" id="FormRadioInvalidOnDark" type="radio" />
      <label class="form-check-label" for="FormRadioInvalidOnDark">Invalid Radio</label>
      <p class="invalid-feedback">Error message</p>
    </div>
  </div>
  <div class="col">
    <div class="form-check">
      <input class="form-check-input is-valid" id="FormRadioValidOnDark" type="radio" checked />
      <label class="form-check-label" for="FormRadioValidOnDark">Valid Radio</label>
      <p class="valid-feedback">Success message (optional)</p>
    </div>
  </div>
</div>
