<p>
  The bootstrap sizing utilities & contextual classes can be used as shown on the bootstrap
  documentation.
</p>
<p>The nav's (tabs or pills) can't be used inside the card-header with our implementation.</p>

<h3 class="mt-huge-r">Images in cards</h3>

<div class="row">
  <div class="col-lg-4 col-rg-6 col-12 mb-3">
    <div class="card mb-3">
      <img class="card-img-top" [src]="imagePath" alt="" />
      <div class="card-body">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">
          This is a wider card with supporting text below as a natural lead-in to additional
          content. This content is a little bit longer.
        </p>
        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-rg-6 col-12 mb-3">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">
          This is a wider card with supporting text below as a natural lead-in to additional
          content. This content is a little bit longer.
        </p>
        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
      </div>
      <img class="card-img-bottom" [src]="imagePath" alt="" />
    </div>
  </div>
</div>

<h3 class="mt-huge-r">Bad example</h3>
<div class="alert alert-danger">
  Don't use this in production. It's very difficult for content editors to always ensure that text
  is legible on an image for every browser size and all different languages.
</div>
<div class="row">
  <div class="col-lg-4 col-rg-6 col-12 mb-3">
    <div class="card">
      <img class="card-img" [src]="imagePath" alt="" />
      <div class="card-img-overlay">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">
          This is a wider card with supporting text below as a natural lead-in to additional
          content. This content is a little bit longer.
        </p>
        <p class="card-text text-white">Last updated 3 mins ago</p>
      </div>
    </div>
  </div>
</div>

<h3 class="mt-huge-r">Card groups</h3>
<div class="alert-container">
  <div class="alert alert-warning">
    <p>Card groups do not work correctly with images in IE.</p>
  </div>
</div>

<div class="card-group mb-5">
  <div class="card">
    <img class="card-img-top" [src]="imagePath" alt="" />
    <div class="card-body">
      <h5 class="card-title">Card title</h5>
      <p class="card-text">
        This is a wider card with supporting text below as a natural lead-in to additional content.
        This content is a little bit longer.
      </p>
      <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
    </div>
  </div>
  <div class="card">
    <img class="card-img-top" [src]="imagePath" alt="" />
    <div class="card-body">
      <h5 class="card-title">Card title</h5>
      <p class="card-text">
        This card has supporting text below as a natural lead-in to additional content.
      </p>
      <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
    </div>
  </div>
  <div class="card">
    <img class="card-img-top" [src]="imagePath" alt="" />
    <div class="card-body">
      <h5 class="card-title">Card title</h5>
      <p class="card-text">
        This is a wider card with supporting text below as a natural lead-in to additional content.
        This card has even longer content than the first to show that equal height action.
      </p>
      <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
    </div>
  </div>
</div>

<div class="card-group mb-5">
  <div class="card">
    <img class="card-img-top" [src]="imagePath" alt="" />
    <div class="card-body">
      <h5 class="card-title">Card title</h5>
      <p class="card-text">
        This is a wider card with supporting text below as a natural lead-in to additional content.
        This content is a little bit longer.
      </p>
    </div>
    <div class="card-footer">
      <small class="text-muted">Last updated 3 mins ago</small>
    </div>
  </div>
  <div class="card">
    <img class="card-img-top" [src]="imagePath" alt="" />
    <div class="card-body">
      <h5 class="card-title">Card title</h5>
      <p class="card-text">
        This card has supporting text below as a natural lead-in to additional content.
      </p>
    </div>
    <div class="card-footer">
      <small class="text-muted">Last updated 3 mins ago</small>
    </div>
  </div>
  <div class="card">
    <img class="card-img-top" [src]="imagePath" alt="" />
    <div class="card-body">
      <h5 class="card-title">Card title</h5>
      <p class="card-text">
        This is a wider card with supporting text below as a natural lead-in to additional content.
        This card has even longer content than the first to show that equal height action.
      </p>
    </div>
    <div class="card-footer">
      <small class="text-muted">Last updated 3 mins ago</small>
    </div>
  </div>
</div>

<h3>Card columns</h3>
<p>Card columns can be checked out in the Swiss Post example below.</p>

<p>
  Default
  <code>column-count: 2</code>
  , breakpoint md and smaller
  <code>column-count: 1</code>
  . This can be adjusted as necessary.
</p>
