<div class="heading py-5">
  <h1 class="bold font-curve-large">Tooltip</h1>
  <app-dependency-link></app-dependency-link>
</div>

<section>
  <app-ngb-tooltip-demo></app-ngb-tooltip-demo>
  <p class="mt-3">
    Using
    <code>placement="[position] auto"</code>
    allows the tooltip to be displayed in another location if there is no room for the initial
    placement location.
  </p>
</section>
<code
  class="block"
  [highlight]="codeTemplate"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>
