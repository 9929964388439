<div class="heading py-5">
  <h1 class="bold font-curve-large">Custom select</h1>
</div>

<div class="alert alert-warning mb-bigger-big">
  <h2 class="alert-heading">This component is deprecated</h2>
  <p>It will be removed in the next major version.</p>
</div>

<section>
  <app-custom-select-floating-demo></app-custom-select-floating-demo>
  <br />
  <app-custom-select-floating-demo noSelected="true"></app-custom-select-floating-demo>
  <br />
  <app-custom-select-demo></app-custom-select-demo>
</section>

<p class="h5 bold mt-huge">custom-select-with-floating-label.component.html</p>
<code class="block" [highlight]="codeTemplateFloating" [languages]="['html']"></code>

<p class="h5 bold mt-huge">custom-select.component.html</p>
<code class="block" [highlight]="codeTemplate" [languages]="['html']"></code>

<p class="h5 bold mt-big">custom-select.component.ts</p>
<code class="block" [highlight]="codeComponent" [languages]="['typescript']"></code>
