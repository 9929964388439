<div class="d-flex flex-wrap gap-3">
  <button
    type="button"
    class="btn btn-animated btn-secondary"
    placement="top"
    ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
    popoverTitle="Popover on top"
  >
    <span>Popover on top</span>
  </button>

  <button
    type="button"
    class="btn btn-animated btn-secondary"
    placement="right"
    ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
    popoverTitle="Popover on right"
  >
    <span>Popover on right</span>
  </button>

  <button
    type="button"
    class="btn btn-animated btn-secondary"
    placement="bottom"
    ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
    popoverTitle="Popover on bottom"
  >
    <span>Popover on bottom</span>
  </button>

  <button
    type="button"
    class="btn btn-animated btn-secondary"
    placement="left"
    ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
    popoverTitle="Popover on left"
  >
    <span>Popover on left</span>
  </button>

  <ng-template #popContent>
    Vivamus sagittis lacus vel augue laoreet rutrum
    <a href="#">link</a>
    faucibus.
  </ng-template>
  <ng-template #popTitle>
    Popover with
    <strong>markup</strong>
  </ng-template>

  <button
    type="button"
    class="btn btn-animated btn-secondary"
    [ngbPopover]="popContent"
    [popoverTitle]="popTitle"
  >
    <span>Popover with markup</span>
  </button>
</div>
