<div
  *ngFor="let color of colorArray"
  class="p-2 my-1"
  [class]="'bg-' + color"
  [style]="'--post-bg-opacity:' + (dropOpacity ? 0.8 : 1) + ';'"
>
  <div class="d-flex justify-content-between align-items-center">
    <strong class="text-capitalize">{{ color }}</strong>
    <small>
      <code>.bg-{{ color }}</code>
    </small>
  </div>
</div>
