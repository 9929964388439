<ngx-datatable [rows]="rows" class="w-75" columnMode="flex" headerHeight="auto" rowHeight="auto">
  <ngx-datatable-column
    [draggable]="false"
    [flexGrow]="1"
    [resizeable]="false"
    [sortable]="false"
    prop="product"
  ></ngx-datatable-column>
  <ngx-datatable-column
    [draggable]="false"
    [flexGrow]="0"
    [minWidth]="105"
    [resizeable]="false"
    [sortable]="false"
    prop="quantity"
  ></ngx-datatable-column>

  <!-- Edit button -->
  <ngx-datatable-column
    [draggable]="false"
    [flexGrow]="0"
    [minWidth]="65"
    [resizeable]="false"
    [sortable]="false"
    cellClass="datatable-button-cell"
  >
    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
      <div
        #dropdown="ngbDropdown"
        class="d-inline-block"
        container="body"
        ngbDropdown
        placement="bottom-right"
      >
        <button
          [id]="'statusDropdown' + row.id"
          class="datatable-button btn-icon no-toggle-arrow"
          ngbDropdownToggle
        >
          <em aria-hidden="true" class="pi pi-3193"></em>
          <span class="visually-hidden">Edit product {{ row.product }}</span>
        </button>
        <div [aria-labelledby]="'statusDropdown' + row.id" ngbDropdownMenu>
          <ng-container
            [ngTemplateOutletContext]="{ row: row, rowIndex: rowIndex, dropdown: dropdown }"
            [ngTemplateOutlet]="editionForm"
          ></ng-container>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<!-- Edition form -->
<ng-template #editionForm let-dropdown="dropdown" let-row="row" let-rowIndex="rowIndex">
  <form #f="ngForm" (submit)="Object.assign(rows[rowIndex], f.value)" class="p-regular">
    <p class="h5 font-weight-bold mb-regular">Update product</p>
    <div class="form-floating mb-regular">
      <input
        [ngModel]="row.product"
        [id]="'productName_' + row.id"
        class="form-control form-control-lg"
        name="product"
        placeholder="Product name"
      />
      <label class="form-label" [for]="'productName_' + row.id">Product name</label>
    </div>
    <div class="form-floating mb-regular">
      <input
        [ngModel]="row.quantity"
        [id]="'quantity_' + row.id"
        class="form-control form-control-lg"
        name="quantity"
        placeholder="Quantity available"
      />
      <label class="form-label" [for]="'quantity_' + row.id">Available quantity</label>
    </div>
    <div class="d-flex flex-row-reverse pt-regular">
      <button
        (click)="dropdown.close()"
        [disabled]="f.pristine"
        class="btn btn-rg btn-primary"
        type="submit"
      >
        Apply
      </button>
      <button (click)="dropdown.close()" class="btn btn-rg btn-secondary me-mini" type="button">
        Cancel
      </button>
    </div>
  </form>
</ng-template>
