<h4>Pagination</h4>

<ngb-pagination
  [collectionSize]="120"
  [(page)]="page"
  [maxSize]="3"
  [rotate]="true"
></ngb-pagination>

<hr />

<pre>Current page: {{ page }}</pre>
