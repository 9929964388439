<sp-intranet-header siteTitle="SiteTitle" languages="de,fr,it">
  <li class="nav-item">
    <a class="nav-link" href="#">Testlink</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#">Testlink</a>
  </li>
  <li class="nav-item" ngbDropdown>
    <button class="nav-link" id="testid01" ngbDropdownToggle type="button">Dropdown</button>
    <div aria-labelledby="testid01" ngbDropdownMenu>
      <a ngbDropdownItem href="#" ngbDropdownItem>Link 1</a>
      <a ngbDropdownItem href="#" ngbDropdownItem>Link 2</a>
      <a ngbDropdownItem href="#" ngbDropdownItem>Link 3</a>
    </div>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#">Testlink</a>
  </li>
</sp-intranet-header>
