<div class="row card-buttons">
  <div class="col-lg-4 col-md-6">
    <div class="card card-button bg-nightblue">
      <button class="post-card-favourit"><i class="pi pi-2062-white"></i></button>
      <a href="#" class="card-body d-flex align-items-center">
        <i class="pi pi-1014-white"></i>
        <div>
          <p class="mb-1">Schwerpunkt</p>
          <h5 class="font-weight-bold mb-0">Post zurückbehalten</h5>
        </div>
      </a>
    </div>
  </div>
  <div class="col-lg-4 col-md-6">
    <div class="card card-button bg-nightblue">
      <button class="post-card-favourit"><i class="pi pi-favourite"></i></button>
      <a href="#" class="card-body d-flex align-items-center">
        <i class="pi pi-1014-white"></i>
        <div>
          <p class="mb-1">Schwerpunkt</p>
          <h5 class="font-weight-bold mb-0">Briefmarken</h5>
        </div>
      </a>
    </div>
  </div>
  <div class="col-lg-4 col-md-6">
    <div class="card card-button bg-nightblue">
      <button class="post-card-favourit"><i class="pi pi-2062-white"></i></button>
      <a href="#" class="card-body d-flex align-items-center">
        <i class="pi pi-1014-white"></i>
        <div>
          <p class="mb-1">Schwerpunkt</p>
          <h5 class="font-weight-bold mb-0">Meine Sendungen</h5>
        </div>
      </a>
    </div>
  </div>
  <div class="col-lg-4 col-md-6">
    <div class="card card-button bg-nightblue">
      <button class="post-card-favourit"><i class="pi pi-2062-white"></i></button>
      <a href="#" class="card-body d-flex align-items-center">
        <i class="pi pi-1014-white"></i>
        <div>
          <p class="mb-1">Schwerpunkt</p>
          <h5 class="font-weight-bold mb-0">Pick&#64;home</h5>
        </div>
      </a>
    </div>
  </div>
  <div class="col-lg-4 col-md-6">
    <div class="card card-button bg-nightblue">
      <button class="post-card-favourit"><i class="pi pi-2062-white"></i></button>
      <a href="#" class="card-body d-flex align-items-center">
        <i class="pi pi-1014-white"></i>
        <div>
          <p class="mb-1">Schwerpunkt</p>
          <h5 class="font-weight-bold mb-0">Post zurückbehalten</h5>
        </div>
      </a>
    </div>
  </div>
  <div class="col-lg-4 col-md-6">
    <div class="card card-button">
      <button class="post-card-favourit"><i class="pi pi-favourite"></i></button>
      <a href="#" class="card-body d-flex align-items-center">
        <i class="pi pi-1014"></i>
        <div>
          <h5 class="font-weight-bold mb-0">Adressänderung mit Nachsendung</h5>
        </div>
      </a>
    </div>
  </div>
  <div class="col-lg-4 col-md-6">
    <div class="card card-button">
      <button class="post-card-favourit"><i class="pi pi-favourite"></i></button>
      <a href="#" class="card-body d-flex align-items-center">
        <i class="pi pi-1014"></i>
        <div>
          <h5 class="font-weight-bold mb-0">E-Finance: Demoversion</h5>
        </div>
      </a>
    </div>
  </div>
  <div class="col-lg-4 col-md-6">
    <div class="card card-button">
      <button class="post-card-favourit"><i class="pi pi-2062"></i></button>
      <a href="#" class="card-body d-flex align-items-center">
        <i class="pi pi-1014"></i>
        <div>
          <h5 class="font-weight-bold mb-0">Filiale mit Partner</h5>
        </div>
      </a>
    </div>
  </div>
  <div class="col-lg-4 col-md-6">
    <div class="card card-button">
      <button class="post-card-favourit"><i class="pi pi-2062"></i></button>
      <a href="#" class="card-body d-flex align-items-center">
        <i class="pi pi-1014"></i>
        <div>
          <h5 class="font-weight-bold mb-0">Frankieren</h5>
        </div>
      </a>
    </div>
  </div>
</div>
