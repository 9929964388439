<h3>Form switch examples</h3>
<div class="row row-cols-1 row-cols-rg-2 align-items-center">
  <!-- Right-labeled switches -->
  <div class="col">
    <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" role="switch" id="formSwitchDefault" />
      <label class="form-check-label" for="formSwitchDefault">Default switch</label>
    </div>
    <div class="form-check form-switch">
      <input
        class="form-check-input"
        type="checkbox"
        role="switch"
        id="formSwitchChecked"
        checked
      />
      <label class="form-check-label" for="formSwitchChecked">Checked switch</label>
    </div>
  </div>

  <!-- Left-labeled switches -->
  <div class="col">
    <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" role="switch" id="formSwitchLeftLabel" />
      <label class="form-check-label order-first" for="formSwitchLeftLabel">
        Default left-labeled switch
      </label>
    </div>
    <div class="form-check form-switch">
      <input
        class="form-check-input"
        type="checkbox"
        role="switch"
        id="formSwitchLeftLabelChecked"
        checked
      />
      <label class="form-check-label order-first" for="formSwitchLeftLabelChecked">
        Checked left-labeled switch
      </label>
    </div>
  </div>
</div>

<h3 class="mt-4">Inline switches</h3>
<div class="form-check form-check-inline form-switch">
  <input class="form-check-input" type="checkbox" role="switch" id="inlineSwitch1" />
  <label class="form-check-label" for="inlineSwitch1">1</label>
</div>
<div class="form-check form-check-inline form-switch">
  <input class="form-check-input" type="checkbox" role="switch" id="inlineSwitch2" />
  <label class="form-check-label" for="inlineSwitch2">2</label>
</div>

<h3 class="mt-4">Switch with two labels</h3>
<div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" role="switch" id="formSwitchTwoLabels" />
  <label class="form-check-label order-first" for="formSwitchTwoLabels">Off</label>
  <label class="form-check-label" for="formSwitchTwoLabels">On</label>
</div>

<h3 class="mt-4">Switch without label</h3>
<div class="form-switch">
  <input
    aria-label="This label is required for accessibility"
    class="form-check-input"
    type="checkbox"
    role="switch"
    id="switchNoLabel"
  />
</div>

<h3 class="mt-4">Switch with long label</h3>
<div class="row row-cols-1 row-cols-rg-2">
  <div class="col">
    <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" role="switch" id="formSwitchLongLabel" />
      <label class="form-check-label" for="formSwitchLongLabel">
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
        invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
        et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
        Lorem ipsum dolor sit amet.
      </label>
    </div>
  </div>
</div>

<h3 class="mt-4">Validation</h3>
<div class="row row-cols-1 row-cols-rg-2 align-items-center">
  <div class="col">
    <div class="form-check form-switch">
      <input
        id="FormSwitchInvalid"
        class="form-check-input is-invalid"
        type="checkbox"
        role="switch"
      />
      <label class="form-check-label" for="FormSwitchInvalid">Invalid Switch</label>
      <p class="invalid-feedback">Error message</p>
    </div>
  </div>
  <div class="col">
    <div class="form-check form-switch">
      <input
        id="FormSwitchValid"
        class="form-check-input is-valid"
        type="checkbox"
        role="switch"
        checked
      />
      <label class="form-check-label" for="FormSwitchValid">Valid Switch</label>
      <p class="valid-feedback">Success message (optional)</p>
    </div>
  </div>
</div>
