<button class="btn btn-secondary" (click)="toggleEx1()" #ex1btn>Toggle Ex. 1</button>

<button class="btn btn-secondary ms-3" (click)="toggleEx2()" #ex2btn>Toggle Ex. 2</button>

<button class="btn btn-secondary ms-3" (click)="toggleEx3()" #ex3btn>Toggle Ex. 3</button>

<div class="alert alert-info alert-action pi-1001 alert-fixed-bottom" role="alert" #ex1>
  <div class="alert-content">
    <h4 class="alert-heading">Neue Nachricht erhalten</h4>
    <p>Sie haben eine neue Nachricht von Paula erhalten.</p>
  </div>
  <div class="alert-buttons">
    <button class="btn btn-primary btn-animated" type="button"><span>Anschauen</span></button>
    <button class="btn btn-secondary btn-animated" type="button" (click)="toggleEx1()">
      <span>Nicht jetzt</span>
    </button>
  </div>
</div>

<div class="alert alert-success alert-action alert-fixed-bottom" role="alert" #ex2>
  <div class="alert-content">
    <h4 class="alert-heading">Die Zustellung wurde angepasst</h4>
    <p>Sie erhalten das Paket neu unter einer geänderten Adresse.</p>
  </div>
  <div class="alert-buttons">
    <button class="btn btn-primary btn-animated" type="button"><span>Rückgängig</span></button>
    <button class="btn btn-secondary btn-animated" type="button" (click)="toggleEx2()">
      <span>Ausblenden</span>
    </button>
  </div>
</div>

<div class="alert alert-primary alert-action pi-2063-white alert-fixed-bottom" role="alert" #ex3>
  <div class="alert-content">
    <h4 class="alert-heading">
      This is an example with a lot of text, a lot of buttons and a special icon
    </h4>
    <p>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
      invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
      justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
      ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
      eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
    </p>
    <p>
      Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel
      illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui
      blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem
      ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
      laoreet dolore magna aliquam erat volutpat.
    </p>
  </div>
  <div class="alert-buttons">
    <button class="btn btn-primary btn-animated" type="button"><span>Primary</span></button>
    <button class="btn btn-secondary btn-animated" type="button"><span>Secondary</span></button>
    <button class="btn btn-secondary btn-animated" type="button" (click)="toggleEx3()">
      <span>Close</span>
    </button>
  </div>
</div>
