A typeahead example that gets values from a static
<code>string[]</code>
<ul>
  <li>
    <code>debounceTime</code>
    operator
  </li>
  <li>kicks in only if 2+ characters typed</li>
  <li>limits to 10 results</li>
</ul>

<div class="form-floating mb-3">
  <input
    id="typeahead-basic"
    placeholder=" "
    type="text"
    class="form-control form-control-lg"
    [(ngModel)]="model"
    [ngbTypeahead]="search"
  />
  <label for="typeahead-basic" class="form-label">Search for a US state</label>
</div>
<hr />
<pre>Model: {{ model | json }}</pre>
