<fieldset [formGroup]="checkboxes" aria-label="Checkboxes with Reactive Froms" class="btn-group">
  <input
    autocomplete="off"
    class="btn-check"
    formControlName="one"
    id="btncheck1"
    type="checkbox"
  />
  <label class="btn btn-secondary" for="btncheck1">One (pre-checked)</label>

  <input
    autocomplete="off"
    class="btn-check"
    formControlName="two"
    id="btncheck2"
    type="checkbox"
  />
  <label class="btn btn-secondary" for="btncheck2">Two</label>

  <input
    autocomplete="off"
    class="btn-check"
    formControlName="three"
    id="btncheck3"
    type="checkbox"
  />
  <label class="btn btn-secondary" for="btncheck3">Three (pre-checked)</label>

  <input
    autocomplete="off"
    class="btn-check"
    formControlName="four"
    id="btncheck4"
    type="checkbox"
  />
  <label class="btn btn-secondary" for="btncheck4">Four (disabled)</label>
</fieldset>
