<div class="stepper-container" role="group">
  <h2 class="visually-hidden">Order progress, step 3 of 4</h2>
  <ngb-progressbar
    [max]="steps.length - 1"
    [value]="currentIndex"
    class="stepper-bar"
    aria-hidden="true"
  ></ngb-progressbar>

  <ol class="stepper">
    <li
      *ngFor="let step of steps; index as i"
      [attr.aria-current]="isCurrent(step) ? 'step' : undefined"
      class="stepper-item"
    >
      <a *ngIf="i < currentIndex" class="stepper-link" (click)="currentIndex = i" href="#">
        <span class="visually-hidden">Complete:</span>
        {{ step }}
      </a>
      <span class="stepper-link" *ngIf="i >= currentIndex">
        {{ step }}
      </span>
    </li>
  </ol>
</div>
