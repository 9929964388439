<button type="button" class="btn btn-secondary btn-icon btn-sm">
  <i aria-hidden="true" class="pi pi-3193"></i>
  <span class="visually-hidden">Label for screenreaders</span>
</button>
<button type="button" class="btn btn-secondary btn-icon btn-rg">
  <i aria-hidden="true" class="pi pi-3193"></i>
  <span class="visually-hidden">Label for screenreaders</span>
</button>
<button type="button" class="btn btn-secondary btn-icon btn-md">
  <i aria-hidden="true" class="pi pi-3193"></i>
  <span class="visually-hidden">Label for screenreaders</span>
</button>
<button type="button" class="btn btn-secondary btn-icon btn-lg">
  <i aria-hidden="true" class="pi pi-3193"></i>
  <span class="visually-hidden">Label for screenreaders</span>
</button>
<button type="button" class="btn btn-primary btn-icon btn-sm">
  <i aria-hidden="true" class="pi pi-3193"></i>
  <span class="visually-hidden">Label for screenreaders</span>
</button>
<button type="button" class="btn btn-primary btn-icon btn-rg">
  <i aria-hidden="true" class="pi pi-3193"></i>
  <span class="visually-hidden">Label for screenreaders</span>
</button>
<button type="button" class="btn btn-primary btn-icon btn-md">
  <i aria-hidden="true" class="pi pi-3193"></i>
  <span class="visually-hidden">Label for screenreaders</span>
</button>
<button type="button" class="btn btn-primary btn-icon btn-lg">
  <i aria-hidden="true" class="pi pi-3193"></i>
  <span class="visually-hidden">Label for screenreaders</span>
</button>
