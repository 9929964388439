<button type="button" class="btn btn-primary">Primary inverted</button>
<button type="button" class="btn btn-primary btn-animated">
  <span>Primary inverted animated</span>
</button>
<button type="button" class="btn btn-secondary">Secondary</button>
<button type="button" class="btn btn-secondary btn-animated">
  <span>Secondary animated</span>
</button>
<button type="button" class="btn btn-primary btn-icon">
  <i aria-hidden="true" class="pi pi-3193"></i>
</button>
<button type="button" class="btn btn-secondary btn-icon">
  <i aria-hidden="true" class="pi pi-3193"></i>
</button>
