<section class="layout-container header-small">
  <sp-intranet-header [hasNavbar]="false" siteTitle="Site Title"></sp-intranet-header>
  <div class="container-fluid header-margin">
    <div class="row flex-grow-1">
      <nav
        class="col-md-3 col-lg-2 d-md-flex bg-light sidebar site-content p-md-3"
        id="sidebar"
        [ngClass]="{ closed: !openedMenu }"
      >
        <div class="sidebar-sticky header-margin bg-light">
          <!-- left menu -->
          <ul>
            <li class="d-md-none">
              <button (click)="openedMenu = !openedMenu">
                <i class="pi pi-menu"></i>
                Home
              </button>
            </li>
            <li><a routerLink="/">Home</a></li>
            <li class="separator"></li>
            <li>
              Menu Text
              <ul>
                <li class="level2">
                  <a href="#">Link 1</a>
                </li>
                <li class="level2">
                  <a href="#">Link 2</a>
                </li>
                <li class="level2">
                  <a href="#">Link 3</a>
                </li>
                <li class="level2">
                  <a href="#">Link 4</a>
                </li>
                <li class="level2">
                  <a href="#">Link 5</a>
                </li>
              </ul>
            </li>
          </ul>
          <span class="spacer d-none d-md-block"></span>
        </div>

        <div></div>
      </nav>
      <main role="main" class="col-md-9 col-lg-10 p-3">
        <h1>Content</h1>
        <p>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
          accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
          sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
          elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
          diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
          gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
          amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
          et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
          amet.
        </p>
      </main>
    </div>
  </div>
</section>
