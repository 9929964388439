<!--
  ~ Copyright 2021 by Swiss Post, Information Technology
  -->

<div class="heading py-5">
  <h1 class="bold font-curve-large">Stepper</h1>
</div>

<section>
  <app-stepper-demo></app-stepper-demo>
  <code class="block" [highlight]="codeTemplate" [languages]="['html']"></code>
  <code
    class="block"
    [highlight]="codeController"
    [languages]="['typescript', 'javascript']"
  ></code>
</section>
