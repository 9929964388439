<label class="form-label" for="templateDrivenRange">Template-driven range</label>
<input [(ngModel)]="templateDrivenValue" class="form-range" id="templateDrivenRange" type="range" />

<label class="form-label" for="reactiveRange">Reactive range</label>
<input
  (input)="reactiveValue.setValue($event.target['value'])"
  [formControl]="reactiveValue"
  class="form-range"
  id="reactiveRange"
  type="range"
/>

<label class="form-label" for="rangeWithValue">Range with displayed value</label>
<input
  [(ngModel)]="displayedValue"
  class="form-range"
  id="rangeWithValue"
  max="100"
  min="0"
  step="1"
  type="range"
/>
<div class="form-text">{{ displayedValue }}%</div>

<div class="row align-items-end">
  <div class="col">
    <label class="form-label" for="controlledRange">Controlled range</label>
    <input
      [(ngModel)]="controlledValue"
      class="form-range"
      id="controlledRange"
      max="100"
      min="0"
      step="1"
      type="range"
    />
  </div>
  <div class="col-auto">
    <label class="form-label visually-hidden" for="rangeController">Range controller</label>
    <input
      [(ngModel)]="controlledValue"
      class="form-control mw-giant"
      id="rangeController"
      type="text"
      inputmode="decimal"
    />
  </div>
</div>

<label class="form-label" for="FormRangeInvalid">Invalid Range</label>
<input class="form-range is-invalid" id="FormRangeInvalid" type="range" />
<p class="invalid-feedback">Error message</p>

<label class="form-label" for="FormRangeValid">Valid Range</label>
<input class="form-range is-valid" id="FormRangeValid" type="range" />
<p class="valid-feedback">Success message (optional)</p>
