<!--
  ~ Copyright 2021 by Swiss Post, Information Technology
  -->

<div class="row">
  <div class="col d-flex flex-column border-right">
    <h5>Manually specifying breakpoints</h5>
    <p class="flex-fill">
      The square below has a "Big" padding from the large (lg) breakpoint and a "Regular" padding
      below.
    </p>
    <div class="border border-dark bg-petrol h-bigger-giant w-bigger-giant p-regular p-lg-big">
      <div class="bg-light h-100"></div>
    </div>

    <!-- Code sample -->
    <code class="mt-regular block" [highlight]="highlightManualDemo" [languages]="['html']"></code>
  </div>
  <div class="col d-flex flex-column">
    <h5>Using automatic responsive behavior</h5>
    <p class="flex-fill">
      The square below has a "Large" responsive padding, which means the padding size automatically
      changes based on the breakpoint but remains visually consistent.
    </p>
    <div class="border border-dark bg-petrol h-bigger-giant w-bigger-giant p-large-r">
      <div class="bg-light h-100"></div>
    </div>

    <!-- Code sample -->
    <code
      class="mt-regular block"
      [highlight]="highlightAutomaticDemo"
      [languages]="['html']"
    ></code>
  </div>
</div>

<p class="text-muted"><small>Resize the browser window to see changes.</small></p>
