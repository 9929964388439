<div *ngIf="isIE11" class="container">
  <div class="alert alert-warning mt-3">
    <h4 class="alert-heading">IE11 not supported</h4>
    <p>
      This app uses Angular {{ getVersion(angularVersion, 'M') }} and therefore does not support
      IE11. Please update your browser.
    </p>
  </div>
</div>

<div class="heading">
  <div class="container py-5">
    <h1 class="bold font-curve-large" id="home">Swiss Post</h1>
    <h2 class="font-curve-large">Design System</h2>
    <p class="font-monospace small">
      <button class="btn btn-tertiary version-button" placement="right" [ngbPopover]="versions">
        v{{ getVersion(stylesVersion) }}
      </button>
    </p>
    <p>
      The Swiss Post Design System pattern library for a unified and accessible user experience
      across the web platform.
    </p>
  </div>
</div>

<ng-template #versions>
  <div class="versions font-monospace">
    <p>
      <span>&#64;swisspost/design-system-styles:</span>
      <span>{{ getVersion(stylesVersion) }}</span>
    </p>
    <p>
      <span>&#64;angular/cli:</span>
      <span>{{ getVersion(angularVersion) }}</span>
    </p>
    <p>
      <span>bootstrap:</span>
      <span>{{ getVersion(bootstrapVersion) }}</span>
    </p>
    <p>
      <span>&#64;ng-bootstrap/ng-bootstrap:</span>
      <span>{{ getVersion(ngBootstrapVersion) }}</span>
    </p>
    <p>
      <span>ngx-toastr:</span>
      <span>{{ getVersion(ngxToastrVersion) }}</span>
    </p>
  </div>
</ng-template>

<div class="bg-light py-5">
  <div class="container">
    <div class="alert alert-info" role="alert">
      <h4 class="alert-heading">
        The Swiss Post Design System documentation is migrating towards a new and revised version
        based on storybook.
      </h4>
      <p>
        Since it is still a beta version it is not yet complete, but in general the new storybook
        version gives you a much better overview of the components and their possible states.
      </p>
      <div class="mt-3">
        <a
          href="https://design-system.post.ch"
          rel="noopener"
          target="_blank"
          class="btn btn-primary"
        >
          🚀 Take me to the future!
        </a>
      </div>
    </div>
    <div class="alert alert-primary" role="alert">
      <h4 class="alert-heading">The Swiss Post Design System is now compatible with Angular 16!</h4>
      <p>
        If you have any inquiries about this change, feel free to contact us via our
        <a
          href="https://teams.microsoft.com/l/channel/19%3ae7fa68fb13eb40b4bf4604edea5f4b3e%40thread.tacv2/%25F0%259F%259A%2591%2520Support?groupId=123c7c9e-052a-40e6-98d3-6cc6d46bad0a&tenantId=3ae7c479-0cf1-47f4-8f84-929f364eff67"
          rel="noopener"
          target="_blank"
        >
          Support channel
        </a>
        in Teams.
      </p>
    </div>
    <section>
      <h3>Compatibility</h3>

      <p id="compatibility-desc">List of compatible version numbers with external dependencies.</p>

      <div class="row gx-5 mt-4">
        <div class="col-md-6 col-sm-12 table-responsive">
          <h4>Styles</h4>
          <table aria-describedby="compatibility-desc" class="table caption-top">
            <caption>
              Package &#64;swisspost/design-system-styles
            </caption>
            <thead>
              <tr>
                <th scope="col">Version</th>
                <th scope="col">Bootstrap</th>
                <th scope="col">Angular</th>
                <th scope="col">Ng-Bootstrap</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th scope="col">5.x</th>
                <td>5.0</td>
                <td>14.x</td>
                <td>13.x</td>
              </tr>
              <tr>
                <th scope="col">6.2</th>
                <td>5.2</td>
                <td>15.x</td>
                <td>14.x</td>
              </tr>
              <tr>
                <th scope="col">6.4</th>
                <td>5.3</td>
                <td>16.x</td>
                <td>15.x</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6 col-sm-12 table-responsive">
          <h4>Common Web Frontend</h4>
          <table aria-describedby="compatibility-desc" class="table caption-top">
            <caption>
              Package &#64;ch-post-common/common-web-frontend
            </caption>
            <thead>
              <tr>
                <th scope="col">Version</th>
                <th scope="col">Bootstrap</th>
                <th scope="col">Angular</th>
                <th scope="col">Ng-Bootstrap</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th scope="col">4.x</th>
                <td>4.6</td>
                <td>12.x</td>
                <td>9.x</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <section>
      <h3>Setup for migration instructions</h3>
      <div class="row migration-options mb-huge">
        <div class="col-md-6">
          <fieldset>
            <legend class="font-curve-small bold">What environment is your application for?</legend>
            <div class="form-check">
              <input
                id="migration-intranet"
                type="radio"
                class="form-check-input"
                name="migration-audience"
                [value]="true"
                [(ngModel)]="isMigratingIntranet"
                (ngModelChange)="setLocaleStorage(migrationTypeIntranetKey, isMigratingIntranet)"
              />
              <label for="migration-intranet" class="form-check-label">Intranet application</label>
            </div>
            <div class="form-check mb-0">
              <input
                id="migration-extranet"
                type="radio"
                class="form-check-input"
                name="migration-audience"
                [value]="false"
                [(ngModel)]="isMigratingIntranet"
                (ngModelChange)="setLocaleStorage(migrationTypeIntranetKey, isMigratingIntranet)"
              />
              <label for="migration-extranet" class="form-check-label">
                Extranet/Internet application
              </label>
            </div>
          </fieldset>
        </div>
        <div class="col-md-6">
          <fieldset>
            <legend class="font-curve-small bold">
              What technology is your application built with?
            </legend>
            <div class="form-check">
              <input
                id="migration-angular"
                type="radio"
                class="form-check-input"
                name="migration-technology"
                [value]="true"
                [(ngModel)]="isMigratingAngular"
                (ngModelChange)="setLocaleStorage(migrationTypeAngularKey, isMigratingAngular)"
              />
              <label for="migration-angular" class="form-check-label">Angular application</label>
            </div>
            <div class="form-check mb-0">
              <input
                id="migration-other-technology"
                type="radio"
                class="form-check-input"
                name="migration-technology"
                [value]="false"
                [(ngModel)]="isMigratingAngular"
                (ngModelChange)="setLocaleStorage(migrationTypeAngularKey, isMigratingAngular)"
              />
              <label for="migration-other-technology" class="form-check-label">
                Non-Angular application
              </label>
            </div>
          </fieldset>
        </div>
      </div>
    </section>

    <section id="v5-to-6" class="mb-huge">
      <h3 class="bold mb-4">Migration from v5 to v6</h3>

      <div class="alert alert-info" role="alert">
        <h4 class="alert-heading">
          Be sure to update your application one major Design System version at a time.
        </h4>
        <p>
          If your project is currently using the &#64;******/common-web-frontend package, start by
          <a routerLink="." fragment="v4-to-5">migrating to version 5</a>
          .
        </p>
      </div>

      <ol class="bubble-tea">
        <li>
          <h4 class="h5 bold">Package Update 🩺</h4>
          <ol>
            <li *ngIf="isMigratingAngular">
              <p>
                Use
                <a href="https://update.angular.io/" target="_blank" rel="noopener">
                  https://update.angular.io/
                </a>
                to update Angular to version 16
              </p>
            </li>
            <li>
              <p>
                Update Bootstrap to version
                <a
                  href="https://getbootstrap.com/docs/5.3/migration"
                  target="_blank"
                  rel="noopener"
                >
                  5.3.x
                </a>
                <ng-container *ngIf="isMigratingAngular">
                  and ng-bootstrap to version
                  <a
                    href="https://github.com/ng-bootstrap/ng-bootstrap/blob/master/CHANGELOG.md#1500-2023-05-25"
                    target="_blank"
                    rel="noopener"
                  >
                    15.x.x
                  </a>
                </ng-container>
                :
                <code
                  appCopyToClipboard
                  class="d-block mt-1 p-3"
                  [languages]="['bash']"
                  [highlight]="
                    'npm install bootstrap@5.3' +
                    (isMigratingAngular ? ' @ng-bootstrap/ng-bootstrap@15' : '')
                  "
                ></code>
              </p>
            </li>
            <li>
              <p>
                Update Design System style package to version 6:
                <code
                  appCopyToClipboard
                  class="d-block mt-1 p-3"
                  [languages]="['bash']"
                  highlight="npm install @swisspost/design-system-styles@6"
                ></code>
              </p>
            </li>
          </ol>
        </li>
        <li *ngIf="isMigratingAngular">
          <h4 class="h5 bold">Automatic Migration ⚙️</h4>
          <p>
            If you are migrating an Angular application, you can take advantage of our migration
            schematics.
          </p>
          <ol>
            <li>Commit all the changes you have made so far</li>
            <li>Make sure you are running on a node version >= 16</li>
            <li>
              You should now be able to run the following command to apply all automatic migrations
              to your application:
              <code
                appCopyToClipboard
                class="d-block mt-1 p-3"
                [languages]="['bash']"
                highlight="npm install @swisspost/design-system-migrations
npx ng update @swisspost/design-system-migrations --from=5 --to=6 --migrate-only --allow-dirty
npm uninstall @swisspost/design-system-migrations"
              ></code>
            </li>
          </ol>
        </li>
        <li>
          <h4 class="h5 bold">Component Migration 🤓</h4>
          <p class="info" *ngIf="isMigratingAngular">
            Changes flagged with the ⚙️ symbol should be automatically migrated with our migration
            tool.
          </p>
          <h5 class="h6 bold mt-4">Accessibility</h5>
          <ul>
            <li>
              <h6>
                Stepper
                <span class="change-badge bg-danger">breaking</span>
              </h6>
              <p>
                The markup of the
                <a href="/#/post-samples/stepper">stepper</a>
                component has been changed in order to be more accessible.
              </p>
              <ul>
                <li>
                  <span *ngIf="isMigratingAngular">⚙️</span>
                  The stepper container no longer has an aria-label attribute but contains a hidden
                  header instead
                </li>
                <li>
                  <span *ngIf="isMigratingAngular">⚙️</span>
                  The progress bar is hidden and no longer requires a type attribute
                </li>
                <li>
                  <span *ngIf="isMigratingAngular">⚙️</span>
                  The stepper has a list role
                </li>
                <li>Completed steps require an additional hidden "Completed: " text</li>
                <li>Current and incomplete steps should not be links</li>
              </ul>
            </li>
          </ul>
          <h5 class="h6 bold mt-4">Deprecations</h5>
          <ul>
            <li *ngIf="isMigratingAngular">
              <h6>
                ngbButton ⚙️
                <span class="change-badge bg-danger">breaking</span>
              </h6>
              <p>
                The
                <span class="highlight">ngbButton</span>
                and
                <span class="highlight">ngbButtonLabel</span>
                directives are no longer a part of ng-bootstrap.
                <br />
                The markup and styles have changed and everything now works with CSS Bootstrap
                classes only.
              </p>
              <p class="info">
                See the
                <a routerLink="/bootstrap-samples/button-group">documentation</a>
                for more detailed information.
              </p>
            </li>
            <li>
              <h6>
                Removed variables
                <span class="change-badge bg-danger">breaking</span>
              </h6>
              <p>
                The following Sass variables have been removed because they are not being used
                anymore.
              </p>
              <ul>
                <li><code>$table-head-bg</code></li>
              </ul>
            </li>
          </ul>
        </li>
      </ol>
    </section>

    <section id="v4-to-5" class="mb-huge">
      <h3 class="bold mb-4">Migration from v4 to v5</h3>

      <ol class="bubble-tea">
        <li>
          <h4 class="h5 bold">Clean Start 🧹</h4>
          <p>
            Create a branch and commit all changes to have a clean, reversible starting point. If
            you like to upgrade from a Design System version lower than 4.0.0, it might be necessary
            to upgrade to v4 first.
          </p>
        </li>
        <li>
          <!-- prettier-ignore -->
          <h4 class="h5 bold">Old Package Uninstallation 🗑️</h4>
          <!-- prettier-ignore -->
          <p>Uninstall the old styles <span *ngIf="isMigratingIntranet">and the old intranet header</span> package<span
            *ngIf="isMigratingIntranet">s</span>.</p>
          <code
            appCopyToClipboard
            class="d-block p-3"
            [languages]="['bash']"
            [highlight]="
              'npm uninstall @******/common-web-frontend' +
              (isMigratingIntranet ? ' @******/common-web-frontend-intranet-header' : '')
            "
          ></code>
        </li>
        <li>
          <!-- prettier-ignore -->
          <h4 class="h5 bold">
            New Package Installation 📦
          </h4>
          <code
            appCopyToClipboard
            class="d-block p-3"
            [languages]="['bash']"
            [highlight]="
              'npm install @swisspost/design-system-styles@5' +
              (isMigratingIntranet ? ' @swisspost/design-system-intranet-header@3' : '')
            "
          ></code>
        </li>
        <li>
          <h4 class="h5 bold">Package Update 🩺</h4>
          <ol *ngIf="isMigratingAngular">
            <li>
              If your Angular version is lower than 13, use
              <a href="https://update.angular.io/" target="_blank" rel="noopener">
                https://update.angular.io/
              </a>
              to update Angular step by step to version 13.
            </li>
            <li>
              <ng-container [ngTemplateOutlet]="bootstrapInstructions"></ng-container>
            </li>
          </ol>

          <p *ngIf="!isMigratingAngular">
            <ng-container [ngTemplateOutlet]="bootstrapInstructions"></ng-container>
          </p>

          <ng-template #bootstrapInstructions>
            Update Bootstrap to version
            <a href="https://getbootstrap.com/docs/5.1/migration" target="_blank" rel="noopener">
              5.1.x
            </a>
            <ng-container *ngIf="isMigratingAngular">
              and ng-bootstrap to version
              <a
                href="https://github.com/ng-bootstrap/ng-bootstrap/blob/master/CHANGELOG.md#1400-2022-12-07"
                target="_blank"
                rel="noopener"
              >
                12.x.x
              </a>
            </ng-container>
            :
            <code
              appCopyToClipboard
              class="d-block mt-1 p-3"
              [languages]="['bash']"
              [highlight]="
                'npm install bootstrap@5.1' +
                (isMigratingAngular ? ' @ng-bootstrap/ng-bootstrap@12' : '')
              "
            ></code>
          </ng-template>
        </li>
        <li *ngIf="isMigratingAngular">
          <h4 class="h5 bold">Automatic Migration ⚙️</h4>
          <p>
            If you are migrating an Angular application, you can take advantage of our migration
            schematics.
          </p>
          <ol>
            <li>Commit all the changes you have made so far</li>
            <li>Make sure you are running on a node version >= 16</li>
            <li>
              You should now be able to run the following command to apply all automatic migrations
              to your application:
              <code
                appCopyToClipboard
                class="d-block mt-1 p-3"
                [languages]="['bash']"
                highlight="npm install @swisspost/design-system-migrations
npx ng update @swisspost/design-system-migrations --from=4 --to=5 --migrate-only --allow-dirty
npm uninstall @swisspost/design-system-migrations"
              ></code>
            </li>
          </ol>
        </li>
        <li>
          <h4 class="h5 bold">Component Migration 🤓</h4>
          <p class="info" *ngIf="isMigratingAngular">
            Changes flagged with the ⚙️ symbol should be automatically migrated with our migration
            tool.
          </p>

          <h5 class="h6 bold">Deprecations</h5>
          <ul class="mt-2 mb-3">
            <li>
              <p>
                <strong>Dropped jQuery dependency</strong>
                <br />
                <span class="info">
                  Replace all jQuery components with the given one or integrate jQuery by your own
                  (not recommended).
                </span>
              </p>
            </li>
            <li>
              <p>
                The
                <span class="highlight">Custom-Select</span>
                component has been marked as deprecated.
                <span class="info">
                  For the moment there is only the alternative to use the
                  <span class="highlight">Form-Select</span>
                  component instead. An alternative is being implemented.
                </span>
              </p>
            </li>
          </ul>

          <h5 class="h6 bold">Migrations list</h5>
          <p>
            Some changes cannot be migrated automatically.
            <br />
            Check the list of changes below and apply the transformations manually if your
            application is affected.
          </p>

          <!-- Post migration table -->
          <div
            ngbAccordion
            [destroyOnHide]="false"
            (hidden)="migrationAccordionHidden($event)"
            (shown)="migrationAccordionShown($event)"
          >
            <div
              ngbAccordionItem
              #general="ngbAccordionItem"
              [collapsed]="!migrationAccordionActiveIds.includes(general.id)"
            >
              <h2 ngbAccordionHeader class="custom-header justify-content-between">
                <button ngbAccordionButton class="accordion-button">
                  General
                  <small>
                    {{ getMigrationAccordionGroupedCheckboxesChecked('general') }}
                  </small>
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <!-- General Files -->
                  <div>
                    <h5 class="mt-4 h6 bold">Paths &amp; Files</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="generalnaming_cwfpackagename"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.general.naming_cwfpackagename
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalnaming_cwfpackagename">
                            <span class="change-badge bg-danger">breaking</span> Renamed <span
                            class="highlight">packages</span>.
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>&#64;******/common-web-frontend</code> became <code
                                appCopyToClipboard>&#64;swisspost/design-system-styles</code></li>
                              <li><code appCopyToClipboard>&#64;******/common-web-frontend-demo</code> became <code
                                appCopyToClipboard>&#64;swisspost/design-system-demo</code></li>
                              <li><code appCopyToClipboard>&#64;******/common-web-frontend-intranet-header</code> became <code
                                appCopyToClipboard>&#64;swisspost/design-system-intranet-header</code></li>
                            </ul>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="generalnaming_entryfiles"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.general.naming_entryfiles
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <label for="generalnaming_entryfiles" class="form-check-label">
                            <span class="change-badge bg-danger">breaking</span>
                            Renamed entry files.
                            <br />

                            <ul class="mt-2">
                              <li class="mb-2">
                                <strong>Default (index)</strong>
                                for default Post styles:
                                <br />
                                <code appCopyToClipboard>
                                  &#64;use "&#64;*****/common-web-frontend/post.scss";
                                </code>
                                to
                                <code appCopyToClipboard>
                                  &#64;use "&#64;swisspost/design-system-styles";
                                </code>
                              </li>
                              <li class="mb-2">
                                <strong>Intranet</strong>
                                for default Post styles + intranet header styles:
                                <br />
                                <code appCopyToClipboard>
                                  &#64;use "&#64;*****/common-web-frontend/post-intranet";
                                </code>
                                to
                                <code appCopyToClipboard>
                                  &#64;use "&#64;swisspost/design-system-styles/intranet.scss";
                                </code>
                              </li>
                              <li class="mb-2">
                                <strong>Basics</strong>
                                for resets, typography, utilities and grid only (use this for small
                                pages with lots of custom styles):
                                <br />
                                <code appCopyToClipboard>
                                  &#64;use "&#64;*****/common-web-frontend/post-basics.scss";
                                </code>
                                to
                                <code appCopyToClipboard>
                                  &#64;use "&#64;swisspost/design-system-styles/basics.scss";
                                </code>
                              </li>
                              <li class="mb-2">
                                <strong>Core</strong>
                                for functions, mixins, variables and placeholders:
                                <br />
                                <code appCopyToClipboard>
                                  &#64;use "&#64;*****/common-web-frontend/_cwf.scss";
                                </code>
                                to
                                <code appCopyToClipboard>
                                  &#64;use "&#64;swisspost/design-system-styles/core.scss";
                                </code>
                              </li>
                            </ul>

                            <span class="info">
                              Read more about Sass modules and the &#64;use rule in the Sass
                              <a href="https://sass-lang.com/documentation/at-rules/use">
                                &#64;use documentation
                              </a>
                              .
                            </span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="generalnaming_cwfname"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="migrationAccordionGroupedCheckboxes.general.naming_cwfname"
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalnaming_cwfname">
                            <span class="change-badge bg-danger">breaking</span> Renamed <span class="highlight">src/cwf.scss</span>
                            file to <span class="highlight">src/core.scss</span>.<br>
                            <span>We recommend to import the <span
                              class="highlight">core.scss</span> file as follows: <code appCopyToClipboard>&#64;use '&#64;swisspost/design-system-styles/core.scss' as post;</code></span><br>
                            <span>In any case, do not forget to switch the namespace in your files from <span
                              class="highlight">cwf.</span> to either <span class="highlight">core.</span> or <span
                              class="highlight">post.</span> depending on the import.</span>
                            <span class="info">Read more about namespaces in the <a
                              href="https://sass-lang.com/blog/the-module-system-is-launched">Sass module system intro</a>.</span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="generalnaming_cwflicense"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.general.naming_cwflicense
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalnaming_cwflicense">
                            <span class="change-badge bg-danger">breaking</span> Removed <span class="highlight">src/lic/cwf-license.scss</span>
                            file.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="generalnaming_options"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="migrationAccordionGroupedCheckboxes.general.naming_options"
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalnaming_options">
                            <span class="change-badge bg-danger">breaking</span> Renamed <span class="highlight">src/themes/bootstrap/options.scss</span>
                            file to <span class="highlight">src/variables/features.scss</span>.
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <!-- General Variables -->
                  <div>
                    <h5 class="mt-4 h6 bold">Variables</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="generalvariables_isolatecomponents"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.general
                                .variables_isolatecomponents
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalvariables_isolatecomponents">
                            <span class="change-badge bg-danger">breaking</span> Removed <code appCopyToClipboard>$isolate-components</code>
                            variable.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="generalvariables_fontsizemap"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.general.variables_fontsizemap
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalvariables_fontsizemap">
                            <span class="change-badge bg-danger">breaking</span> Removed <code appCopyToClipboard>$font-size-map</code>
                            variable.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="generalvariables_lineheightrg"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.general.variables_lineheightrg
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalvariables_lineheightrg">
                            <span class="change-badge bg-danger">breaking</span> Removed <code appCopyToClipboard>$line-height-rg</code>
                            variable.<br>
                            <span class="highlight">Line-heights</span> are now relative to the font-size.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="generalvariables_floatinglabel"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.general.variables_floatinglabel
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalvariables_floatinglabel">
                            <span class="change-badge bg-danger">breaking</span> Removed <span class="highlight">floating-label</span>
                            variables.<br>
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>$form-floating-label-padding-t</code></li>
                              <li><code appCopyToClipboard>$form-floating-label-padding-b</code></li>
                              <li><code appCopyToClipboard>$form-floating-textarea-line-height</code></li>
                              <li><code appCopyToClipboard>$form-floating-textarea-padding-t</code></li>
                              <li><code appCopyToClipboard>$form-floating-textarea-padding-b</code></li>
                            </ul>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="generalvariables_colorsremoved"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.general.variables_colorsremoved
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalvariables_colorsremoved">
                            <span class="change-badge bg-danger">breaking</span> Removed <span
                            class="highlight">color</span> variables.<br>
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>$white-alpha-10</code>, <code appCopyToClipboard>$white-alpha-20</code>,
                                <code appCopyToClipboard>$white-alpha-40</code>, <code
                                  appCopyToClipboard>$white-alpha-60</code>, <code
                                  appCopyToClipboard>$white-alpha-80</code> and <code
                                  appCopyToClipboard>$white-alphas</code></li>
                              <li><code appCopyToClipboard>$black-alpha-10</code>, <code appCopyToClipboard>$black-alpha-20</code>,
                                <code appCopyToClipboard>$black-alpha-40</code>, <code
                                  appCopyToClipboard>$black-alpha-60</code>, <code
                                  appCopyToClipboard>$black-alpha-80</code> and <code
                                  appCopyToClipboard>$black-alphas</code></li>
                              <li><code appCopyToClipboard>$facebook</code>, <code appCopyToClipboard>$instagram</code>,
                                <code appCopyToClipboard>$youtube</code>, <code appCopyToClipboard>$snapchat</code>, <code
                                  appCopyToClipboard>$twitter</code>, <code appCopyToClipboard>$xing</code>, <code
                                  appCopyToClipboard>$linkedin</code>, <code appCopyToClipboard>$kununu</code> and <code
                                  appCopyToClipboard>$email</code></li>
                              <li><code appCopyToClipboard>$primary-color</code></li>
                              <li><code appCopyToClipboard>$secondary-color</code></li>
                              <li><code appCopyToClipboard>$gray-50</code></li>
                              <li><code appCopyToClipboard>$highlight-colors</code></li>
                              <li><code appCopyToClipboard>$brand-colors</code></li>
                              <li><code appCopyToClipboard>$icon-colors</code></li>
                              <li><code appCopyToClipboard>$theme-color-interval</code></li>
                            </ul>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="generalvariables_colorsrenamed"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.general.variables_colorsrenamed
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalvariables_colorsrenamed">
                            <span class="change-badge bg-danger">breaking</span> Renamed <span
                            class="highlight">color</span> variables.
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>$gray-pampas</code> became <code appCopyToClipboard>$gray-background-light</code>
                              </li>
                              <li><code appCopyToClipboard>$gray-cararra</code> became <code appCopyToClipboard>$gray-background</code>
                              </li>
                              <li><code appCopyToClipboard>$colors</code> became <code appCopyToClipboard>$background-colors</code>
                              </li>
                              <li><code appCopyToClipboard>$icon-colors</code> became <code appCopyToClipboard>$contextual-colors</code>
                              </li>
                              <li><code appCopyToClipboard>$yiq-contrasted-threshold</code> became <code
                                appCopyToClipboard>$min-contrast-ratio</code></li>
                              <li><code appCopyToClipboard>$yiq-text-dark</code> became <code appCopyToClipboard>$color-contrast-dark</code>
                              </li>
                              <li><code appCopyToClipboard>$yiq-text-light</code> became <code appCopyToClipboard>$color-contrast-light</code>
                              </li>
                            </ul>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="generalvariables_lineheigts"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.general.variables_lineheigts
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalvariables_lineheigts">
                            Dropped usage of <span class="highlight">line-height</span> variables.<br>
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>$line-height-sm</code></li>
                              <li><code appCopyToClipboard>$line-height-lg</code></li>
                            </ul>
                            <p class="info">The variables remain available because of Bootstrap.</p>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="generalvariables_lineheightlighter"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.general
                                .variables_lineheightlighter
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalvariables_lineheightlighter">
                            Dropped usage of <code appCopyToClipboard>$font-weight-lighter</code> variable.<br>
                            Also removed corresponding <code appCopyToClipboard>&#64;font-face</code> rule.<br>
                            <p class="info">The variable remains available because of Bootstrap.</p>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="generalvariables_headingfontsizes"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.general.variables_headingfontsizes
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalvariables_headingfontsizes">
                            Dropped usage of <span class="highlight">font-size</span> variables.<br>
                            Headings now have responsive font-sizes.<br>
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>$h1-font-size</code></li>
                              <li><code appCopyToClipboard>$h2-font-size</code></li>
                              <li><code appCopyToClipboard>$h3-font-size</code></li>
                              <li><code appCopyToClipboard>$h4-font-size</code></li>
                              <li><code appCopyToClipboard>$h5-font-size</code></li>
                              <li><code appCopyToClipboard>$h6-font-size</code></li>
                            </ul>
                            <p class="info">The variables remain available because of Bootstrap.</p>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <!-- General Mixins -->
                  <div>
                    <h5 class="mt-4 h6 bold">Mixins</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="generalmixins_fontsizelineheight"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.general.mixins_fontsizelineheight
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalmixins_fontsizelineheight">
                            <span class="change-badge bg-danger">breaking</span> Removed <span
                            class="highlight">font-size</span> and <span class="highlight">line-height</span> mixins:<br>
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>font-size-calc()</code></li>
                              <li><code appCopyToClipboard>font-size-and-lineheight()</code></li>
                              <li><code appCopyToClipboard>font-line-height()</code></li>
                            </ul>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <!-- General Classes -->
                  <div>
                    <h5 class="mt-4 h6 bold">Classes</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="generalclasses_bgopacity"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.general.classes_bgopacity
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalclasses_bgopacity">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Removed <code appCopyToClipboard>.bg-[colorname]-opacity-[opacityvalue]</code>
                            classes.<br>
                            Use <code appCopyToClipboard>.bg-[colorname]</code> together with <code appCopyToClipboard>var(--post-bg-opacity)</code>
                            instead.<br>
                            <p class="info">Replace [colorname] for example with "primary", "warning", etc.</p>
                            <p class="info">Replace [opacityvalue] for example with "0", "0.5" or "1".</p>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="generalclasses_secondary"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.general.classes_secondary
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalclasses_secondary">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Removed <span
                            class="highlight">secondary</span> classes.<br>
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>.bg-secondary</code></li>
                              <li><code appCopyToClipboard>.border-secondary</code></li>
                              <li><code appCopyToClipboard>.text-secondary</code></li>
                            </ul>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="generalclasses_rtlmode"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.general.classes_rtlmode
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalclasses_rtlmode">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Updated spacing and alignment helper classes,
                            for better support of <span class="highlight">rtl-mode</span>.
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>.ml-[size]</code> became <code
                                appCopyToClipboard>.ms-[size]</code></li>
                              <li><code appCopyToClipboard>.ml-[breakpoint]-[size]</code> became <code appCopyToClipboard>.ms-[breakpoint]-[size]</code>
                              </li>
                              <li><code appCopyToClipboard>.mr-[size]</code> became <code
                                appCopyToClipboard>.me-[size]</code></li>
                              <li><code appCopyToClipboard>.mr-[breakpoint]-[size]</code> became <code appCopyToClipboard>.me-[breakpoint]-[size]</code>
                              </li>
                              <hr>
                              <li><code appCopyToClipboard>.pl-[size]</code> became <code
                                appCopyToClipboard>.ps-[size]</code></li>
                              <li><code appCopyToClipboard>.pl-[breakpoint]-[size]</code> became <code appCopyToClipboard>.ps-[breakpoint]-[size]</code>
                              </li>
                              <li><code appCopyToClipboard>.pr-[size]</code> became <code
                                appCopyToClipboard>.pe-[size]</code></li>
                              <li><code appCopyToClipboard>.pr-[breakpoint]-[size]</code> became <code appCopyToClipboard>.pe-[breakpoint]-[size]</code>
                              </li>
                              <hr>
                              <li><code appCopyToClipboard>.float-left</code> became <code
                                appCopyToClipboard>.float-start</code></li>
                              <li><code appCopyToClipboard>.float-[breakpoint]-left</code> became <code
                                appCopyToClipboard>.float-[breakpoint]-start</code></li>
                              <li><code appCopyToClipboard>.float-right</code> became <code
                                appCopyToClipboard>.float-end</code></li>
                              <li><code appCopyToClipboard>.float-[breakpoint]-right</code> became <code
                                appCopyToClipboard>.float-[breakpoint]-end</code></li>
                              <hr>
                              <li><code appCopyToClipboard>.text-left</code> became <code
                                appCopyToClipboard>.text-start</code></li>
                              <li><code appCopyToClipboard>.text-[breakpoint]-left</code> became <code appCopyToClipboard>.text-[breakpoint]-start</code>
                              </li>
                              <li><code appCopyToClipboard>.text-right</code> became <code
                                appCopyToClipboard>.text-end</code></li>
                              <li><code appCopyToClipboard>.text-[breakpoint]-right</code> became <code
                                appCopyToClipboard>.text-[breakpoint]-end</code></li>
                            </ul>
                            <p class="info">Replace [size] for example with "hair", "line", ... "bigger-giant" or "0",
                              "1", ... "5".</p>
                            <p class="info">Replace [breakpoint] for example with "xs", "sm", ... "xxl".</p>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="generalclasses_sronly"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="migrationAccordionGroupedCheckboxes.general.classes_sronly"
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="generalclasses_sronly">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Updated <span class="highlight">sr-only</span>
                            classes.
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>.sr-only</code> became <code appCopyToClipboard>.visually-hidden</code>
                              </li>
                              <li><code appCopyToClipboard>.sr-only-focusable</code> became <code appCopyToClipboard>.visually-hidden-focusable</code>
                              </li>
                            </ul>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div
              ngbAccordionItem
              #bootstrap="ngbAccordionItem"
              [collapsed]="!migrationAccordionActiveIds.includes(bootstrap.id)"
            >
              <h2 ngbAccordionHeader class="custom-header justify-content-between">
                <button ngbAccordionButton class="accordion-button">
                  Bootstrap
                  <small>
                    {{ getMigrationAccordionGroupedCheckboxesChecked('bootstrap') }}
                  </small>
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <!-- Bootstrap Alert / Notifications -->
                  <div>
                    <h5 class="h6 bold">Alert / Notification</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapalerts_closebuttoncontent"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap
                                .alerts_closebuttoncontent
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapalerts_closebuttoncontent">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Removed the <code appCopyToClipboard>&lt;span
                            aria-hidden="true"&gt;&lt;/span&gt;</code> element contained in the <span class="highlight">close-button</span>
                            elements.
                            <span class="info">The close icon will be rendered anyway.</span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapalerts_closebuttonclass"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.alerts_closebuttonclass
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapalerts_closebuttonclass">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Renamed class <code
                            appCopyToClipboard>.close</code> to <code appCopyToClipboard>.btn-close</code>.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/bootstrap-samples/alert">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- Bootstrap Badge -->
                  <div>
                    <h5 class="mt-4 h6 bold">Badge</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapbadges_classes"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.badges_classes
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapbadges_classes">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Renamed <span class="highlight">badge</span>
                            classes.<br>
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>.badge-pill</code> became <code appCopyToClipboard>.rounded-pill</code>
                              </li>
                              <li><code appCopyToClipboard>.badge-[colorname]</code> became <code appCopyToClipboard>.bg-[colorname]</code>
                              </li>
                              <li><code appCopyToClipboard>.badge-outline-[colorname]</code> became <code
                                appCopyToClipboard>.border-[colorname]</code></li>
                              <li><code appCopyToClipboard>.badge-gray-cararra</code> became <code appCopyToClipboard>.bg-light</code>
                              </li>
                              <li><code appCopyToClipboard>.badge-outline-gray-carrara-thick</code> became <code
                                appCopyToClipboard>.border-light.border-2</code></li>
                            </ul>
                            <p class="info">Replace [colorname] for example with "primary", "warning", etc.</p>
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/bootstrap-samples/badge">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- Bootstrap Background -->
                  <div>
                    <h5 class="mt-4 h6 bold">Background</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapbackgrounds_textcolor"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.backgrounds_textcolor
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapbackgrounds_textcolor">
                            <span *ngIf="isMigratingAngular">⚙️</span> Dropped usage of <code appCopyToClipboard>.text-auto</code>
                            class on elements with background classes such as <code
                            appCopyToClipboard>.bg-[colorname]</code>.
                            The <span class="highlight">text-color</span> is now automatically defined with <code
                            appCopyToClipboard>var(--post-contrast-color)</code>, therefore an explicit color style is no
                            longer needed.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/bootstrap-samples/background">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- Bootstrap Blockquote -->
                  <div>
                    <h5 class="mt-4 h6 bold">Blockquote</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapblockquotes_footerstructure"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap
                                .blockquotes_footerstructure
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapblockquotes_footerstructure">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Refactored <span
                            class="highlight">blockquote</span> with footer.<br>
                            Such blockquotes should now be nested in a <code appCopyToClipboard>figure</code> tag.
                            In addition, the tag <code appCopyToClipboard>footer.blockquote-footer</code> became <code
                            appCopyToClipboard>figcaption.blockquote-footer</code>.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapblockquotes_qclass"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.blockquotes_qclass
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapblockquotes_qclass">
                            <span *ngIf="isMigratingAngular">⚙️ </span>Dropped usage of <code
                            appCopyToClipboard>.mb-0</code> class, on <span class="highlight">p</span> tags within <span
                            class="highlight">blockquote</span> elements.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/bootstrap-samples/blockquotes">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- Bootstrap Button -->
                  <div>
                    <h5 class="mt-4 h6 bold">Button</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapbuttons_outline"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.buttons_outline
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapbuttons_outline">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Removed <span class="highlight">button</span>
                            classes <code appCopyToClipboard>.btn-outline-[colorname]</code>.<br>
                            All the <code appCopyToClipboard>.btn-outline-[colorname]</code> classes will be replaced by
                            <code appCopyToClipboard>.btn-secondary</code>.
                            <p class="info">Replace [colorname] for example with "primary", "warning", etc.</p>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapbuttons_borderradius"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.buttons_borderradius
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapbuttons_borderradius">
                            <span class="change-badge bg-danger">breaking</span> Removed <span
                            class="highlight">button</span> variables.<br>
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>$btn-border-radius-rg</code></li>
                              <li><code appCopyToClipboard>$btn-border-radius-map</code></li>
                            </ul>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapbuttons_borderradius2"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.buttons_borderradius2
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapbuttons_borderradius2">
                            Dropped the usage of <span class="highlight">button</span> variables.
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>$btn-border-radius-sm</code></li>
                              <li><code appCopyToClipboard>$btn-border-radius-lg</code></li>
                            </ul>
                            <p class="info">The variables remain available because of Bootstrap.</p>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapbuttons_invertedclass"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.buttons_invertedclass
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapbuttons_invertedclass">
                            <span *ngIf="isMigratingAngular">⚙️</span> Dropped the usage of <code appCopyToClipboard>.btn-inverted</code>
                            class.<br>
                            <span class="highlight">Text-colors</span> are now automatically handled.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapbuttons_iconclass"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.buttons_iconclass
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapbuttons_iconclass">
                            <span *ngIf="isMigratingAngular">⚙️</span> Dropped the usage of <code appCopyToClipboard>.btn-icon</code>
                            class for buttons with <span class="highlight">icon</span> and visible <span
                            class="highlight">text</span>.<br>
                            <span class="highlight">Icon-only</span> buttons still need this class!
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/bootstrap-samples/buttons">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- Bootstrap Button Close -->
                  <div>
                    <h5 class="mt-4 h6 bold">Button Close</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapbuttonclose_content"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.buttonclose_content
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapbuttonclose_content">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Removed the <code appCopyToClipboard>&lt;span
                            aria-hidden="true"&gt;&lt;/span&gt;</code> element contained in the <span class="highlight">close-button</span>
                            elements.
                            <span class="info">The close icon will be rendered anyway.</span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapbuttonclose_class"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.buttonclose_class
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapbuttonclose_class">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Renamed class <code
                            appCopyToClipboard>.close</code> to <code appCopyToClipboard>.btn-close</code>.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapbuttonclose_buttonclasses"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap
                                .buttonclose_buttonclasses
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapbuttonclose_buttonclasses">
                            <span *ngIf="isMigratingAngular">⚙️</span> Dropped the usage of <code
                            appCopyToClipboard>.btn</code> and <code appCopyToClipboard>.btn-icon</code> classes.<br>
                            <span class="info">Unless you want to have a button-like appearance, you should not use these classes on a <span
                              class="highlight">close-button</span> element.</span>
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/bootstrap-samples/card">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- Bootstrap Cards -->
                  <div>
                    <h5 class="mt-4 h6 bold">Card</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapcards_classes"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.cards_classes
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapcards_classes">
                            <span class="change-badge bg-danger">breaking</span> Removed <span
                            class="highlight">card</span> classes <code appCopyToClipboard>.card-deck</code> and <code
                            appCopyToClipboard>.card-columns</code>.<br>
                            Use the <span class="highlight">grid-system</span> instead.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/bootstrap-samples/card">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- Bootstrap Forms -->
                  <div>
                    <h5 class="mt-4 h6 bold">Forms</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="boostrapforms_formlabelclass"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.forms_formlabelclass
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="boostrapforms_formlabelclass">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Required class <code appCopyToClipboard>.form-label</code>
                            on <span class="highlight">form-label</span> elements.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapforms_formgroup"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.forms_formgroup
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapforms_formgroup">
                            <span *ngIf="isMigratingAngular">⚙️</span> Dropped usage of <code appCopyToClipboard>.form-group</code>
                            class.<br>
                            Use <span class="highlight">utility-class</span>&nbsp;<code
                            appCopyToClipboard>.mb-regular</code> instead.<br>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapforms_formtext"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.forms_formtext
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapforms_formtext">
                            <span *ngIf="isMigratingAngular">⚙️</span> Dropped usage of <code
                            appCopyToClipboard>.small</code> and <code appCopyToClipboard>.text-muted</code> classes on
                            <code appCopyToClipboard>.form-text</code> elements.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/bootstrap-samples/forms">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- Bootstrap Form-Controls -->
                  <div>
                    <h5 class="mt-4 h6 bold">From Control</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="boostrapformcontrols_formfloatingwrapper"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap
                                .formcontrols_formfloatingwrapper
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="boostrapformcontrols_formfloatingwrapper">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Required a <code appCopyToClipboard>&lt;div
                            class="form-floating"&gt;...&lt;/div&gt;</code> wrapper around <span class="highlight">floating-label</span>
                            elements.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="boostrapformcontrols_formfloatingcontrollgclass"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap
                                .formcontrols_formfloatingcontrollgclass
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="boostrapformcontrols_formfloatingcontrollgclass">
                            <span *ngIf="isMigratingAngular">⚙️</span> Dropped usage of <code appCopyToClipboard>.form-control-lg</code>
                            class on <span class="highlight">floating-label</span> elements.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/bootstrap-samples/form-control">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- Bootstrap Form-Select -->
                  <div>
                    <h5 class="mt-4 h6 bold">From Select</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="boostrapformselects_formfloatingwrapper"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap
                                .formselects_formfloatingwrapper
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="boostrapformselects_formfloatingwrapper">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Required a <code appCopyToClipboard>&lt;div
                            class="form-floating"&gt;...&lt;/div&gt;</code> wrapper around <span class="highlight">floating-label</span>
                            elements.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapformselects_classes"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.formselects_classes
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapformselects_classes">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Renamed <span class="highlight">select</span>,
                            <span class="highlight">multi-select</span> and <span class="highlight">custom-select</span>
                            classes.
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>.form-control</code> became <code appCopyToClipboard>.form-select</code>
                              </li>
                              <li><code appCopyToClipboard>.form-control-lg</code> became <code appCopyToClipboard>.form-select-lg</code>
                              </li>
                              <li><code appCopyToClipboard>.form-control-rg</code> became <code appCopyToClipboard>.form-select-rg</code>
                              </li>
                              <li><code appCopyToClipboard>.form-control-sm</code> became <code appCopyToClipboard>.form-select-sm</code>
                              </li>
                              <li><code appCopyToClipboard>.custom-select</code> became <code appCopyToClipboard>.form-select</code>
                              </li>
                              <li><code appCopyToClipboard>.custom-select-lg</code> became <code appCopyToClipboard>.form-select-lg</code>
                              </li>
                              <li><code appCopyToClipboard>.custom-select-rg</code> became <code appCopyToClipboard>.form-select-rg</code>
                              </li>
                              <li><code appCopyToClipboard>.custom-select-sm</code> became <code appCopyToClipboard>.form-select-sm</code>
                              </li>
                            </ul>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapformselects_formfloatingselectlgclass"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap
                                .formselects_formfloatingselectlgclass
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapformselects_formfloatingselectlgclass">
                            <span *ngIf="isMigratingAngular">⚙️</span> Dropped usage of <code appCopyToClipboard>.form-control-lg</code>
                            class on <span class="highlight">floating-label</span> elements.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/bootstrap-samples/form-select">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- Bootstrap Form-Textarea -->
                  <div>
                    <h5 class="mt-4 h6 bold">From Textarea</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="boostrapformtextareas_formfloatingwrapper"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap
                                .formtextareas_formfloatingwrapper
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="boostrapformtextareas_formfloatingwrapper">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Required a <code appCopyToClipboard>&lt;div
                            class="form-floating"&gt;...&lt;/div&gt;</code> wrapper around <span class="highlight">floating-label</span>
                            elements.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/bootstrap-samples/form-textarea">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- Bootstrap Form-File -->
                  <div>
                    <h5 class="mt-4 h6 bold">Form File</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="boostrapformfiles_formlabelclass"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.formfiles_formlabelclass
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="boostrapformfiles_formlabelclass">
                            <span class="change-badge bg-danger">breaking</span> Required class <code appCopyToClipboard>.form-label</code>
                            on <span class="highlight">form-label</span> elements.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/bootstrap-samples/form-file">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- Bootstrap Form-Checkbox -->
                  <div>
                    <h5 class="mt-4 h6 bold">From Checkbox</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapformcheckboxes_classes"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.formcheckboxes_classes
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapformcheckboxes_classes">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Renamed <span class="highlight">control</span>
                            classes.
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>.custom-control.custom-checkbox</code> became <code
                                appCopyToClipboard>.form-check</code></li>
                              <li><code appCopyToClipboard>.custom-control-input</code> became <code appCopyToClipboard>.form-check-input</code>
                              </li>
                              <li><code appCopyToClipboard>.custom-control-label</code> became <code appCopyToClipboard>.form-check-label</code>
                              </li>
                              <li><code appCopyToClipboard>.custom-control-inline</code> became <code appCopyToClipboard>.form-check-inline</code>
                              </li>
                            </ul>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapformcheckboxes_validationclasses"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap
                                .formcheckboxes_validationclasses
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapformcheckboxes_validationclasses">
                            <span class="change-badge bg-danger">breaking</span> Shifted <span class="highlight">validation</span>
                            classes.<br>
                            The classes <code appCopyToClipboard>.is-valid</code> and <code
                            appCopyToClipboard>.is-invalid</code> now belong on the <code appCopyToClipboard>.form-check-input</code>
                            element. The <code appCopyToClipboard>.form-check</code> element does no longer require the
                            <span class="highlight">validation</span> classes.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapformcheckboxes_validationfeedbackclasses"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap
                                .formcheckboxes_validationfeedbackclasses
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapformcheckboxes_validationfeedbackclasses">
                            <span class="change-badge bg-danger">breaking</span> Shifted <span class="highlight">validation-feedback</span>
                            elements.<br>
                            The elements <code appCopyToClipboard>.valid-feedback</code> and <code appCopyToClipboard>.invalid-feedback</code>
                            now belong inside of the <code appCopyToClipboard>.form-check</code> element.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/bootstrap-samples/form-check">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- Bootstrap Form-Radio -->
                  <div>
                    <h5 class="mt-4 h6 bold">From Radio</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapformradios_classes"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.formradios_classes
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapformradios_classes">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Renamed <span class="highlight">control</span>
                            classes.
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>.custom-control.custom-radio</code> became <code
                                appCopyToClipboard>.form-check</code></li>
                              <li><code appCopyToClipboard>.custom-control-input</code> became <code appCopyToClipboard>.form-check-input</code>
                              </li>
                              <li><code appCopyToClipboard>.custom-control-label</code> became <code appCopyToClipboard>.form-check-label</code>
                              </li>
                              <li><code appCopyToClipboard>.custom-control-inline</code> became <code appCopyToClipboard>.form-check-inline</code>
                              </li>
                            </ul>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapformradios_validationclasses"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap
                                .formradios_validationclasses
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapformradios_validationclasses">
                            <span class="change-badge bg-danger">breaking</span> Shifted <span class="highlight">validation</span>
                            classes.<br>
                            The classes <code appCopyToClipboard>.is-valid</code> and <code
                            appCopyToClipboard>.is-invalid</code> now belong on the <code appCopyToClipboard>.form-check-input</code>
                            element. The <code appCopyToClipboard>.form-check</code> element does no longer require the
                            <span class="highlight">validation</span> classes.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapformradios_validationfeedbackclasses"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap
                                .formradios_validationfeedbackclasses
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapformradios_validationfeedbackclasses">
                            <span class="change-badge bg-danger">breaking</span> Shifted <span class="highlight">validation-feedback</span>
                            elements.<br>
                            The elements <code appCopyToClipboard>.valid-feedback</code> and <code appCopyToClipboard>.invalid-feedback</code>
                            now belong inside of the <code appCopyToClipboard>.form-check</code> element.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/bootstrap-samples/form-radio">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- Post Switch -->
                  <div>
                    <h5 class="mt-4 h6 bold">Form Switch</h5>

                    <p>
                      The
                      <span class="highlight">switch</span>
                      component has been moved to the
                      <strong>Bootstrap</strong>
                      section since Bootstrap version 5.x now provides such a component.
                      <span class="info">
                        If you import this component manually, you need to update to the new file
                        path.
                      </span>
                    </p>

                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapformswitches_classes"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap.formswitches_classes
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapformswitches_classes">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Refactored <span
                            class="highlight">switch</span> classes.<br>
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>.switch</code> became <code appCopyToClipboard>.form-check.form-switch</code>
                              </li>
                              <li><code appCopyToClipboard>.switch input.switch</code> became <code appCopyToClipboard>input.form-check-input</code>
                              </li>
                            </ul>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapformswitches_labelclasses"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap
                                .formswitches_labelclasses
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapformswitches_labelclasses">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Refactored <span
                            class="highlight">switch-label</span>.<br>
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>div.switch label</code> (before <code appCopyToClipboard>label.switch-toggler</code>)
                                became <code appCopyToClipboard>label.form-check-label.order-first</code></li>
                              <li><code appCopyToClipboard>div.switch label.switch-toggler</code> has been removed</li>
                              <li><code appCopyToClipboard>div.switch label</code> (after <code appCopyToClipboard>label.switch-toggler</code>)
                                became <code appCopyToClipboard>label.form-check-label</code></li>
                            </ul>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapformswitches_validationclasses"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap
                                .formswitches_validationclasses
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapformswitches_validationclasses">
                            <span class="change-badge bg-danger">breaking</span> Shifted <span class="highlight">validation</span>
                            classes.<br>
                            The classes <code appCopyToClipboard>.is-valid</code> and <code
                            appCopyToClipboard>.is-invalid</code> now belong on the <code appCopyToClipboard>.form-check-input</code>
                            element.<br>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="bootstrapformswitches_validationfeedbackclasses"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.bootstrap
                                .formswitches_validationfeedbackclasses
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="bootstrapformswitches_validationfeedbackclasses">
                            <span class="change-badge bg-danger">breaking</span> Shifted <span class="highlight">validation-feedback</span>
                            elements.<br>
                            The elements <code appCopyToClipboard>.valid-feedback</code> and <code appCopyToClipboard>.invalid-feedback</code>
                            now belong inside of the <code appCopyToClipboard>.form-check</code> element.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/bootstrap-samples/form-switch">documentation</a>
                      for more detailed information.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              *ngIf="isMigratingAngular"
              ngbAccordionItem
              #ngbootstrap="ngbAccordionItem"
              [collapsed]="!migrationAccordionActiveIds.includes(ngbootstrap.id)"
            >
              <h2 ngbAccordionHeader class="custom-header justify-content-between">
                <button ngbAccordionButton class="accordion-button">
                  NgBootstrap
                  <small>
                    {{ getMigrationAccordionGroupedCheckboxesChecked('ngbootstrap') }}
                  </small>
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <!-- ngBootstrap Buttons -->
                  <div>
                    <h5 class="h6 bold">Buttons</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="ngbootstrapbuttons_labelclass"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.ngbootstrap.buttons_labelclass
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="ngbootstrapbuttons_labelclass">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Refactored <code appCopyToClipboard>label.btn-primary</code>
                            to <code appCopyToClipboard>label.btn.btn-secondary</code>.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="ngbootstrapbuttons_inputclass"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.ngbootstrap.buttons_inputclass
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="ngbootstrapbuttons_inputclass">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Required class <code appCopyToClipboard>.btn-check</code>
                            on the <code appCopyToClipboard>input</code> element.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="ngbootstrapbuttons_grouptoggleclass"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.ngbootstrap
                                .buttons_grouptoggleclass
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="ngbootstrapbuttons_grouptoggleclass">
                            <span *ngIf="isMigratingAngular">⚙️</span> Dropped usage of <code appCopyToClipboard>.btn-group-toggle</code>
                            class.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/ng-bootstrap-samples/buttons">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- ngBootstrap DatePicker -->
                  <div>
                    <h5 class="mt-4 h6 bold">DatePicker</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="ngbootstrapdatepickers_variables"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.ngbootstrap.datepickers_variables
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="ngbootstrapdatepickers_variables">
                            <span class="change-badge bg-danger">breaking</span> Removed <span
                            class="highlight">padding</span> variables.
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>$ngb-dp-icon-padding</code></li>
                              <li><code appCopyToClipboard>$ngb-dp-icon-padding-sm</code></li>
                              <li><code appCopyToClipboard>$ngb-dp-icon-padding-rg</code></li>
                              <li><code appCopyToClipboard>$ngb-dp-icon-padding-lg</code></li>
                            </ul>
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/ng-bootstrap-samples/datepicker">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- ngBootstrap Modal -->
                  <div>
                    <h5 class="mt-4 h6 bold">Modal</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="ngbootstrapmodals_closebuttoncontent"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.ngbootstrap
                                .modals_closebuttoncontent
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="ngbootstrapmodals_closebuttoncontent">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Removed the <code appCopyToClipboard>&lt;span
                            aria-hidden="true"&gt;&lt;/span&gt;</code> element contained in the <span class="highlight">close-button</span>
                            elements.
                            <span class="info">The close icon will be rendered anyway.</span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="ngbootstrapmodals_closebuttonclass"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.ngbootstrap
                                .modals_closebuttonclass
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="ngbootstrapmodals_closebuttonclass">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Renamed class <code
                            appCopyToClipboard>.close</code> to <code appCopyToClipboard>.btn-close</code>.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/ng-bootstrap-samples/modal">documentation</a>
                      for more detailed information.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              ngbAccordionItem
              #jquery="ngbAccordionItem"
              [collapsed]="!migrationAccordionActiveIds.includes(jquery.id)"
            >
              <h2 ngbAccordionHeader class="custom-header justify-content-between">
                <button ngbAccordionButton class="accordion-button">
                  jQuery
                  <small>
                    {{ getMigrationAccordionGroupedCheckboxesChecked('jquery') }}
                  </small>
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <!-- jQuery Accordion -->
                  <div>
                    <h5 class="h6 bold">Accordion</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="jqueryaccordions_removed"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.jquery.accordions_removed
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="jqueryaccordions_removed">
                            <span class="change-badge bg-danger">breaking</span> Removed the entire jQuery <span
                            class="highlight">accordion</span> component.
                            <p class="info">This can be migrated easily to the new <a
                              routerLink="/bootstrap-samples/accordion">Bootstrap accordion</a> component.</p>

                            <p *ngIf="isMigratingAngular" class="alert alert-info">
                              One might think: "When it is so easy to migrate, why don't you offer an automatic
                              migration?"<br>
                              The short answer: because it is no longer the same component!
                            </p>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div
              ngbAccordionItem
              #post="ngbAccordionItem"
              [collapsed]="!migrationAccordionActiveIds.includes(post.id)"
            >
              <h2 ngbAccordionHeader class="custom-header justify-content-between">
                <button ngbAccordionButton class="accordion-button">
                  Post
                  <small>
                    {{ getMigrationAccordionGroupedCheckboxesChecked('post') }}
                  </small>
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <!-- Post Accordion -->
                  <div>
                    <h5 class="h6 bold">Accordion</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="postaccordions_removed"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.post.accordions_removed
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="postaccordions_removed">
                            <span class="change-badge bg-danger">breaking</span> Removed the entire Post <span
                            class="highlight">accordion</span> component and the associated <span class="highlight">detail-summary</span>
                            stylesheet.<br>
                            The component has been removed due to <a
                            href="https://adrianroselli.com/2019/04/details-summary-are-not-insert-control-here.html">accessibility
                            issues with the &lt;summary&gt; element</a>.
                            <p class="info">We recommend to use the new <a routerLink="/bootstrap-samples/accordion">Bootstrap
                              accordion</a> or the existing <a routerLink="/ng-bootstrap-samples/accordion">Angular
                              accordion</a> component instead.</p>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <!-- Post Custom-Select -->
                  <div>
                    <h5 class="mt-4 h6 bold">Custom-Select</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="postcustomselects_formfloatingwrapper"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.post
                                .customselects_formfloatingwrapper
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="postcustomselects_formfloatingwrapper">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Required a <code appCopyToClipboard>&lt;div
                            class="form-floating"&gt;...&lt;/div&gt;</code> wrapper around <span class="highlight">floating-label</span>
                            elements.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="postcustomselects_classes"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.post.customselects_classes
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="postcustomselects_classes">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Refactored <code appCopyToClipboard>.form-control.custom-select</code>
                            and <code appCopyToClipboard>.form-control.form-control-lg.custom-select</code> to <code
                            appCopyToClipboard>.form-select</code>.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="postcustomselects_menuclass"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.post.customselects_menuclass
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="postcustomselects_menuclass">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Removed <code appCopyToClipboard>.custom-select-menu</code>
                            class.<br>
                            Use <span class="highlight">utility-classes</span>&nbsp;<code
                            appCopyToClipboard>.w-100.mw-100</code> instead.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/post-samples/custom-select">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- Post Subnavigation -->
                  <div>
                    <h5 class="mt-4 h6 bold">Subnavigation</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="postsubnavigations_invertedclass"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.post.subnavigations_invertedclass
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="postsubnavigations_invertedclass">
                            <span *ngIf="isMigratingAngular">⚙️</span> Dropped usage of <code appCopyToClipboard>.subnavigation-inverted</code>
                            class.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/post-samples/subnavigation">documentation</a>
                      for more detailed information.
                    </p>
                  </div>

                  <!-- Post Topic Teaser -->
                  <div>
                    <h5 class="mt-4 h6 bold">Topic Teaser</h5>
                    <ul class="list-unstyled my-3">
                      <li>
                        <div class="form-check">
                          <input
                            id="posttopicteasers_imageattributes"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.post.topicteasers_imageattributes
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="posttopicteasers_imageattributes">
                            <span *ngIf="isMigratingAngular">⚙️ </span><span
                            class="change-badge bg-danger">breaking</span> Required <span class="highlight">image</span>
                            attributes.
                            <ul class="mt-2">
                              <li><code appCopyToClipboard>width="100%"</code></li>
                              <li><code appCopyToClipboard>height="100%"</code></li>
                            </ul>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="posttopicteasers_imagecontainergridclasses"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.post
                                .topicteasers_imagecontainergridclasses
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="posttopicteasers_imagecontainergridclasses">
                            <span *ngIf="isMigratingAngular">⚙️</span> Dropped usage of <code appCopyToClipboard>.col-10.col-rg-8.col-lg-4</code>
                            on <code appCopyToClipboard>.topic-teaser-image-container</code> elements.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="posttopicteasers_contentcontainergridclasses"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.post
                                .topicteasers_contentcontainergridclasses
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="posttopicteasers_contentcontainergridclasses">
                            <span *ngIf="isMigratingAngular">⚙️</span> Dropped usage of <code appCopyToClipboard>.col-12.col-lg-8</code>
                            on <code appCopyToClipboard>.topic-teaser-content</code> elements.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input
                            id="posttopicteasers_linklistfontcurve"
                            class="form-check-input"
                            type="checkbox"
                            [(ngModel)]="
                              migrationAccordionGroupedCheckboxes.post
                                .topicteasers_linklistfontcurve
                            "
                            (change)="migrationAccordionGroupedCheckboxesChange()"
                          />
                          <!-- prettier-ignore -->
                          <label class="form-check-label" for="posttopicteasers_linklistfontcurve">
                            <span *ngIf="isMigratingAngular">⚙️</span> Dropped usage of <code appCopyToClipboard>.font-curve-regular</code>
                            on <code appCopyToClipboard>ul.link-list</code> elements.
                          </label>
                        </div>
                      </li>
                    </ul>
                    <p class="info">
                      See the
                      <a routerLink="/post-samples/topic-teaser">documentation</a>
                      for more detailed information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <h4 class="h5 bold">Treat yourself to a 🍺, you've done a great job! 🚀</h4>
        </li>
      </ol>
    </section>
  </div>
</div>

<div class="topic-teaser bg-nightblue mt-huge-r mb-giant-r">
  <div class="container">
    <div class="topic-teaser-container bg-nightblue">
      <div class="row pt-huge-r">
        <div class="topic-teaser-content">
          <h2 class="topic-teaser-title font-curve-large mb-large">
            <span class="bold">Resources</span>
            <span class="light">For a consistent user experience</span>
          </h2>
          <ul class="link-list mb-large">
            <li class="link-list-item">
              <a
                target="_blank"
                rel="noopener"
                [href]="'//getbootstrap.com/docs/' + getVersion(bootstrapVersion, 'Mm')"
              >
                <span>Bootstrap Documentation</span>
              </a>
            </li>
            <li class="link-list-item">
              <a
                target="_blank"
                rel="noopener"
                [href]="'//v' + getVersion(angularVersion, 'M') + '.angular.io/docs'"
              >
                <span>Angular Docs</span>
              </a>
            </li>
            <li class="link-list-item">
              <!-- exact link is not possible, because the latest url is different from the previews -->
              <a target="_blank" rel="noopener" href="https://ng-bootstrap.github.io/#/home">
                <span>ngBootstrap Docs</span>
              </a>
            </li>
            <li class="link-list-item">
              <!-- exact link is not possible, because no version urls available -->
              <a target="_blank" rel="noopener" href="https://fonts.post.ch/frutigerneueforpost/#/">
                <span>Frutiger Neue For Post</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="topic-teaser-image-container">
          <img
            class="topic-teaser-image"
            src="assets/images/lego.jpg"
            width="100%"
            height="100%"
            alt="Lego"
          />
        </div>
      </div>
    </div>
  </div>
</div>
