<!--
  ~ Copyright 2021 by Swiss Post, Information Technology
  -->

<div class="bg-primary p-regular d-flex align-items-start">
  <!-- Demo -->
  <div class="flex-fill d-flex">
    <div class="bg-active">
      <div class="bg-petrol-bright" [class]="demoClass">
        <div class="bg-light h-100"></div>
      </div>
    </div>
  </div>

  <!-- Legend -->
  <ul class="ps-regular text-white">
    <li class="d-flex align-items-center mb-regular">
      <div class="bg-active h-regular w-regular me-mini"></div>
      <span>margin</span>
    </li>
    <li class="d-flex align-items-center mb-regular">
      <div class="bg-petrol-bright h-regular w-regular me-mini"></div>
      <span>padding</span>
    </li>
    <li class="d-flex align-items-center mb-regular">
      <div class="bg-light h-regular w-regular me-mini"></div>
      <span>content</span>
    </li>
  </ul>
</div>

<form [formGroup]="demoForm" class="row mt-regular">
  <!-- Margin controls -->
  <ng-container formGroupName="margin">
    <div class="col">
      <label class="form-label" for="margin-size">Margin size</label>
      <select id="margin-size" class="form-select form-select-rg" formControlName="size">
        <optgroup label="Bootstrap sizes">
          <option *ngFor="let size of bootstrapSizes" [value]="size.value">{{ size.name }}</option>
        </optgroup>
        <optgroup label="Post sizes">
          <option *ngFor="let size of sizes" [value]="size.name">
            {{ size.name | replaceHyphen | titlecase | removeSpaces }}
          </option>
        </optgroup>
      </select>
    </div>
    <div class="col">
      <label class="form-label" for="margin-position">Margin position</label>
      <select id="margin-position" class="form-select form-select-rg" formControlName="position">
        <option *ngFor="let p of positions" [value]="p.value">{{ p.name }}</option>
      </select>
    </div>
  </ng-container>

  <!-- Padding controls -->
  <ng-container formGroupName="padding">
    <div class="col">
      <label class="form-label" for="padding-size">Padding size</label>
      <select id="padding-size" class="form-select form-select-rg" formControlName="size">
        <optgroup label="Bootstrap sizes">
          <option *ngFor="let size of bootstrapSizes" [value]="size.value">{{ size.name }}</option>
        </optgroup>
        <optgroup label="Post sizes">
          <option *ngFor="let size of sizes" [value]="size.name">
            {{ size.name | replaceHyphen | titlecase | removeSpaces }}
          </option>
        </optgroup>
      </select>
    </div>
    <div class="col">
      <label class="form-label" for="padding-position">Padding position</label>
      <select id="padding-position" class="form-select form-select-rg" formControlName="position">
        <option *ngFor="let p of positions" [value]="p.value">{{ p.name }}</option>
      </select>
    </div>
  </ng-container>
</form>

<!-- Code sample -->
<p class="h5 bold mt-4">spacing-demo.component.html</p>
<code
  class="block"
  [highlight]="highlight"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>
