<section>
  <div class="row">
    <div class="col-lg-4 col-rg-6 col-12 mb-3">
      <div class="card" style="width: 18rem">
        <img class="card-img-top" [src]="imagePath" alt="" />
        <div class="card-body">
          <h5 class="card-title">Card title</h5>
          <p class="card-text">
            Some quick example text to build on the card title and make up the bulk of the card's
            content.
          </p>
          <a href="#" class="btn btn-primary btn-animated"><span>Go somewhere</span></a>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-rg-6 col-12 mb-3">
      <div class="card">
        <div class="card-body">This is some text within a card body.</div>
      </div>
    </div>
    <div class="col-lg-4 col-rg-6 col-12 mb-3">
      <div class="card" style="width: 18rem">
        <div class="card-body">
          <h5 class="card-title">Card title</h5>
          <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
          <p class="card-text">
            Some quick example text to build on the card title and make up the bulk of the card's
            content.
          </p>
          <a href="#" class="card-link">Card link</a>
          <a href="#" class="card-link">Another link</a>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-rg-6 col-12 mb-3">
      <div class="card" style="width: 18rem">
        <img class="card-img-top" [src]="imagePath" alt="" />
        <div class="card-body">
          <p class="card-text">
            Some quick example text to build on the card title and make up the bulk of the card's
            content.
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-rg-6 col-12 mb-3">
      <div class="card" style="width: 18rem">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">Cras justo odio</li>
          <li class="list-group-item">Dapibus ac facilisis in</li>
          <li class="list-group-item">Vestibulum at eros</li>
        </ul>
      </div>
    </div>
    <div class="col-lg-4 col-rg-6 col-12 mb-3">
      <div class="card" style="width: 18rem">
        <div class="card-header">Featured</div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">Cras justo odio</li>
          <li class="list-group-item">Dapibus ac facilisis in</li>
          <li class="list-group-item">Vestibulum at eros</li>
        </ul>
      </div>
    </div>
    <div class="col-lg-4 col-rg-6 col-12 mb-3">
      <div class="card" style="width: 18rem">
        <img class="card-img-top" [src]="imagePath" alt="" />
        <div class="card-body">
          <h5 class="card-title">Card title</h5>
          <p class="card-text">
            Some quick example text to build on the card title and make up the bulk of the card's
            content.
          </p>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">Cras justo odio</li>
          <li class="list-group-item">Dapibus ac facilisis in</li>
          <li class="list-group-item">Vestibulum at eros</li>
        </ul>
        <div class="card-body">
          <a href="#" class="card-link">Card link</a>
          <a href="#" class="card-link">Another link</a>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-rg-6 col-12 mb-3">
      <div class="card">
        <div class="card-header">Featured</div>
        <div class="card-body">
          <h5 class="card-title">Special title treatment</h5>
          <p class="card-text">
            With supporting text below as a natural lead-in to additional content.
          </p>
          <a href="#" class="btn btn-primary btn-animated"><span>Go somewhere</span></a>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-rg-6 col-12 mb-3">
      <div class="card">
        <h5 class="card-header">Featured</h5>
        <div class="card-body">
          <h5 class="card-title">Special title treatment</h5>
          <p class="card-text">
            With supporting text below as a natural lead-in to additional content.
          </p>
          <a href="#" class="btn btn-primary btn-animated"><span>Go somewhere</span></a>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-rg-6 col-12 mb-3">
      <div class="card">
        <div class="card-header">Quote</div>
        <div class="card-body">
          <figure>
            <blockquote class="blockquote">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a
                ante.
              </p>
            </blockquote>
            <figcaption class="blockquote-footer">
              Someone famous in
              <cite title="Source Title">Source Title</cite>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-rg-6 col-12 mb-3">
      <div class="card text-center">
        <div class="card-header">Featured</div>
        <div class="card-body">
          <h5 class="card-title">Special title treatment</h5>
          <p class="card-text">
            With supporting text below as a natural lead-in to additional content.
          </p>
          <a href="#" class="btn btn-primary btn-animated"><span>Go somewhere</span></a>
        </div>
        <div class="card-footer text-muted">2 days ago</div>
      </div>
    </div>
    <div class="col-lg-4 col-rg-6 col-12 mb-3">
      <div class="card card-white">
        <div class="card-header">
          <h3 class="mb-0">Post-Example</h3>
        </div>
        <div class="card-body">
          <h5 class="card-title">Special title treatment</h5>
          <p class="card-text">Example for white cards (set class: card card-white)</p>
          <a href="#" class="btn btn-primary btn-animated"><span>Go somewhere</span></a>
        </div>
        <div class="card-footer card-links">
          <a href="#">Example Link A</a>
          <a href="#">Link B</a>
        </div>
      </div>
    </div>
  </div>
</section>
