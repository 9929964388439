<section id="internationalization">
  <h3 class="bold mt-5">Internationalization</h3>

  <p class="light font-curve-regular mt-4">
    Some ng-bootstrap components contain static English text that does not appear on screen but is
    used in aria attributes needed for accessibility.
  </p>

  <p class="light font-curve-regular mt-4">
    The internationalization of this content is done using the
    <code>&#64;angular/localize</code>
    package. To do this, make sure to add
    <code>import '&#64;angular/localize/init';</code>
    to your
    <code>polyfills.ts</code>
    file, then use the implementation below to enable translations:
  </p>

  <code [highlight]="ngbLocalizationSample" [languages]="['ts']" class="block"></code>

  <ng-content></ng-content>
</section>
