<div class="heading py-5">
  <h1 class="bold font-curve-large">Notification overlay</h1>
</div>

<div class="mb-5">
  This is just a simple to describe how to create an overlay notification only with bootstrap
  classes
</div>
<div>
  <section>
    <app-nofitication-overlay-demo></app-nofitication-overlay-demo>
  </section>
</div>
<code
  class="block"
  [highlight]="codeTemplate"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>
