<div class="heading py-5">
  <h1 class="bold font-curve-large">Form radio button</h1>
  <app-dependency-link documentationPath="forms/checks-radios/#radios"></app-dependency-link>
</div>

<section>
  <app-form-radio-demo></app-form-radio-demo>
</section>
<code
  class="block"
  [highlight]="codeTemplate"
  [languages]="['html', 'scss', 'css', 'typescript', 'javascript']"
></code>
